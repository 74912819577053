/**
 * PNAssociationEvent view component.
 * @module components/theme/View/PNAssociationEvent
 */

import React, { createRef, useState } from 'react';
import { Col, Container, Row } from 'design-react-kit';
import PropTypes from 'prop-types';
import {
  PageHeader,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
  EventoDateOrari,
  EventoLuoghi,
  RelatedItems,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import {
  PNAlertBehavior,
  PNAttachments,
} from 'comune-pordenone-plone-theme/components/View';
import { flattenToAppURL } from '@plone/volto/helpers';

import PNEventContacts from '../PNEventItemView/PNEventContacts';
import PNEventFee from '../PNEventItemView/PNEventFee';
import PNAssociationEventText from './PNAssociationEventText';
import PNAssociationEventFileImage from './PNAssociationEventFileImage';
import PNAssociationEventInfo from './PNAssociationEventInfo';

import { useIntl } from 'react-intl';
import config from '@plone/volto/registry';

export const EventSectionsOrder = [
  { /* DATE */ component: EventoDateOrari },
  { /* PLACES */ component: EventoLuoghi },
  { /* FEE */ component: PNEventFee },
  { /* ATTACHMENTS */ component: PNAttachments },
  { /* CONTACTS */ component: PNEventContacts },
  { /* TEXT */ component: PNAssociationEventText },
  { /* FILE IMAGE */ component: PNAssociationEventFileImage },
  { /* EVENT INFO */ component: PNAssociationEventInfo },
];

/**
 * PNAssociationEvent view component class.
 * @function PNAssociationEvent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNAssociationEvent = ({ content, location }) => {
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  const intl = useIntl();

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  return (
    <Container className="my-4 event-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        <div className="row wide-image row-full-width my-3">
          <figure className="figure">
            <img
              src={flattenToAppURL(content?.banner?.download)}
              alt={content?.banner?.filename}
            />
          </figure>
        </div>
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={EventSectionsOrder}
            />
          </section>
          <RelatedItems content={content} />
        </Row>
      </div>
    </Container>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNAssociationEvent.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    effective: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    orari: PropTypes.shape({
      data: PropTypes.string,
    }),
    fee: PropTypes.shape({
      data: PropTypes.string,
    }),
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    modified: PropTypes.string,
  }).isRequired,
};

export default PNAssociationEvent;
