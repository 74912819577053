/**
 * useProcessModel modelsuses.
 * @module modelsuses/useProcessModel
 *
 */

import { useCallback, useState } from 'react';

import { ProcessModel } from '../models';
import { getPorcessAttributesProxy } from '../proxies/processes';
import { getProcessActivityProxy } from '../proxies/processes';
import { getProcessInstanceProxy } from '../proxies/processes';
import { getProcessInstancesProxy } from '../proxies/processes';
import { getProcessesProxy } from '../proxies/processes';
import { postProcessInstanceProxy } from '../proxies/processes';
import { putProcessInstanceProxy } from '../proxies/processes';
import { RequestConfig } from '../proxies/utils';

const useProcessModel = () => {
  // processes store
  const [processesData, setProcessesData] = useState<ProcessModel[]>();

  // get processes
  const getProcesses = useCallback(async () => {
    const response = await getProcessesProxy({
      params: {
        detailed: true,
      },
    });
    const processes = response.data as ProcessModel[];
    setProcessesData(processes);
    return processes;
  }, []);

  // get process
  const getProcess = async (processName: string) => {
    const processes = await getProcesses();
    return processes?.find((proc) => proc.name === processName);
  };

  // get process attributes
  const getProcessAttributes = useCallback(async (processName: string) => {
    const process = await getProcess(processName);
    process.attributes = await getPorcessAttributesProxy(processName);
    return process?.attributes;
  }, []);

  // get process instance
  const getProcessInstance = async (processName: string, instanceId: number) => {
    return await getProcessInstanceProxy(processName, instanceId);
  };

  // get process instances
  const getProcessInstances = async (processName: string, config?: RequestConfig) => {
    const cls = await getProcess(processName);

    // default sorting
    if (!config?.sort) {
      if (cls?.defaultOrder?.length) {
        config.sort = cls.defaultOrder.map((o: object) => {
          return {
            property: o['attribute'],
            direction: o['direction'] === 'descending' ? 'DESC' : 'ASC',
          };
        });
      } else {
        config.sort = [
          {
            property: 'Description',
            direction: 'ASC',
          },
        ];
      }
    }

    return getProcessInstancesProxy(processName, config);
  };
  const getProcessActivity = async (processName: string, instanceId?: number, activityId?: string) => {
    return await getProcessActivityProxy(processName, instanceId, activityId);
  };

  const advanceProcessInstance = async (processName: string, instanceId?: number, activity: string, data: object) => {
    if (instanceId) {
      return await putProcessInstanceProxy(processName, instanceId, activity, data, true);
    } else {
      return await postProcessInstanceProxy(processName, activity, data, true);
    }
  };

  const saveProcessInstance = async (processName: string, instanceId?: number, activity: string, data: object) => {
    if (instanceId) {
      return await putProcessInstanceProxy(processName, instanceId, activity, data, false);
    } else {
      return await postProcessInstanceProxy(processName, activity, data, false);
    }
  };

  return {
    processes: processesData,
    getProcesses,
    getProcess,
    getProcessAttributes,
    getProcessInstance,
    getProcessInstances,
    getProcessActivity,
    advanceProcessInstance,
    saveProcessInstance,
  };
};

export default useProcessModel;
