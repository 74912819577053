import { RequestConfig } from './utils';
import { RequestResponse } from './utils';
import { makeGetRequest } from './utils';

const stringToHex = (s: string) => {
  if (s && !s.startsWith('HEX')) {
    // utf8 to latin1
    s = unescape(encodeURIComponent(s));
    var h = '';
    for (var i = 0; i < s.length; i++) {
      h += s.charCodeAt(i).toString(16);
    }
    return "HEX" + h;
  }
  return s;
};

export const getLookupValuesProxy = async (lookupType: string, config?: { filter: object }): Promise<RequestResponse> => {
  const params: RequestConfig = {};
  if (config?.filter) {
    params.filter = config.filter;
  }
  return makeGetRequest(`lookup_types/${stringToHex(lookupType)}/values`, params);
};
