import React from 'react';
import PropTypes from 'prop-types';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Row, Col } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';

const PNAdministratorsViewCityCouncil = ({ content }) => {
  return content?.Administrators?.length > 0 || content.roles ? (
    <>
      {content?.Administrators.map((item, index) => (
        <RichTextSection
          tag_id={index}
          title={item?.name}
          show_title={false}
          // key={index}
        >
          <Row className="mt-5">
            <Col>
              <Col className="px-2 py-1 mb-2 primary-bg rounded-top text-primary">
                {item?.roles?.presidentmunicipalcouncil && (
                  <h4 className="text-white">
                    {item.roles.presidentmunicipalcouncil}
                  </h4>
                )}
                {item?.roles?.mayor && (
                  <h4 className="text-white">{item.roles.mayor}</h4>
                )}
              </Col>
              <ul>
                <a href={flattenToAppURL(item.url)}>
                  <b>{item.name}</b>
                </a>
                {item?.email && (
                  <i>
                    {' - '}
                    <ContactLink email={item.email} label={false} />
                  </i>
                )}
              </ul>
            </Col>
          </Row>
        </RichTextSection>
      ))}
    </>
  ) : (
    ''
  );
};

PNAdministratorsViewCityCouncil.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewCityCouncil;
