import React, { createRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  SkipToMainContent,
  PageHeader,
  SideMenu,
  RelatedItems,
  Metadata,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import { Container, Col, Row } from 'design-react-kit';

import PNAdministratorsViewPrologue from './PNAdministratorsViewPrologue';
import PNAdministratorsViewEpilogue from './PNAdministratorsViewEpilogue';
import PNAdministratorsViewCityBoard from './PNAdministratorsViewCityBoard';
import PNAdministratorsViewCityCouncil from './PNAdministratorsViewCityCouncil';
import PNAdministratorsViewBoardCommittee from './PNAdministratorsViewBoardCommittee';
import PNAdministratorsViewSecretariat from './PNAdministratorsViewSecretariat';

const PNAdministratorsView = ({ content }) => {
  let documentBody = createRef();
  const [sideMenuElements, setSideMenuElements] = useState(null);

  useEffect(() => {
    if (documentBody.current && __CLIENT__) {
      setSideMenuElements(documentBody.current);
    }
  }, [documentBody]);

  return (
    <Container className="px-md-8 col-sm-10 col-lg-8 container-administrator">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {documentBody.current && __CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="administratorsView-main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <PNAdministratorsViewPrologue content={content} className="mb-5" />
            {content.viewtype.title === 'Giunta comunale' ||
            content.viewtype.title === 'Giunta Comunale' ? (
              <PNAdministratorsViewCityBoard content={content} />
            ) : (
              ''
            )}
            {content.viewtype.title === 'Consiglio comunale' ||
            content.viewtype.title === 'Consiglio Comunale' ? (
              <PNAdministratorsViewCityCouncil content={content} />
            ) : (
              ''
            )}
            {content.viewtype.title === 'Commissione' && (
              <PNAdministratorsViewBoardCommittee content={content} />
            )}
            <PNAdministratorsViewSecretariat content={content} />
            <PNAdministratorsViewEpilogue content={content} className="mb-5" />
            <Metadata content={content} title={' '} />
          </section>
        </Row>
        <RelatedItems list={content?.relatedItems} />
      </div>
    </Container>
  );
};

PNAdministratorsView.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }),
};

export default PNAdministratorsView;
