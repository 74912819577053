/**
 * PNPressReleaseInfo view component.
 * @module components/theme/View/PNPressReleaseInfo
 */

import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

const messages = defineMessages({
  text: {
    id: 'text',
    defaultMessage: 'Testo',
  },
});

/**
 * PNPressReleaseInfo view component class.
 * @function PNPressReleaseInfo
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNPressReleaseInfo = ({ content }) => {
  const intl = useIntl();
  return (
    <>
      {content?.text && (
        <RichTextSection
          tag_id={'pressrelease-text'}
          field="text"
          title={intl.formatMessage(messages.text)}
          show_title={true}
          data={content?.text}
          className={'mb-5'}
        />
      )}
    </>
  );
};
PNPressReleaseInfo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNPressReleaseInfo;
