import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  Container,
  Card,
  CardBody,
} from 'design-react-kit';

const messages = defineMessages({
  organizationType: {
    id: 'organizationType',
    defaultMessage: 'Tipologia di organizzazione',
  },
  areaRiferimento: {
    id: 'areaRiferimento',
    defaultMessage: 'Area di riferimento',
  },
});

const PNOfficeInfo = ({ content }) => {
  const intl = useIntl();

  return content?.organizationType || content?.officeResponsibleText ? (
    <>
      {/* TIPOLOGIA DI ORGANIZZAZIONE */}
      <RichTextSection
        tag_id={'title-office-organization-type'}
        field="text"
        title={intl.formatMessage(messages.organizationType)}
        show_title={true}
      >
        <div> {content.organizationType} </div>
      </RichTextSection>

  {/* AT THE MOMENT IT'S A STATIC VIEW */}
      {/* AREA DI RIFERIMENTO */}
      <RichTextSection
        tag_id={'title-office-area-riferimento'}
        field="text"
        title={intl.formatMessage(messages.areaRiferimento)}
        show_title={true}
      >
        <Card
          className="card-bg card-teaser card-teaser-danger rounded my-3 col-lg-8 shadow"
          noWrapper={false}
          space
          tag="div"
        >
          <CardBody>
            <div> Ambiente, Mobilità e Trasporti</div>
          </CardBody>
        </Card>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

PNOfficeInfo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNOfficeInfo;
