/**
 * BandoVies view component.
 * @module components/theme/View/PNAnnouncementOffice
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  OfficeCard,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  ufficio_responsabile: {
    id: 'ufficio_responsabile',
    defaultMessage: 'Ufficio responsabile',
  },
});

/**
 * PNAnnouncementOffice view component class.
 * @function PNAnnouncementOffice
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNAnnouncementOffice = ({ content }) => {
  const intl = useIntl();

  return (
    content?._ufficio_responsabile && (
      <RichTextSection
        tag_id="ufficio_responsabile"
        title={intl.formatMessage(messages.ufficio_responsabile)}
      >
        <div className="mb-5 mt-3">
          <div className="card-wrapper card-teaser-wrapper card-teaser-wrapper-equal">
            <OfficeCard
              key={content?._ufficio_responsabile.token}
              office={content?._ufficio_responsabile}
            />
          </div>
        </div>
      </RichTextSection>
    )
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNAnnouncementOffice.propTypes = {
  content: PropTypes.shape({
    _ufficio_responsabile: PropTypes.array,
  }).isRequired,
};

export default PNAnnouncementOffice;
