/**
 * Fields helper utility.
 * @module utilities/widgetshelper
 *
 */
import { Button, Card, CardBody, CardText, Modal, ModalBody, ModalFooter, ModalHeader } from 'design-react-kit';
import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { CreateReportWidget, OpenAttachmentWidget, WfInfoWidget } from '../formwidgets';

import { SelectableGridWidget } from '../formwidgets';
import { WfattachmentWidget } from '../formwidgets';
import { useState } from 'react';

export const WidgetsButtons = ({ widgtes, context }: { widgtes: WidgetModel[]; context: CardModel | ProcessInstanceModel | object }) => {
  const button_widgets = widgtes.filter((w) => {
    return !(w._inline || w.RelatedTo);
  });

  if (button_widgets.length) {
    return (
      <div>
        <Card className="card-bg card-big no-after mb-5">
          <CardBody
            className="card shadow-sm p-5 has-bkg-grey"
            style={{
              backgroundColor: '#ebeef0',
            }}
          >
            <CardText>
              {button_widgets.map((w) => (
                <WidgetButton widget={w} context={context} />
              ))}
            </CardText>
          </CardBody>
        </Card>
      </div>
    );
  }
  return null;
};

const RenderWidgetModalBody = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  switch (widget._type) {
    case 'createReport':
      return <CreateReportWidget widget={widget} context={context} />;
    case 'wfattachmentwidget':
      return <WfattachmentWidget config={widget} target={context} />;
    case 'openAttachment':
      return <OpenAttachmentWidget widget={widget} context={context} />;
    default:
      return <p>{widget._type}</p>;
  }
};

const WidgetButton = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  const [isOpen, toggleModal] = useState(false);
  return (
    <>
      <Button
        color="secondary"
        key={`widget-${widget._id}`}
        className="m-2"
        onClick={() => {
          toggleModal(!isOpen);
        }}
      >
        {widget._label_translation}
      </Button>
      <Modal isOpen={isOpen} toggle={() => toggleModal(!isOpen)} labelledBy={`widget-modal-${widget._id}`} size='lg'>
        <ModalHeader toggle={() => toggleModal(!isOpen)} id={`widget-modal-${widget._id}`}>
          {widget._label_translation}
        </ModalHeader>
        <ModalBody style={{ height: window.innerHeight * 0.8 }}>
          <RenderWidgetModalBody widget={widget} context={context} />
        </ModalBody>
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export const FieldInlineWidget = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object | undefined | null }) => {
  let WidgetComponent;
  switch (widget._type) {
    case 'wfattachmentwidget':
      WidgetComponent = WfattachmentWidget;
      break;
    case 'wfinfowidget':
      WidgetComponent = WfInfoWidget;
      break;
    case 'selectablegridwidget':
      WidgetComponent = SelectableGridWidget;
      break;
    default:
      return <p>{widget._label_translation}</p>;
  }
  return <WidgetComponent config={widget} target={context} />;
};

export const InlineWidgets = ({ widgets, target }: { widgets: WidgetModel[]; target: CardModel | ProcessInstanceModel | object | undefined | null }) => {
  // eslint-disable-next-line array-callback-return
  return widgets?.map((w) => {
    if (w._inline && !w.RelatedTo && !w.BeforeAttribute && !w.AfterAttribute) {
      const type = w._type || '';
      let WidgetComponent;
      switch (type) {
        case 'selectablegridwidget':
          WidgetComponent = SelectableGridWidget;
          break;
        default:
          return <p>The widget {w._type} is not implemented!</p>;
      }
      return <WidgetComponent config={w} target={target} />;
    }
  });
};
