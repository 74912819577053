import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  costs: {
    id: 'costs',
    defaultMessage: 'Costi',
  },
});

const PNProcedureCosts = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.costs) ? (
    <RichTextSection
      data={content.costs}
      tag_id={'services-costs'}
      field="text"
      title={intl.formatMessage(messages.costs)}
      show_title={true}
      lighthouseId={'service-cost'}
    />
  ) : (
    ''
  );
};

PNProcedureCosts.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureCosts;
