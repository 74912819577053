/* eslint-disable react-hooks/exhaustive-deps */
/**
 * MyArea component.
 * @module components/Features/MyArea/MyArea
 *
 * Page which displays personal area page.
 *
 */
import cx from 'classnames';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import jwtDecode from 'jwt-decode';
import { Container, TabContent, TabPane } from 'design-react-kit';
import { useLocation } from 'react-router-dom';
import { getUser } from '@plone/volto/actions';
import DeskPage from './DeskPage';
import MessagesPage from './MessagesPage';
import ActivitiesPage from './ActivitiesPage';
import ServicesPage from './ServicesPage';
import PropTypes from 'prop-types';
import MyAreaTab from './MyAreaTab';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  fiscalNumber: {
    id: 'fiscalNumber',
    defaultMessage: 'CF: ',
  },
});
const MyArea = (props) => {
  const intl = useIntl();
  const [activeTab, setActiveTab] = useState('desk-tab');
  const dispatch = useDispatch();
  const location = useLocation();

  const userSession = useSelector((state) => state.portalUser);
  /**
   * The following code is needed for standard authentication
   */
  const ploneUserId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  useEffect(() => {
    if (
      !userLoggedSt?.get?.loading &&
      userSession?.isAuthenticated === false &&
      ploneUserId
    ) {
      dispatch(getUser(ploneUserId));
    }
  }, [ploneUserId, userSession.isAuthenticated]);

  const rightHeaderHasContent = false;
  return (
    <>
      <Container className="my-4">
        <div className="PageHeaderWrapper row justify-content-center">
          <div className=" col-12 col-lg-10">
            <div
              className={cx('title-description-wrapper', {
                'col-lg-6': rightHeaderHasContent,
                'col-lg-12': !rightHeaderHasContent,
              })}
            >
              <h1>{`${userSession.user?.first_name} ${userSession.user?.last_name}`}</h1>
              <p>
                <strong>
                  {intl.formatMessage(messages.fiscalNumber)}
                  {`${userSession.user?.fiscal_number}`}
                </strong>
              </p>
            </div>
          </div>
        </div>
        {/* TAB SECTION */}
        <div className="col-12">
          <MyAreaTab activeTab={activeTab} setActiveTab={setActiveTab} />
          <TabContent activeTab={activeTab}>
            <TabPane tabId="desk-tab" className="p-3">
              <DeskPage />
            </TabPane>
            <TabPane tabId="messages-tab" className="p-3">
              <MessagesPage />
            </TabPane>
            <TabPane tabId="activities-tab" className="p-3">
              <ActivitiesPage />
            </TabPane>
            <TabPane tabId="services-tab" className="p-3">
              <ServicesPage />
            </TabPane>
          </TabContent>
        </div>
      </Container>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MyArea.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default MyArea;
