import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import Image from '@plone/volto/components/theme/Image/Image';

const messages = defineMessages({
  assignment: {
    id: 'assignment',
    defaultMessage: 'Incarico',
  },
  competences: {
    id: 'competences',
    defaultMessage: 'Competenze',
  },
  appointmentDate: {
    id: 'appointmentDate',
    defaultMessage: 'Data di insediamento',
  },
  assigmentType: {
    id: 'assigmentType',
    defaultMessage: 'Tipo di incarico',
  },
  remunerations: {
    id: 'Remunerations',
    defaultMessage: 'Compenso',
  },
});

const PNAdministratorInfo = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {content?.image && (
        <div>
          <Image
            item={content}
            alt={content.image_caption}
            className=""
            responsive={true}
            width="200"
            height="300"
          />
          <figcaption className="figure-caption text-left pt-3">
            {content.image_caption}
          </figcaption>
        </div>
      )}
      {content?.administratorRoles && (
        <RichTextSection
          tag_id={'title-administrator-roles'}
          field="text"
          title={intl.formatMessage(messages.assignment)}
          show_title={true}
        >
          {content?.administratorRoles?.length > 0 ? (
            <div className={'administrator-roles'}>
              {content.administratorRoles.map((role, index) => (
                <p>
                  <b>{role.title}</b>
                </p>
              ))}
            </div>
          ) : (
            ''
          )}
        </RichTextSection>
      )}
      {richTextHasContent(content?.competences) && (
        <RichTextSection
          data={content.competences}
          tag_id={'title-administrator-competences'}
          field="text"
          title={intl.formatMessage(messages.competences)}
          show_title={true}
        />
      )}
      {content?.assigmentType && (
        <RichTextSection
          tag_id={'title-administrator-assigmentType'}
          field="text"
          title={intl.formatMessage(messages.assigmentType)}
          show_title={true}
        >
          <b> {content?.assigmentType}</b>
        </RichTextSection>
      )}
      {content?.remunerations && (
        <RichTextSection
          tag_id={'title-administrator-remunerations'}
          field="text"
          title={intl.formatMessage(messages.remunerations)}
          show_title={true}
        >
          <b>{content?.remunerations}</b>
        </RichTextSection>
      )}
      {content?.appointmentDate && (
        <RichTextSection
          tag_id={'title-administrator-appointmentDate'}
          field="text"
          title={intl.formatMessage(messages.appointmentDate)}
          show_title={true}
        >
          <b>
            {viewDate(intl.locale, content?.appointmentDate).format(
              'DD MMMM YYYY',
            )}
          </b>
        </RichTextSection>
      )}
    </>
  );
};

PNAdministratorInfo.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorInfo;
