/**
 * Search filters reducer
 * @module src/reducers/isauthenticated
 */

import { GET_PORTAL_USER } from 'comune-pordenone-plone-theme/actions';
import { POST_LOGOUT } from 'comune-pordenone-plone-theme/actions';
import jwtDecode from 'jwt-decode';

const initialState = {
  error: null,
  hasError: false,
  isAuthenticated: null,
  token: null,
  user: {},
  loadingInfo: false,
};

export const portalUserReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_PORTAL_USER}_PENDING`:
      return {
        ...state,
        loadingInfo: true,
      };

    case `${GET_PORTAL_USER}_SUCCESS`:
      return {
        ...state,
        isAuthenticated: action.result?.authenticated,
        token: action.result?.token,
        user: action.result?.token ? jwtDecode(action.result?.token) : {},
        loadingInfo: false,
      };

    case `${GET_PORTAL_USER}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loadingInfo: false,
      };

    case `${POST_LOGOUT}_SUCCESS`:
    case `${POST_LOGOUT}_FAIL`:
      return {
        ...initialState,
        isAuthenticated: false,
      };

    default:
      return state;
  }
};
