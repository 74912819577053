import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  prologue: {
    id: 'prologue',
    defaultMessage: 'Prologo',
  },
  epilogue: {
    id: 'epilogue',
    defaultMessage: 'Epilogo',
  },
});

const PNAdministratorsViewInfo = ({ content }) => {
  const intl = useIntl();
  return (
    richTextHasContent(content?.prologue) && (
      <RichTextSection
        tag_id={'administratorsView-prologue'}
        field="text"
        title={intl.formatMessage(messages.prologue)}
        show_title={false}
        data={content?.prologue}
        className={'mb-5 mt-0'}
      />
    )
  );
};

PNAdministratorsViewInfo.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewInfo;
