import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  stateReason: {
    id: 'stateReason',
    defaultMessage: 'Motivo dello stato',
  },
  state: {
    id: 'state',
    defaultMessage: 'Stato',
  },
});

const PNProcedureStateReason = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.stateReason) ||
    content?.serviceState !== undefined ? (
    <>
      <RichTextSection
        tag_id={'service-state'}
        field="text"
        title={intl.formatMessage(messages.state)}
        show_title={true}
        data-element="page-index"
      >
        <div className="chip chip-simple chip-primary">
          <span className="chip-label">
            {content?.serviceState ? 'Servizio attivo' : 'Servizio non attivo'}
          </span>
        </div>
      </RichTextSection>
      <RichTextSection
        data={content.stateReason}
        tag_id={'service-stateReason'}
        field="text"
        title={intl.formatMessage(messages.stateReason)}
        show_title={true}
        data-element="page-index"
      />
    </>
  ) : (
    ''
  );
};

PNProcedureStateReason.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureStateReason;
