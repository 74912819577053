/**
 * Message attribute.
 * @module fields/message
 *
 */
import { CardModel, ProcessInstanceModel } from '../models';

import { Alert } from 'design-react-kit';

export const MessageField = ({ attribute, context }: { attribute: string; context: CardModel | ProcessInstanceModel | object }) => {
  return attribute && context?.[attribute as keyof object] ? (
    <Alert color="info" hidden={!context[attribute]}>
      <div dangerouslySetInnerHTML={{ __html: context[attribute as keyof object] }} />
    </Alert>
  ) : null;
};
