import { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'design-react-kit';
import {
  PageHeader,
  useSideMenu,
  ContentTypeViewSections,
  RelatedItems,
  NewsItemPlaceholderAfterContent,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import PNAssociationLogo from './PNAssociationLogo';
import PNAssociationData from './PNAssociationData';
import PNAssociationEventItem from './PNAssociationEventItem';

export const AssociationViewSectionsOrder = [
  { /* LOGO */ component: PNAssociationLogo },
  { /* DATA */ component: PNAssociationData },
  { /* EVENTO */ component: PNAssociationEventItem },
];

const PNAssociationView = ({ content }) => {
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  return (
    <Container className="my-4 newsitem-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={AssociationViewSectionsOrder}
            />
          </section>
        </Row>
        <NewsItemPlaceholderAfterContent content={content} />
        <RelatedItems list={related_items} />
      </div>
    </Container>
  );
};

PNAssociationView.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNAssociationView;
