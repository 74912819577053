import { Form, FormGroup, TextArea } from 'design-react-kit';
import React, { useEffect, useRef } from 'react';

import { FormHeader } from 'volto-feedback';
import cx from 'classnames';
import { defineMessages } from 'react-intl';

const messages = defineMessages({
  suggestions_placeholder: {
    id: 'feedback_form_suggestions_placeholder',
    defaultMessage:
      'Explain us why, and help us improve the quality of the site',
  },

  header_comments: {
    id: 'feedback_comments_header',
    defaultMessage: 'Do you want to add some more details?',
  },
  infotext_valid: {
    id: 'feedback_comments_infotext_valid',
    defaultMessage: 'Add your comment, maximum length is 200 characters',
  },
  infotext_invalid: {
    id: 'feedback_comments_infotext_invalid',
    defaultMessage: 'Comment is too long, maximum length is 200 characters',
  },
  label_comment: {
    id: 'feedback_comments_label_comment',
    defaultMessage: 'Comment',
  },
});

const CommentsStep = ({
  updateFormData,
  userFeedback,
  step,
  totalSteps,
  getFormFieldValue,
  intl,
}) => {
  const handleChange = ({ target }) => {
    updateFormData('comment', target.value ?? '');
  };
  const invalid = getFormFieldValue('comment')?.length > 200;

  return (
    <fieldset
      className={cx('comments-step', {
        'd-none': step !== 1,
      })}
      data-step={step}
      aria-expanded={step === 1}
      aria-hidden={step !== 1}
    ></fieldset>
  );
};

export default CommentsStep;
