export const objecttypes = {
    calendar: 'calendar',
    custompage: 'custompage',
    dashboard: 'dashboard',
    dmsmodel: 'dmsmodel',
    domain: 'domain',
    event: 'event',
    klass: 'class',
    navtreecontent: 'navtreecontent',
    process: 'process',
    report: 'report',
    view: 'view'
};

export const attributetypes = {
    activity: 'activity',
    bigint: 'long',
    boolean: 'boolean',
    char: 'char',
    date: 'date',
    datetime: 'dateTime',
    decimal: 'decimal',
    double: 'double',
    file: 'file',
    foreignkey: 'foreignKey',
    formula: 'formula',
    integer: 'integer',
    ipaddress: 'ipAddress',
    link: 'link',
    lookup: 'lookup',
    lookupArray: 'lookupArray',
    reference: 'reference',
    string: 'string',
    text: 'text',
    time: 'time',
    tenant: 'tenant',
};