/**
 * PNPersonInfo view component.
 * @module components/theme/View/PNPersonInfo
 */

import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody } from 'design-react-kit';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useSelector } from 'react-redux';

const messages = defineMessages({
  file: {
    id: 'file',
    defaultMessage: 'File',
  },
  person: {
    id: 'person',
    defaultMessage: 'Peronale',
  },
  role: {
    id: 'role',
    defaultMessage: 'Ruolo',
  },
  office: {
    id: 'office',
    defaultMessage: 'Ufficio',
  },
  notes: {
    id: 'Notes',
    defaultMessage: 'Note',
  },
  other_info: {
    id: 'other_info',
    defaultMessage: 'Ulteriori informazioni',
  },
  competences: {
    id: 'competences',
    defaultMessage: 'Competenze',
  },
  biography: {
    id: 'biography',
    defaultMessage: 'Biografia',
  },
  deleghe: {
    id: 'deleghe',
    defaultMessage: 'deleghe',
  },
  situazione_patrimoniale: {
    id: 'situazione_patrimoniale',
    defaultMessage: 'Situazione patrimoniale',
  },
});

const PNPersonInfo = ({ content }) => {
  const intl = useIntl();
  const userLogged = useSelector((state) => state.users.user);

  return richTextHasContent(content?.competences) ||
    richTextHasContent(content?.deleghe) ||
    richTextHasContent(content?.biography) ||
    richTextHasContent(content?.situazione_patrimoniale) ||
    content?.office ||
    content?.role?.title ||
    richTextHasContent(content?.note) ||
    richTextHasContent(content?.other_info) ? (
    <>
      {/* Office */}
      {content?.office && (
        <RichTextSection
          tag_id={'person-office'}
          field="text"
          title={content?.office?.title}
          show_title={false}
        >
          {/* METTERE OFFICE CARD */}
          <div className="card-text">
            <h4>{intl.formatMessage(messages.office)}</h4>
            <p>
              <a href={flattenToAppURL('resolveuid/' + content?.office?.token)}>
                {content?.office?.title}
              </a>
            </p>
          </div>
        </RichTextSection>
      )}
      {/* Role */}
      {content?.role.title && (
        <RichTextSection
          tag_id={'person-role'}
          field="text"
          title={content?.role.title}
          show_title={false}
        >
          <div className="card-text">
            <h4>{intl.formatMessage(messages.role)}</h4>
            <p>{content?.role.title}</p>
          </div>
        </RichTextSection>
      )}
      {/* Note */}
      {userLogged.roles && richTextHasContent(content?.note) ? (
        <RichTextSection
          data={content?.note}
          tag_id={'title-notes'}
          field="text"
          title={intl.formatMessage(messages.notes)}
          show_title={true}
        />
      ) : (
        ''
      )}
      {richTextHasContent(content?.other_info) && (
        <RichTextSection
          data={content?.other_info}
          tag_id={'title-more-info'}
          field="text"
          title={intl.formatMessage(messages.other_info)}
          show_title={true}
        />
      )}
      {richTextHasContent(content?.competences) && (
        <RichTextSection
          data={content?.competences}
          tag="p"
          tag_id={'competenze'}
          title={intl.formatMessage(messages.competences)}
          show_title={true}
          className="it-page-section anchor-offset mb-5"
        />
      )}
      {richTextHasContent(content?.biography) && (
        <RichTextSection
          data={content?.biography}
          tag="p"
          tag_id={'biografia'}
          title={intl.formatMessage(messages.biography)}
          show_title={true}
          className="it-page-section anchor-offset mb-5"
        />
      )}
      {richTextHasContent(content?.deleghe) && (
        <RichTextSection
          data={content?.deleghe}
          tag="p"
          tag_id={'deleghe'}
          title={intl.formatMessage(messages.deleghe)}
          show_title={true}
          className="it-page-section anchor-offset mb-5"
        />
      )}
      {richTextHasContent(content?.situazione_patrimoniale) && (
        <RichTextSection
          data={content?.situazione_patrimoniale}
          tag="p"
          tag_id={'situazione_patrimoniale'}
          title={intl.formatMessage(messages.situazione_patrimoniale)}
          show_title={true}
          className="it-page-section anchor-offset mb-5"
        />
      )}
    </>
  ) : (
    ''
  );
};

PNPersonInfo.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNPersonInfo;
