/**
 * BandoVies view component.
 * @module components/theme/View/PNAnnouncementInfo
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { UniversalLink } from '@plone/volto/components';
import { flattenToAppURL } from '@plone/volto/helpers';
const messages = defineMessages({
  CIG: {
    id: 'CIG',
    defaultMessage: 'CIG',
  },
  CUP: {
    id: 'CUP',
    defaultMessage: 'CUP',
  },
  contract_detail: {
    id: 'contract_detail',
    defaultMessage: 'Dettaglio appalto',
  },
});

/**
 * PNAnnouncementInfo view component class.
 * @function PNAnnouncementInfo
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNAnnouncementInfo = ({ content }) => {
  const intl = useIntl();
  const base_cig_url =
    'https://dati.anticorruzione.it/superset/dashboard/dettaglio_cig/?cig=';
  return (
    <>
      {content?.cigCode && (
        <>
          <RichTextSection
            tag_id="cig-code"
            title={intl.formatMessage(messages.CIG)}
          >
            {content?.cigCode}
          </RichTextSection>
          <RichTextSection
            tag_id="contract-detail"
            title={intl.formatMessage(messages.contract_detail)}
          >
            <UniversalLink
              alt={content?.cigCode}
              target="_blank"
              href={flattenToAppURL(base_cig_url + content?.cigCode)}
            >
              {content?.cigCode}
            </UniversalLink>
          </RichTextSection>
        </>
      )}
      {content?.cupCode && (
        <RichTextSection
          tag_id="cup-code"
          title={intl.formatMessage(messages.CUP)}
        >
          {content?.cupCode}
        </RichTextSection>
      )}
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNAnnouncementInfo.propTypes = {
  content: PropTypes.shape({
    _ufficio_responsabile: PropTypes.array,
  }).isRequired,
};

export default PNAnnouncementInfo;
