import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  intendedFor: {
    id: 'intendedFor',
    defaultMessage: 'A chi è rivolto',
  },
});

const PNProcedureIntendedFor = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.intendedFor) ? (
    <RichTextSection
      data={content.intendedFor}
      tag_id={'service-intendedFor'}
      field="text"
      title={intl.formatMessage(messages.intendedFor)}
      show_title={true}
      lighthouseId={'service-addressed'}
    />
  ) : (
    ''
  );
};

PNProcedureIntendedFor.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureIntendedFor;
