import PropTypes from 'prop-types';
import { Col, Row } from 'design-react-kit';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import Image from '@plone/volto/components/theme/Image/Image';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  denomination: {
    id: 'denomination',
    defaultMessage: 'Denominazione',
  },
});

const PNAssociationLogo = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      <RichTextSection
        tag_id={'association-denomination'}
        field="text"
        title={intl.formatMessage(messages.denomination)}
        show_title={true}
      >
        <Row>
          <Col className="col-lg-9 col-7 ">
            <p>{content.title}</p>
          </Col>
          <Col className="col-lg-3 col-5 ">
            <Image
              item={content}
              alt={content.filename}
              className="img-fluid"
            />
          </Col>
        </Row>
      </RichTextSection>
    </>
  );
};

PNAssociationLogo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNAssociationLogo;
