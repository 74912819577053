/**
 * Message shown when the service is not enabled.
 * @module components/Features/OnlineService/ServiceDisabledMessage
 *
 * Display the list of the active instances for this service.
 *
 */
import { Alert } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  serviceNotActive: {
    id: 'serviceNotActive',
    defaultMessage: 'Servizio non attivo!',
  },
});

const ServiceDisabledMessage = ({ timing }) => {
  const intl = useIntl();
  return (
    <Alert color="info">
      <h4 className="alert-heading">
        {intl.formatMessage(messages.serviceNotActive)}
      </h4>
      <div dangerouslySetInnerHTML={{ __html: timing }} />
    </Alert>
  );
};
export default ServiceDisabledMessage;
