import React, { createRef } from 'react';
import PropTypes from 'prop-types';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import {
  RichTextSection,
  PageHeader,
  RelatedItems,
  BandoPlaceholderAfterContent,
  BandoPlaceholderAfterRelatedItems,
  RelatedItemInEvidence,
  SkipToMainContent,
  useSideMenu,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import { flattenToAppURL } from '@plone/volto/helpers';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  active: {
    id: 'active',
    defaultMessage: 'Attivi',
  },
  active_announcements: {
    id: 'active_announcements',
    defaultMessage: 'Bandi attivi',
  },
  expired_announcements: {
    id: 'expired_announcements',
    defaultMessage: 'Bandi scaduti',
  },
});

const PNAnnouncementsContainerView = ({ content, location }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed in JavaScript
  const day = String(today.getDate()).padStart(2, '0');

  const dateString = `${year}-${month}-${day}`;

  const active = content?.items?.filter(
    (active) =>
      active['@type'] === 'PNAnnouncement' &&
      active.chiusura_procedimento_bando < dateString,
  );

  const expired = content?.items?.filter(
    (announcement) =>
      announcement['@type'] === 'PNAnnouncement' &&
      announcement.chiusura_procedimento_bando > dateString,
  );

  return (
    <>
      <div className="container px-4 my-4 bando-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={true}
          showbandostate={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <div className="row row-column-border border-light row-column-menu-left">
          <aside className="col-lg-4">
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </aside>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container border-light"
          >
            {/* Render the new component here */}
            {active?.length > 0 && (
              <RichTextSection
                tag_id={'active-announcements'}
                field="text"
                title={intl.formatMessage(messages.active_announcements)}
                show_title={false}
              >
                <h4 className="mb-4 ">
                  {intl.formatMessage(messages.active_announcements)}
                </h4>
                {active?.map((announcement, index) => {
                  return (
                    <Card
                      key={index}
                      className="card card-teaser shadow p-4 mt-3 rounded attachment"
                      noWrapper={true}
                      tag="div"
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          <a href={flattenToAppURL(announcement['@id'])}>
                            {announcement.title}
                          </a>
                        </CardTitle>
                        {announcement?.description && (
                          <p>{announcement.description}</p>
                        )}
                      </CardBody>
                    </Card>
                  );
                })}
              </RichTextSection>
            )}
            {/* Render the new component here */}
            {expired?.length > 0 && (
              <RichTextSection
                tag_id={'expired-announcements'}
                field="text"
                title={intl.formatMessage(messages.expired_announcements)}
                show_title={false}
              >
                <h4 className="mb-4 ">
                  {intl.formatMessage(messages.expired_announcements)}
                </h4>
                {expired?.map((announcement, index) => {
                  return (
                    <Card
                      key={index}
                      className="card card-teaser shadow p-4 mt-3 rounded attachment"
                      noWrapper={true}
                      tag="div"
                    >
                      <CardBody>
                        <CardTitle tag="h5">
                          <a href={flattenToAppURL(announcement['@id'])}>
                            {announcement.title}
                          </a>
                        </CardTitle>
                        {announcement?.description && (
                          <p>{announcement.description}</p>
                        )}
                      </CardBody>
                    </Card>
                  );
                })}
              </RichTextSection>
            )}
          </section>
        </div>
      </div>
      <BandoPlaceholderAfterContent content={content} />
      <RelatedItems content={content} />
      <RelatedItemInEvidence content={content} />
      <BandoPlaceholderAfterRelatedItems content={content} />
    </>
  );
};

PNAnnouncementsContainerView.propTypes = {
  content: PropTypes.shape({
    // Define your prop types here
  }).isRequired,
};

export default PNAnnouncementsContainerView;
