import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichText } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import moment from 'moment';

const messages = defineMessages({
  alert: {
    id: 'alert: ',
    defaultMessage: 'Avvisi: ',
  },
  expirationDate: {
    id: 'expirationDate: ',
    defaultMessage: 'Expiration date: ',
  },
});

const PNAlertBehavior = ({ text, expirationDate }) => {
  const intl = useIntl();
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    const expirationTime = new Date(expirationDate).getTime();
    const currentTime = new Date().getTime();

    // Check if the current time is greater than the expiration time
    if (currentTime > expirationTime) {
      setIsVisible(false); // Hide the alert if expired
    }
  }, [expirationDate]);

  return (
    <div className="bg-alert-warning">
      {isVisible && (
        <div
          className="text-left col-12 alert alert-warning"
          class="alert alert-warning"
          role="alert"
        >
          <div className="">
            <RichText title_size="h5" data={text} />
          </div>
        </div>
      )}
    </div>
  );
};

PNAlertBehavior.propTypes = {
  content: PropTypes.shape({
    alert_text: PropTypes.string,
    expirationDate: PropTypes.string,
  }).isRequired,
};

export default PNAlertBehavior;
