/**
 * Form widgets CreateReportWidget module.
 * @module formwidgets/createreportwidget
 *
 */

import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { expandToBackendURL } from '@plone/volto/helpers';

export const CreateReportWidget = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  const regex = new RegExp('^[a-z].*');
  let widgetParams = Object.keys(widget)
    .filter((el: string) => regex.test(el))
    .reduce((obj: any, key) => {
      obj[key] = widget[key];
      return obj;
    }, {});

  return <iframe src={expandToBackendURL(`@c_get_file/reports/` + widget.ReportCode + `/` + widget.ReportCode + `.pdf?extension=pdf&parameters=` + JSON.stringify(widgetParams))} data-ref="iframeEl" width="100%" height="100%" frameborder="0"></iframe>;
};
