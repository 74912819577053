import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  cosa_serve: {
    id: 'cosa_serve',
    defaultMessage: 'Cosa serve',
  },
});

const PNProcedureNeeded = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.cosa_serve) ? (
    <RichTextSection
      tag_id={'service-needed'}
      field="text"
      title={intl.formatMessage(messages.cosa_serve)}
      show_title={true}
      lighthouseId={'service-needed'}
    >
      <div
        className={'p-3'}
        style={{ backgroundColor: 'rgba(122,0,0,.10)', borderRadius: '5px' }}
      >
        <RichTextSection
          data={content.cosa_serve}
          tag_id={'service-needed'}
          field="text"
          title={intl.formatMessage(messages.cosa_serve)}
          show_title={false}
          lighthouseId={'service-needed'}
        />
      </div>
    </RichTextSection>
  ) : (
    ''
  );
};

PNProcedureNeeded.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureNeeded;
