import { getFunctionOutputsProxy, getGateResponse } from '../proxies';

import { FormTriggerModel } from '../models';

export const executeTriggersBeforeAction = (triggers: FormTriggerModel[], record: object, recordUpdater: Function): void => {
  const api = {
    executeGate: (gateName: string, method: string, params: object) => {
      return new Promise((resolve, reject) => {
        getGateResponse(gateName, method, params).then((response) => {
          resolve(response?.data);
        });
      });
    },
    getFunctionOutputs: (fnName: string, params: object, model: object) => {
      return new Promise((resolve, reject) => {
        getFunctionOutputsProxy(fnName, params).then((response) => {
          resolve(response?.data);
        });
      });
    },
    setValue: (attribute: string, value: any) => {
      if (record?.[attribute as keyof object] != value) {
        recordUpdater({
          ...record,
          [attribute]: value,
        });
      }
    },
    updateRecord: (data: object) => {
      recordUpdater({
        ...record,
        ...data,
      });
    },
  };

  triggers.forEach((t) => {
    let executeFormTrigger;
    let jsfn = `executeFormTrigger = function(api) {\n${t.script}\n}`;
    try {
      eval(jsfn);
    } catch (e) {
      console.error('Error on trigger function.', e);
      executeFormTrigger = () => {};
    }

    // use try / catch to manage errors
    try {
      executeFormTrigger(api);
    } catch (e) {
      console.error('Error on execution of form trigger.', e);
    }
  });
};
