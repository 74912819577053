/**
 * Form widgets OpenAttachmentWidget module.
 * @module formwidgets/openattachmentwidget
 *
 */

import React from 'react';
import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { expandToBackendURL } from '@plone/volto/helpers';
import { getProcessInstanceAttachmentsProxy } from '../proxies/processes';
import { Icon, Table } from 'design-react-kit';

export const OpenAttachmentWidget = ({ widget, context }: { widget: WidgetModel; context: CardModel | ProcessInstanceModel | object }) => {
  const [attachments, setAttachments] = React.useState([]);

  React.useEffect(() => {
    getProcessInstanceAttachmentsProxy(context._type, context._id, {}).then(({ data, meta }) => {
      setAttachments(data);
    });
  }, []);

  if (attachments?.length) {
    return (
      <Table hover>
        <tbody>
          {attachments.map((attachment: any, rowIndex) => (
            <tr key={rowIndex}>
              <td>{attachment.name}</td>
              <td style={{ textAlign: 'right' }}>
                <a href={expandToBackendURL(`@c_get_file/processes/${context._type}/instances/${context._id}/attachments/${attachment._id}/${attachment.name}`)} target="_blank">
                  <Icon
                    className=""
                    color="primary"
                    icon="it-clip"
                    size=""
                    title="Download"
                  />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  } else {
    return <p>Nessun file presente</p>;
  }
};
