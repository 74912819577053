import { RequestConfig } from './utils';
import { RequestResponse } from './utils';
import { makeGetRequest } from './utils';

export const getClassesProxy = async (config?: RequestConfig): Promise<RequestResponse> => {
    return makeGetRequest(`classes`, config);
}

export const getClassAttributesProxy = async (className: string, config?: RequestConfig): Promise<RequestResponse> => {
    return makeGetRequest(`classes/${className}/attributes`, config);
}

export const getCardsProxy = async (className: string, config?: RequestConfig): Promise<RequestResponse> => {
    return makeGetRequest(`classes/${className}/cards`, config);
}

export const getCardAttachmentsProxy = async (className: string, cardId: number, config?: RequestConfig): Promise<RequestResponse> => {
    return makeGetRequest(`classes/${className}/cards/${cardId}/attachments`, config);
}