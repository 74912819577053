/**
 * PNNewsView view component.
 * @module components/View/PNNewsItemView
 */

import {
  NewsItemPlaceholderAfterContent,
  PageHeader,
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  SkipToMainContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  PNAlertBehavior,
  PNAttachments,
  PNImageGallery,
  PNCuredBy,
} from 'comune-pordenone-plone-theme/components/View';
import { createRef, useState } from 'react';

import Image from '@plone/volto/components/theme/Image/Image';
import PNNewsItemText from './PNNewsItemText';
import PNNewsMoreInformation from './PNNewstMoreInformation';
import PropTypes from 'prop-types';

export const NewsSectionsOrder = [
  { /* TEXT */ component: PNNewsItemText },
  { /* IMAGES */ component: PNImageGallery },
  { /* ATTACHMENTS */ component: PNAttachments },
  { /* CURED BY */ component: PNCuredBy },
  { /* MORE INFORMATION */ component: PNNewsMoreInformation },
];

/**
 * PNNewsView view component class.
 * @function PNNewsView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNNewsView = ({ content, location }) => {
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  return (
    <>
      <div className="container px-4 my-4 newsitem-view">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />

        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />

        {/* HEADER IMAGE */}
        <div className="row row-column-border border-light row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container border-light"
            id="main-content-section"
            ref={documentBody}
          >
            {/* SEZIONI */}
            {content?.image && (
              <div>
                <Image
                  item={content}
                  alt={content.filename}
                  className=""
                  responsive={true}
                />
                <figcaption className="figure-caption text-center pt-3">
                  {content.image_caption}
                </figcaption>
              </div>
            )}
            <ContentTypeViewSections
              content={content}
              defaultSections={NewsSectionsOrder}
            />
          </section>
        </div>
      </div>
      <NewsItemPlaceholderAfterContent content={content} />
      <RelatedItems list={related_items} />
      {/* <RelatedItemInEvidence content={content} />
      <NewsItemPlaceholderAfterRelatedItems content={content} /> */}
    </>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNNewsView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    description: PropTypes.string,
    effective: PropTypes.string,
    expires: PropTypes.string,
    image: PropTypes.object,
    image_caption: PropTypes.string,
    text: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    dataset: PropTypes.shape({
      data: PropTypes.string,
    }),
    modified: PropTypes.string,
    rights: PropTypes.string,
    luoghi_correlati: PropTypes.array,
    related_news: PropTypes.array,
    tassonomia_argomenti: PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        token: PropTypes.string,
      }),
    ),
    tipologia_notizia: PropTypes.shape({
      title: PropTypes.string,
      token: PropTypes.string,
    }).isRequired,
  }).isRequired,
};

export default PNNewsView;
