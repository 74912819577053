/**
 * PNPressRelease view component.
 * @module components/theme/View/PNPressRelease
 */

import React, { createRef } from 'react';
import {
  PageHeader,
  RelatedItems,
  PersonaPlaceholderAfterContent,
  PersonaPlaceholderAfterRelatedItems,
  RelatedItemInEvidence,
  SkipToMainContent,
  ContentTypeViewSections,
  useSideMenu,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  PNAlertBehavior,
  PNAttachments,
  PNImageGallery,
} from 'comune-pordenone-plone-theme/components/View';
import { defineMessages, useIntl } from 'react-intl';
import PNPressReleaseInfo from './PNPressReleaseInfo';
import { viewDate } from 'design-comuni-plone-theme/helpers';
import PropTypes from 'prop-types';

export const PersonaViewSectionsOrder = [
  { /* DATE & TEXT} */ component: PNPressReleaseInfo },
  { /* FILE */ component: PNAttachments },
  { /* GALLERY */ component: PNImageGallery },
];

const messages = defineMessages({
  date: {
    id: 'date',
    defaultMessage: 'Data',
  },
  text: {
    id: 'text',
    defaultMessage: 'Testo',
  },
});

/**
 * PNPressRelease view component class.
 * @function PNPressRelease
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNPressRelease = ({ content }) => {
  const intl = useIntl();
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  const date = viewDate(intl.locale, content?.pressReleaseDate);
  return (
    <>
      <div className="container px-4 my-4">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={null}
          showreadingtime={false}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        <div>
          <p>
            {intl.formatMessage(messages.date)}: {date.format('DD MMMM YYYY')}
          </p>
        </div>
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <div className="row row-column-border border-light row-column-menu-left">
          <aside className="col-lg-4">
            <SideMenu data={sideMenuElements} content_uid={content?.UID} />
          </aside>
          <section
            className="col-lg-8 it-page-sections-container border-light"
            id="main-content-section"
            ref={documentBody}
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={PersonaViewSectionsOrder}
            />
          </section>
        </div>
      </div>
      <PersonaPlaceholderAfterContent content={content} />
      <RelatedItems content={content} />
      <RelatedItemInEvidence content={content} />
      <PersonaPlaceholderAfterRelatedItems content={content} />
    </>
  );
};
PNPressRelease.propTypes = {
  content: PropTypes.shape({
    date: PropTypes.object,
    text: PropTypes.object,
    attachments: PropTypes.object,
    images: PropTypes.object,
  }).isRequired,
};

export default PNPressRelease;
