/**
 * PNAssociationCategory view component.
 * @module components/theme/View/PNAssociationCategory
 */

import React, { createRef, useState } from 'react';
import { Container } from 'design-react-kit';
import PropTypes from 'prop-types';
import {
  PageHeader,
  useSideMenu,
  SkipToMainContent,
  RelatedItems,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';
import { flattenToAppURL } from '@plone/volto/helpers';
import { useIntl } from 'react-intl';
import config from '@plone/volto/registry';

/**
 * PNAssociationCategory view component class.
 * @function PNAssociationCategory
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNAssociationCategory = ({ content, location }) => {
  const [readingtime, setReadingtime] = useState(0);
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  const intl = useIntl();

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  return (
    <Container className="my-4 event-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showdates={false}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        <ul>
          {content?.Associations.map(
            (association, index) =>
              association?.category?.title ===
                content?.title + ' - ' + content?.description && (
                <li>
                  <a href={flattenToAppURL(association['@id'])}>
                    <strong>{association?.title}</strong>
                  </a>
                  <hr />
                </li>
              ),
          )}
        </ul>
        <RelatedItems content={content} />
      </div>
    </Container>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNAssociationCategory.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    effective: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    orari: PropTypes.shape({
      data: PropTypes.string,
    }),
    fee: PropTypes.shape({
      data: PropTypes.string,
    }),
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    modified: PropTypes.string,
  }).isRequired,
};

export default PNAssociationCategory;
