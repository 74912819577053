/**
 * Activities component.
 * @module components/Features/MyArea/Components/Activities
 *
 * Page which displays personal activities page.
 *
 */
import { useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { useViewsModel } from '../../../../addons/volto-cmdbuild/src/modelsuses';
import {
  Accordion,
  AccordionItem,
  AccordionHeader,
  AccordionBody,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'design-react-kit';
import { formatDate } from '@plone/volto/helpers/Utils/Date';
import PropTypes from 'prop-types';
import { Pagination } from 'design-comuni-plone-theme/components/ItaliaTheme/';
import { expandToBackendURL } from '@plone/volto/helpers';

const Activities = (config) => {
  const intl = useIntl();
  const { getItems } = useViewsModel();
  const viewName = 'LastActivities';
  const [collapseElementOpen, setCollapseElement] = useState('');
  const [items, setItems] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(items?.length);

  const messages = defineMessages({
    practice: {
      id: 'practice',
      defaultMessage: 'Pratica: ',
    },
    noActivities: {
      id: 'noActivities',
      defaultMessage: 'Al momento non ci sono attività',
    },
  });
  config = {
    ...config,
    sort: [
      {
        property: 'Date',
        direction: 'DESC',
      },
    ],
    limit: config?.limit || 10,
    page: currentPage,
    start: (currentPage - 1) * config?.limit,
  };

  useEffect(() => {
    getItems(viewName, config).then(({ data, meta }) => {
      setItems(
        data?.map((i) => {
          i.documenti = JSON.parse(i.Documenti);
          return i;
        }),
      );
      setTotalItems(meta?.total);
    });
    config = {
      ...config,
      start: (currentPage - 1) * config?.limit,
    };
  }, [currentPage]);

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage?.children);
  };
  const totalPages = Math.ceil(totalItems / config?.limit);
  const [openModal, setOpenModal] = useState(null);

  return (
    <>
      {items?.length > 0 ? (
        items?.map((item, index) => (
          <Accordion key={index}>
            <AccordionItem>
              <AccordionHeader
                active={collapseElementOpen === index}
                onToggle={() =>
                  setCollapseElement(collapseElementOpen !== index ? index : '')
                }
              >
                {item?.Servizio}
                &nbsp;
              </AccordionHeader>
              <div className="text-muted font-weight-light" id="accordion-date">
                {formatDate({
                  date: item?.Date,
                  format: 'dd-mm-yyyy',
                  locale: 'it-IT',
                })}
              </div>
              <AccordionBody active={collapseElementOpen === index}>
                <div>
                  <p>{item?.Descrizione}</p>
                  <p>
                    <b>{intl.formatMessage(messages.practice)}</b>
                    {item?.Numero}
                  </p>
                  {item?.documenti?.length > 0 &&
                    item?.documenti?.map((element, idx) => (
                      <div key={idx} className="mx-0 my-3 row ">
                        <Button
                          className="text-white btn btn-primary col-4 py-2"
                          onClick={() => setOpenModal(idx)}
                        >
                          {element?.description}
                        </Button>
                        <Modal
                          isOpen={openModal === idx}
                          toggle={() => setOpenModal(null)}
                          labelledBy={`widget-modal-${idx}`}
                          size="lg"
                        >
                          <ModalHeader
                            toggle={() => setOpenModal(null)}
                            id={`widget-modal-${idx}`}
                          >
                            {element.description}
                          </ModalHeader>
                          <ModalBody
                            style={{ height: window.innerHeight * 0.8 }}
                          >
                            {element?.type === 'report' ? (
                              <iframe
                                src={expandToBackendURL(
                                  `@c_get_file/reports/` +
                                    element?.code +
                                    `/` +
                                    element?.code +
                                    `.pdf?extension=pdf&parameters=` +
                                    JSON.stringify({
                                      process_id: item['Id richiesta'],
                                    }),
                                )}
                                data-ref="iframeEl"
                                width="100%"
                                height="100%"
                                frameborder="0"
                              ></iframe>
                            ) : (
                              <p>
                                Tipologia di documento:
                                {' ' + element?.type}
                              </p>
                            )}
                          </ModalBody>
                          <ModalFooter></ModalFooter>
                        </Modal>
                      </div>
                    ))}
                </div>
              </AccordionBody>
            </AccordionItem>
          </Accordion>
        ))
      ) : (
        <p>{intl.formatMessage(messages.noActivities)}</p>
      )}
      {/* PAGINATION FROM DESIGN COMUNI PLONE THEME */}
      {config?.tab === 'ActivitiesPage' && totalPages > 1 ? (
        <Pagination
          defaultActivePage={1}
          activePage={1}
          boundaryRange={0}
          siblingRange={3}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      ) : (
        ''
      )}
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Activities.propTypes = {
  limit: PropTypes.number,
  config: PropTypes.object,
};

export default Activities;
