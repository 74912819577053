import { RequestConfig } from '../proxies/utils';
import { getItemsProxy } from '../proxies/views';

const useViewsModel = () => {
  const getItems = async (viewName: string, config?: RequestConfig) => {
    return getItemsProxy(viewName, config);
  };

  return { getItems };
};

export default useViewsModel;
