import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  topic_list: {
    id: 'topic_list',
    defaultMessage: 'Tassonomie argomenti',
  },
});

const PNProcedureArguments = ({ content }) => {
  const intl = useIntl();

  return (
    <></>
    // content?.tassonomia_argomenti?.length > 0 && (
    //   <RichTextSection
    //     tag_id={'service-arguments'}
    //     field="text"
    //     title={intl.formatMessage(messages.topic_list)}
    //     show_title={true}
    //     lighthouseId={'topic-list'}
    //   >
    //     {/* {content.tassonomia_argomenti.map((argument, index) => (
    //       <div className="chip chip-simple chip-primary mr-2" key={index}>
    //         <a href={content['@id']} data-element="topic-list">
    //           <span className="chip-label">{argument}</span>
    //         </a>
    //       </div>
    //     ))} */}
    //   </RichTextSection>
    // )
  );
};

PNProcedureArguments.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureArguments;
