import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  serviceCategory: {
    id: 'serviceCategory',
    defaultMessage: 'Categoria del servizio',
  },
});

const PNProcedureCategory = ({ content }) => {
  const intl = useIntl();

  return content?.categoria_servizio ? (
    <>
      <RichTextSection
        tag_id={'serviccategoria_servizioeCategory'}
        field="text"
        title={intl.formatMessage(messages.serviceCategory)}
        show_title={true}
        lighthouseId={'breadcrumb'}
      >
        <ol data-element="breadcrumb">
          {content?.categoria_servizio.map((category, index) => (
            <li>{category?.title}</li>
          ))}
        </ol>
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

PNProcedureCategory.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureCategory;
