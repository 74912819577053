import PropTypes from 'prop-types';
import { richTextHasContent } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  socials: {
    id: 'socials',
    defaultMessage: 'Seguici su',
  },
  statute: {
    id: 'statute',
    defaultMessage: 'Statuto',
  },
  incorporationDate: {
    id: 'incorporationDate',
    defaultMessage: 'Data di costituzione',
  },
  address: {
    id: 'address',
    defaultMessage: 'Indirizzo',
  },
  webSite: {
    id: 'webSite',
    defaultMessage: 'Sito web',
  },
  email: {
    id: 'E-mail',
    defaultMessage: 'E-mail',
  },
  telephone: {
    id: 'telephone',
    defaultMessage: 'Telefono',
  },
  mobile: {
    id: 'mobile',
    defaultMessage: 'Cellulare',
  },
  president: {
    id: 'president',
    defaultMessage: 'Presidente',
  },
  presentation: {
    id: 'presentation',
    defaultMessage: 'Presentazione',
  },
  category: {
    id: 'category',
    defaultMessage: 'Categoria',
  },
});

const PNAssociationData = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {/* Statute */}
      {content?.statute && (
        <RichTextSection
          tag_id={'association-statute'}
          field="text"
          title={intl.formatMessage(messages.statute)}
          show_title={true}
        >
          <a href={flattenToAppURL(content.statute.download)}>
            {content.statute.filename}
          </a>
        </RichTextSection>
      )}
      {/* Incorpoation date */}
      {content?.incorporationDate && (
        <RichTextSection
          tag_id={'association-incorporationDate'}
          field="text"
          title={intl.formatMessage(messages.incorporationDate)}
          show_title={true}
        >
          <p>{content.incorporationDate}</p>
        </RichTextSection>
      )}
      {/* address */}
      {content?.address && (
        <RichTextSection
          tag_id={'association-address'}
          field="text"
          title={intl.formatMessage(messages.address)}
          show_title={true}
        >
          <p>{content.address}</p>
        </RichTextSection>
      )}
      {/* website */}
      {content?.web && (
        <RichTextSection
          tag_id={'association-webSite'}
          field="text"
          title={intl.formatMessage(messages.webSite)}
          show_title={true}
        >
          <a href={flattenToAppURL(content.web)}>{content.web}</a>
        </RichTextSection>
      )}
      {/* E-mail */}
      {content?.email && (
        <RichTextSection
          tag_id={'association-email'}
          field="text"
          title={intl.formatMessage(messages.email)}
          show_title={true}
        >
          <ContactLink email={content.email} label={false} />
        </RichTextSection>
      )}
      {/* telephone */}
      {content?.telephone && (
        <RichTextSection
          tag_id={'association-telephone'}
          field="text"
          title={intl.formatMessage(messages.telephone)}
          show_title={true}
        >
          <p>{content.telephone}</p>
        </RichTextSection>
      )}
      {/* mobile */}
      {content?.mobile && (
        <RichTextSection
          tag_id={'association-mobile'}
          field="text"
          title={intl.formatMessage(messages.mobile)}
          show_title={true}
        >
          <p>{content.mobile}</p>
        </RichTextSection>
      )}
      {/* socials */}
      {content?.socialFacebook ||
      content?.socialInstagram ||
      content?.socialLinkedin ||
      content?.socialTwitter ||
      content?.socialYoutube ? (
        <RichTextSection
          tag_id={'association-socials'}
          field="text"
          title={intl.formatMessage(messages.socials)}
          show_title={true}
        >
          {content?.socialFacebook && (
            <a href={flattenToAppURL(content?.socialFacebook)}>
              <Icon
                icon="it-facebook"
                padding={true}
                className="icon-administrator"
              />
            </a>
          )}
          {content?.socialInstagram && (
            <a href={flattenToAppURL(content?.socialInstagram)}>
              <Icon
                icon="it-instagram"
                padding={true}
                className="icon-administrator"
              />
            </a>
          )}
          {content?.socialLinkedin && (
            <a href={flattenToAppURL(content?.socialLinkedin)}>
              <Icon
                icon="it-linkedin"
                padding={true}
                className="icon-administrator"
              />
            </a>
          )}
          {content?.socialTwitter && (
            <a href={flattenToAppURL(content?.socialTwitter)}>
              <Icon
                icon="it-twitter"
                padding={true}
                className="icon-administrator"
              />
            </a>
          )}
          {content?.socialYoutube && (
            <a href={flattenToAppURL(content?.socialYoutube)}>
              <Icon
                icon="it-youtube"
                padding={true}
                className="icon-administrator"
              />
            </a>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
      {/* president */}
      {content?.president && (
        <RichTextSection
          tag_id={'association-president'}
          field="text"
          title={intl.formatMessage(messages.president)}
          show_title={true}
        >
          <p>{content.president}</p>
        </RichTextSection>
      )}
      {/* Presentation */}
      {richTextHasContent(content?.aim) && (
        <RichTextSection
          tag_id={'association-presentation'}
          field="text"
          title={intl.formatMessage(messages.presentation)}
          show_title={true}
          data={content.aim}
        />
      )}
      {/* Category */}
      {content?.category && (
        <RichTextSection
          tag_id={'association-category'}
          field="text"
          title={intl.formatMessage(messages.category)}
          show_title={true}
        >
          <a href={flattenToAppURL('resolveuid/' + content?.category?.token)}>
            {content.category?.title}
          </a>
        </RichTextSection>
      )}
    </>
  );
};

PNAssociationData.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNAssociationData;
