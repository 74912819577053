import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import Image from '@plone/volto/components/theme/Image/Image';

import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Col, Row } from 'design-react-kit';

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
});

const PNAssociationEventFileImage = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {content?.image && (
        <a
          data-pat-plone-modal="image: true"
          className="pat-plone-modal"
          href={content?.image.scales.large.download}
          title="Vedi immagine"
        >
          <Image
            item={content}
            alt={content.filename}
            className="img-fluid mb-4 col-6"
          />
        </a>
      )}
      {content?.file && (
        <RichTextSection
          tag_id="Allegati"
          title={intl.formatMessage(messages.attachments)}
          className="mt-2"
        >
          <a
            data-pat-plone-modal="image: true"
            className="pat-plone-modal"
            href={content?.file}
            title="Vedi immagine"
          >
            {content?.file?.filename}
          </a>
        </RichTextSection>
      )}
    </>
  );
};

PNAssociationEventFileImage.propTypes = {
  content: PropTypes.shape({
    prezzo: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PNAssociationEventFileImage;
