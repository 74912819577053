/**
 * Functions proxy.
 * @module proxies/functions
 *
 * Proxy for functions management.
 *
 */
import { makeGetRequest } from './utils';

export const getFunctionOutputsProxy = async (functionName: string, functionParameters: object): Promise<object | null> => {
  const url = `functions/${functionName}/outputs`;

  return makeGetRequest(url, {
    params: {
      parameters: JSON.stringify(functionParameters),
    },
  });
};
