import { Container, Card, CardBody, CardTitle } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  attachments: {
    id: 'attachments',
    defaultMessage: 'Allegati',
  },
  attachment: {
    id: 'attachment',
    defaultMessage: 'Allegato',
  },
});

const PNAttachmentsView = ({ content }) => {
  const intl = useIntl();

  return (
    <Container className="my-4 event-view">
      <div className="px-4 px-lg-0">
        <RichTextSection
          tag_id="attachments_view"
          title={intl.formatMessage(messages.attachments)}
        >
          <Card
            key={content?.id}
            className="card card-teaser shadow p-4 mt-3 rounded attachment"
            noWrapper={true}
            tag="div"
          >
            <Icon
              icon="it-clip"
              alt={intl.formatMessage(messages.attachment)}
              title={intl.formatMessage(messages.attachment)}
            />
            <CardBody tag="div">
              <CardTitle tag="h5">
                <a
                  href={flattenToAppURL(content['@id'])}
                  download
                  data-element="service-file"
                >
                  {content?.title}
                </a>
              </CardTitle>
              <p>{content?.description}</p>
            </CardBody>
          </Card>
        </RichTextSection>
      </div>
    </Container>
  );
};

PNAttachmentsView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAttachmentsView;
