/**
 * ActivitiesPage component.
 * @module components/Features/MyArea/ActivitiesPage
 *
 * Page which displays personal activities page.
 *
 */
import { createRef } from 'react';
import { useSideMenu } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { Col, Row } from 'design-react-kit';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PropTypes from 'prop-types';

import Activities from './components/Activities';

const messages = defineMessages({
  practices: {
    id: 'practices',
    defaultMessage: 'Pratiche',
  },
});
const ActivitiesPage = (props) => {
  const intl = useIntl();
  let documentBody = createRef();
  const content = props.content;
  const { sideMenuElements, SideMenu } = useSideMenu(props, documentBody);
  return (
    <>
      <div>
        <Row className="border-top-0 row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            <SideMenu data={sideMenuElements} />
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            <RichTextSection
              tag_id={'title-practices'}
              field="text"
              title={intl.formatMessage(messages.practices)}
              show_title={true}
            >
              <div>
                <Activities limit={10} tab={'ActivitiesPage'} />
              </div>
            </RichTextSection>
          </section>
        </Row>
      </div>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
ActivitiesPage.propTypes = {};

export default ActivitiesPage;
