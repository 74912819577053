/**
 * useClassModel modelsuses.
 * @module modelsuses/useClassModel
 *
 */

import { ClassModel } from '../models';
import React from 'react';
import { RequestConfig } from '../proxies/utils';
import { getCardsProxy } from '../proxies/classes';
import { getClassesProxy } from '../proxies/classes';

const useClassModel = () => {
  // classes store
  const [classesData, setClassesData] = React.useState<ClassModel[]>();

  // get classes
  const getClasses = React.useCallback(async (): Promise<ClassModel[]> => {
    const classes = (
      await getClassesProxy({
        params: {
          detailed: true,
        },
      })
    ).data as ClassModel[];
    setClassesData(classes);
    return classes;
  }, []);

  // get class
  const getClass = async (className: string): Promise<ClassModel> => {
    const classes = await getClasses();
    return classes?.find((cls) => cls.name === className);
  };

  const getCards = async (className: string, config?: RequestConfig) => {
    const cls = await getClass(className);

    // default sorting
    if (!config?.sort) {
      if (cls?.defaultOrder?.length) {
        config.sort = cls.defaultOrder.map((o: object) => {
          return {
            property: o['attribute'],
            direction: o['direction'] === 'descending' ? 'DESC' : 'ASC',
          };
        });
      } else {
        config.sort = [
          {
            property: 'Description',
            direction: 'ASC',
          },
        ];
      }
    }

    return getCardsProxy(className, config);
  };

  return {
    getCards,
  };
};

export default useClassModel;
