import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardTitle, CardText } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  textInfo: {
    id: 'textInfo',
    defaultMessage: 'Informazioni',
  },
  secretariat: {
    id: 'secretariat',
    defaultMessage: 'Segreteria',
  },
});

const PNAdministratorsViewSecretariat = ({ content }) => {
  const intl = useIntl();

  return (
    content?.secretariats?.length > 0 && (
      <RichTextSection
        tag_id="secretariat-list"
        title={intl.formatMessage(messages.secretariat)}
        show_title={false}
      >
        <div className="secretariat-card rounded shadow mt-5 p-0">
          <Card className="card rounded shadow">
            <CardTitle tag="h4" className="p-3 secretariat-title">
              {intl.formatMessage(messages.secretariat)}
            </CardTitle>
            <CardText className="card-text">
              {content?.secretariats.map((secretary, index) => (
                <p className="p-3 pt-1">
                  <a href={flattenToAppURL('resolveuid/' + secretary?.token)}>
                    {secretary?.title}
                  </a>
                </p>
              ))}
            </CardText>
          </Card>
        </div>
      </RichTextSection>
    )
  );
};

PNAdministratorsViewSecretariat.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewSecretariat;
