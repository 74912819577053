import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { Chip, ChipLabel } from 'design-react-kit';
import {
  RichTextSection,
  richTextHasContent,
  Gallery,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  cos_e: {
    id: 'event_cos_e',
    defaultMessage: "Cos'è",
  },
  parteciperanno: {
    id: 'parteciperanno',
    defaultMessage: 'Parteciperanno',
  },
  short_description: {
    id: 'short_description',
    defaultMessage: 'Descrizione breve',
  },
});

const PNEventCosE = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.text) ||
    content?.attendees?.length > 0 ||
    content?.contributors?.length > 0 ||
    richTextHasContent(content?.short_description) ? (
    <>
      <RichTextSection
        tag_id={'text-body'}
        field="text"
        data={content?.text}
        title={intl.formatMessage(messages.cos_e)}
        show_title={true}
      >
        <Gallery content={content} folder_name={'multimedia'} />

        {content?.attendees?.length > 0 && (
          <div className="mb-5">
            <h5>{intl.formatMessage(messages.parteciperanno)}</h5>
            {content?.attendees.map((item, i) => (
              <Chip
                color="primary"
                disabled={false}
                large={false}
                simple
                tag="div"
                key={item['@id']}
                className="mr-2"
              >
                <ChipLabel tag="span">{item.title}</ChipLabel>
              </Chip>
            ))}
          </div>
        )}
      </RichTextSection>
      {richTextHasContent(content?.short_description) && (
        <RichTextSection
          tag_id={'text-body-shortDescription'}
          field="text"
          data={content.short_description}
          title={intl.formatMessage(messages.short_description)}
          show_title={true}
        ></RichTextSection>
      )}
    </>
  ) : (
    ''
  );
};

PNEventCosE.propTypes = {
  content: PropTypes.shape({
    descrizione_completa: PropTypes.object,
    short_description: PropTypes.shape({
      data: PropTypes.string,
    }),
    persone_amministrazione: PropTypes.array,
  }).isRequired,
};

export default PNEventCosE;
