/**
 * Volto CMDBuild proxy utilities.
 * @module proxies/utils
 *
 * Utility for CMDBuild proxy.
 *
 */
import { expandToBackendURL } from '@plone/volto/helpers';

export interface RequestConfig {
  params?: object;
  attrs?: string | string[];
  sort?: object | object[];
  limit?: number;
  start?: number;
  page?: number;
  filter?: object;
}

export interface RequestResponse {
  data: object | object[];
  meta: object;
}

export const makeGetRequest = async (path: string, config?: RequestConfig) => {
  let url = expandToBackendURL(`@c_services/${path}`);
  let params = {};

  // attribtues
  if (config?.attrs) {
    params = {
      ...params,
      attrs: Array.isArray(config.attrs) ? config.attrs.join(',') : config.attrs,
    };
  }

  // sorters
  if (config?.sort) {
    params = {
      ...params,
      sort: JSON.stringify(config.sort),
    };
  }

  // filter
  if (config?.filter) {
    params = {
      ...params,
      filter: JSON.stringify(config.filter),
    };
  }

  // limit
  if (config?.limit) {
    params = {
      ...params,
      limit: config.limit,
    };
  }

  // start
  if (config?.start) {
    params = {
      ...params,
      start: config.start,
    };
  }

  // page
  if (config?.page) {
    params = {
      ...params,
      page: config.page,
    };
  }

  // other parameters
  if (config?.params && Object.keys(config?.params || {}).length > 0) {
    params = {
      ...params,
      ...config.params,
    };
  }

  if (Object.keys(params).length) {
    url += '?' + new URLSearchParams(params);
  }

  const response = await fetch(url, {
    headers: {
      Accept: 'application/json',
    },
  });
  return await response.json();
};

export const makePostRequest = async (path: string, data: object) => {
  let url = expandToBackendURL(`@c_services/${path}`);

  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

export const makePutRequest = async (path: string, data: object) => {
  let url = expandToBackendURL(`@c_services/${path}`);

  const response = await fetch(url, {
    method: 'PUT',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  });
  return response.json(); // parses JSON response into native JavaScript objects
};

export const uploadFileRequest = (path: string, file: Blob, config: { filePartName?: string; metadataPartName?: string; extraParams?: object }) => {
  return new Promise((resolve, reject) => {
    let url = expandToBackendURL(`@c_upload_file/${path}`);

    const xmlhttp = new XMLHttpRequest();
    let formData = new FormData();

    // use cookie authentication
    xmlhttp.withCredentials = true;

    // Response handler
    xmlhttp.onreadystatechange = function (e) {
      if (this.readyState === 4) {
        if ([200, 201, 204].indexOf(this.status) !== -1) {
          resolve(JSON.parse(this.responseText).data);
        } else {
          // eslint-disable-next-line no-console
          console.error('Error on uploading file');
          reject(JSON.parse(this.responseText));
        }
      }
    };

    // Error handler
    xmlhttp.upload.onerror = function () {
      reject(JSON.parse(this.responseText));
    };

    // append file part
    if (file) {
      formData.append(config.filePartName || 'file', file);
    }

    // add extra params
    if (config.extraParams) {
      const query = new URLSearchParams(config.extraParams as URLSearchParams);
      url += '?' + query.toString();
    }

    // open form with file using XMLHttpRequest POST request
    xmlhttp.open('POST', url, true);

    // set header
    xmlhttp.setRequestHeader('Accept', 'application/json');

    // finally send
    xmlhttp.send(formData);
  });
};
