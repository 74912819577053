import PropTypes from 'prop-types';
import { Card, CardBody, CardText, Col, Row } from 'design-react-kit';
import {
  EventoDateOrari,
  RichTextSection,
  Dates,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import Image from '@plone/volto/components/theme/Image/Image';
import { defineMessages, useIntl } from 'react-intl';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  event: {
    id: 'event',
    defaultMessage: 'Evento',
  },
});

const PNAssociationLogo = ({ content }) => {
  const intl = useIntl();

  return (
    content?.items?.length > 0 && (
      <>
        <RichTextSection
          tag_id={'association-event'}
          field="text"
          title={intl.formatMessage(messages.event)}
          show_title={true}
        >
          {content.items.map((event, index) => (
            <Card className="card rounded shadow">
              <CardBody>
                <CardText>
                  <a href={flattenToAppURL(event['@id'])}>{event?.title}</a>
                </CardText>
                <CardText>{content?.description}</CardText>
              </CardBody>
            </Card>
          ))}
        </RichTextSection>
      </>
    )
  );
};

PNAssociationLogo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNAssociationLogo;
