import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  terms: {
    id: 'terms',
    defaultMessage: 'Condizioni di servizio',
  },
});

const PNProcedureTerms = ({ content }) => {
  const intl = useIntl();

  return (
    content?.TermsService && (
      <RichTextSection
        tag_id={'terms-service'}
        field="text"
        title={intl.formatMessage(messages.terms)}
        show_title={true}
        data-element="page-index"
        lighthouseId={'service-file'}
      >
        <Card
          key={content?.TermsService?.download}
          className="card card-teaser shadow p-4 mt-3 rounded attachment"
          noWrapper={true}
          tag="div"
        >
          <Icon
            icon="it-clip"
            alt={intl.formatMessage(messages.terms)}
            title={intl.formatMessage(messages.terms)}
          />
          <CardBody tag="div">
            <CardTitle tag="h5">
              <a
                href={flattenToAppURL(content?.TermsService?.download)}
                download
                data-element="service-file"
              >
                {content?.TermsService?.filename}
              </a>
            </CardTitle>
          </CardBody>
        </Card>
      </RichTextSection>
    )
  );
};

PNProcedureTerms.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureTerms;
