/**
 * Selectable Grid Widget.
 * @module formwidgets/selectablegridwidget
 *
 */
import { Alert, FormGroup, Input, Label, Spinner, Table } from 'design-react-kit';
import { useEffect, useState } from 'react';

import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { getGateResponse } from '../proxies';
import cx from 'classnames';
import { updateVisibility, extractFieldFeatureBindings, getFieldFeatureApis, getShowIfFn } from './../utilities/fieldshelper';

export const SelectableGridWidget = ({ config, target }: { config: WidgetModel; target: CardModel | ProcessInstanceModel | object }) => {
  const columns = JSON.parse(config.Columns);
  const [contentData, setContentData] = useState<object[]>([]);
  const [selectedItems, setSelectedItems] = useState('{}');
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const disabledVal: string[] = config?.DisabledValues.split(',') || [];
  // CSS style
  const alignTableText = {
    verticalAlign: 'middle',
  };

  const showIf = config.ShowIf || '';
  let showIfBindings;
  let showIfApis: any;
  let showIfFn: Function | undefined;

  if (showIf) {
    showIfBindings = extractFieldFeatureBindings(showIf, target);
    showIfApis = getFieldFeatureApis(target, {});
    showIfFn = getShowIfFn(showIf);
  }

  useEffect(() => {
    if (config.Endpoint) {
      getGateResponse(config.Endpoint, 'GET', {}).then((response: { data?: object[]; meta?: object }) => {
        setContentData(response?.data);
        setIsLoading(false);
      });
    }
  }, []);

  // Save selected items in target
  if (target && config._output) {
    target[config._output as keyof object] = selectedItems;
  }

  const isDisabledValue = (row: object): boolean => {
    if (config?.DisabledAttr) {
      return disabledVal.includes(row[`${config?.DisabledAttr}` as keyof object]);
    }
    return false;
  };

  // Check ReadOnly property
  let readOnly = false;
  if (config?.ReadOnly === true) {
    readOnly = true;
  }

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  return (
    <div style={{ marginBottom: '2rem' }}>
      {config?.Help ? <Alert color="info">{config?.Help}</Alert> : null}
      <Table hover hidden={!isVisible}>
        <thead>
          <tr>
            <th></th>
            {columns?.map((col: { descr: string }, index: number) => (
              <th scope="col" key={index}>
                {col.descr}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {isLoading ? (
            <tr>
              <td colSpan={columns?.length + 1}>
                <div className="d-flex justify-content-center">
                  <Spinner active small />
                </div>
              </td>
            </tr>
          ) : (
            contentData?.map((row: object, rowIndex: number) => (
              <tr key={rowIndex}>
                <td style={alignTableText}>
                  <FormGroup check>
                    <Input type="checkbox" id={`selectablegrid-row-${rowIndex}`} onChange={() => setSelectedItems(JSON.stringify(row))} checked={selectedItems === JSON.stringify(row)} disabled={readOnly || isDisabledValue(row)} />
                    <Label className="mb-0" for={`selectablegrid-row-${rowIndex}`}></Label>
                  </FormGroup>
                </td>
                {columns?.map((col: { code: string }, colIndex: number) => (
                  <td key={colIndex} style={alignTableText}>
                    {row[col.code as keyof object]}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};
