import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import { Card, CardBody, CardTitle } from 'design-react-kit';
import { viewDate } from 'design-comuni-plone-theme/helpers';

const messages = defineMessages({
  tempi_e_scadenze: {
    id: 'tempi_e_scadenze',
    defaultMessage: 'Tempi e scadenze',
  },
  start: {
    id: 'start',
    defaultMessage: 'Inizio evento',
  },
  end: {
    id: 'end',
    defaultMessage: 'Fine evento',
  },
  open_end: {
    id: 'open_end',
    defaultMessage: 'Questo evento ha una data di fine aperta/variabile.',
  },
  whole_day: {
    id: 'whole_day',
    defaultMessage: 'Questo evento ha luogo per tutta la giornata.',
  },
  additional_dates: {
    id: 'Date aggiuntive',
    defaultMessage: 'Date aggiuntive',
  },
  excluded_dates: {
    id: "L'evento non si terrà nelle seguenti date",
    defaultMessage: "L'evento non si terrà nelle seguenti date",
  },
});

const PNProcedureTimesDeadlines = ({ content }) => {
  const intl = useIntl();
  const first_date = viewDate(intl.locale, content.first_date);
  const second_date = viewDate(intl.locale, content.second_date);
  const third_date = viewDate(intl.locale, content.third_date);
  const fourth_date = viewDate(intl.locale, content.fourth_date);

  return richTextHasContent(content?.tempi_e_scadenze) ? (
    <RichTextSection
      data={content.tempi_e_scadenze}
      tag_id={'service-dates'}
      field="text"
      title={intl.formatMessage(messages.tempi_e_scadenze)}
      show_title={true}
      lighthouseId={'service-calendar-text'}
    >
      <div
        className="point-list-wrapper my-4 mb-5 service-dates"
        data-element="service-calendar-list"
      >
        {content?.first_date && (
          <div className="point-list">
            <div className="point-list-aside point-list-primary">
              <div className="point-date text-monospace">
                {first_date.format('DD')}
              </div>
              <div className="point-month text-monospace">
                {first_date.format('MMM')}
              </div>
            </div>
            <div className="point-list-content">
              <Card
                className="card card-teaser card-teaser-danger rounded shadow pb-0"
                noWrapper={true}
                tag="div"
              >
                <CardBody tag="div" className={'card-body'}>
                  <CardTitle tag="h5">
                    {first_date.format('HH:mm')}
                    {content?.description_first_date &&
                      ' - ' + content.description_first_date}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        {content?.second_date && (
          <div className="point-list">
            <div className="point-list-aside point-list-primary">
              <div className="point-date text-monospace">
                {second_date.format('DD')}
              </div>
              <div className="point-month text-monospace">
                {second_date.format('MMM')}
              </div>
            </div>
            <div className="point-list-content">
              <Card
                className="card card-teaser card-teaser-danger rounded shadow pb-0"
                noWrapper={true}
                tag="div"
              >
                <CardBody tag="div" className={'card-body'}>
                  <CardTitle tag="h5">
                    {second_date.format('HH:mm')}
                    {content?.description_second_date &&
                      ' - ' + content.description_second_date}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        {content?.third_date && (
          <div className="point-list">
            <div className="point-list-aside point-list-primary">
              <div className="point-date text-monospace">
                {third_date.format('DD')}
              </div>
              <div className="point-month text-monospace">
                {third_date.format('MMM')}
              </div>
            </div>
            <div className="point-list-content">
              <Card
                className="card card-teaser card-teaser-danger rounded shadow pb-0"
                noWrapper={true}
                tag="div"
              >
                <CardBody tag="div" className={'card-body'}>
                  <CardTitle tag="h5">
                    {third_date.format('HH:mm')}
                    {content.description_third_date &&
                      ' - ' + content.description_third_date}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
        {content?.fourth_date && (
          <div className="point-list">
            <div className="point-list-aside point-list-primary">
              <div className="point-date text-monospace">
                {fourth_date.format('DD')}
              </div>
              <div className="point-month text-monospace">
                {fourth_date.format('MMM')}
              </div>
            </div>
            <div className="point-list-content">
              <Card
                className="card card-teaser card-teaser-danger rounded shadow pb-0"
                noWrapper={true}
                tag="div"
              >
                <CardBody tag="div" className={'card-body'}>
                  <CardTitle tag="h5">
                    {fourth_date.format('HH:mm')}
                    {content.description_fourth_date &&
                      ' - ' + content.description_fourth_date}
                  </CardTitle>
                </CardBody>
              </Card>
            </div>
          </div>
        )}
      </div>
    </RichTextSection>
  ) : (
    ''
  );
};

PNProcedureTimesDeadlines.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureTimesDeadlines;
