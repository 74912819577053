/**
 * Process Instance Form.
 * @module utilities/processinstanceform
 *
 */
import { ActivityModel, AttributeModel, ProcessInstanceModel, ProcessModel } from '../models';
import { Button, Col, Icon, Row, Spinner, StepperContainer, StepperContent, StepperNav } from 'design-react-kit';
import { InlineWidgets, WidgetsButtons } from './widgetshelper';
import React, { createRef, useEffect, useState } from 'react';
import { defineMessages, useIntl } from 'react-intl';

import { CMDBuildForm } from './formhelper';
import { ProcessStateStepper } from '../utilities/processstatestepper';
import SideMenu from './sidenavbar';
import { executeTriggersBeforeAction } from './formtriggers';
import { useProcessModel } from '../modelsuses';

const messages = defineMessages({
  go_back: {
    id: 'go_back',
    defaultMessage: 'Indietro',
  },
  save_request: {
    id: 'save_request',
    defaultMessage: 'Salva richiesta',
  },
  advance: {
    id: 'advance',
    defaultMessage: 'Avanti',
  },
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
});

const ProcessInstanceForm = ({ processName, instanceId, activityId, config }: { processName: string; instanceId: number; activityId: string; config: object }) => {
  let documentBody = createRef();
  const intl = useIntl();
  const { getProcess, getProcessAttributes, getProcessInstance, getProcessActivity, advanceProcessInstance, saveProcessInstance } = useProcessModel();

  const [process, setProcess] = useState<ProcessModel | null>();
  const [processInstance, setProcessInstance] = useState<ProcessInstanceModel | null>();
  const [processActivity, setProcessActivity] = useState<ActivityModel | null>();
  const [activityAttributes, setActivityAttributes] = useState<AttributeModel[] | object[] | null>();
  const [fieldsets, setFieldsets] = useState([]);
  const [processAttributes, setProcessAttributes] = useState<AttributeModel[] | object[] | null>();
  const [isSaving, setIsSaving] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    // load process info
    getProcess(processName).then((response) => {
      setProcess(response);
    });

    // load attributes
    getProcessAttributes(processName).then((response) => {
      setProcessAttributes(response);
    });

    let activityRequestParams: [string | number] = [processName];
    if (instanceId && activityId) {
      // load process instance
      getProcessInstance(processName, instanceId).then((data) => {
        setProcessInstance(data);
      });
      // add instance id and activity id in activity request parameters
      activityRequestParams.push(instanceId, activityId);
    } else {
      // set empty process instance
      setProcessInstance({
        _type: processName,
      });
    }
    // load activity
    getProcessActivity.apply(this, activityRequestParams).then((activity) => {
      setProcessActivity(activity);
    });
  }, []);

  useEffect(() => {
    if (processActivity) {
      const attributes: AttributeModel[] | undefined = processActivity.attributes?.map((attr) => {
        return {
          ...attr.detail,
          index: attr.index,
          writable: attr.writable,
          mandatory: attr.mandatory,
        } as AttributeModel;
      });
      setActivityAttributes(attributes);
    }
  }, [processActivity]);

  useEffect(() => {
    if (process) {
      setFieldsets(process.attributeGroups);
    }
  }, [process]);

  const [formTriggerExecuted, setFormTriggerExecuted] = useState(false);
  useEffect(() => {
    if (process && processInstance && !formTriggerExecuted) {
      setFormTriggerExecuted(true);
      const isNewInstance = !processInstance._id;
      let formTriggers = [];
      if (isNewInstance) {
        formTriggers = process.formTriggers?.filter((ft: object) => ft.beforeInsert && ft.active);
      }
      // execute form triggers
      if (formTriggers.length) {
        executeTriggersBeforeAction(formTriggers, processInstance, setProcessInstance);
      }
    }
  }, [process, processInstance]);

  const onAdvanceClick = () => {
    setIsSaving(true);
    advanceProcessInstance(processName, processInstance._id, processActivity?._id, processInstance).then((response) => {
      if (response?.success) {
        const processInstanceData = response.data;
        setActivityAttributes([]);
        setProcessInstance({});
        const current_activity = processInstanceData?._tasklist ? processInstanceData?._tasklist[0] : { _id: '' };
        getProcessActivity(processInstanceData?._type, processInstanceData?._id, current_activity._id).then((activity) => {
          setProcessActivity(activity);
          setIsSaving(false);
        });
        setProcessInstance(processInstanceData);
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
        setIsLoading(false);
      }
    });
  };

  const onSaveClick = () => {
    setIsSaving(true);
    saveProcessInstance(processName, processInstance._id, processActivity?._id, processInstance).then((response) => {
      if (response?.success) {
        // TODO: ....
        setIsSaving(false);
      }
    });
  };

  // CSS STYLE FOR CARDS
  const CardStyle = {
    backgroundColor: '#ebeef0',
  };

  const [currentStep, setCurrentStep] = useState(0);

  const handleNextClick = () => {
    setIsLoading(true);
    setCurrentStep(currentStep + 1);
    onAdvanceClick();
  };

  const processState = processInstance?.State;
  const tabData = process?.lookupValues;

  return (
    <>
      <StepperContainer>
        {/* PASSARE SOLO GLI ATTRIBUTI E IL CONTESTO */}
        <ProcessStateStepper attributes={processAttributes} context={processInstance} />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            <SideMenu data={documentBody} groups={fieldsets} />
          </Col>
          <section id="main-content-section" className="col-lg-8 it-page-sections-container">
            {/* SEZIONI */}
            <StepperContent className="h-auto border-0 bg-white d-block">
              <div className="it-page-sections-container" ref={documentBody}>
                <CMDBuildForm groups={fieldsets} attributes={activityAttributes || []} layout={processActivity?.formStructure?.form || {}} widgets={processActivity?.widgets || []} context={processInstance || {}} updateContext={setProcessInstance} activity={processActivity || ({} as ActivityModel)} model={process} />
              </div>
              <InlineWidgets widgets={processActivity?.widgets || []} target={processInstance} />
              <WidgetsButtons widgtes={processActivity?.widgets || []} context={processInstance || {}} />
            </StepperContent>
            {processActivity?.writable ? (
              <StepperNav>
                {/* BUTTON GO BACK*/}
                {/* <Button type="button" className="btn btn-sm steppers-btn-prev p-0" data-focus-mouse="false" data-element="go-back">
                <Icon icon="it-chevron-left" title="Indietro" color="primary" />
                <span className="text-button-sm text-primary flex-fill">{intl.formatMessage(messages.go_back)}</span>
              </Button> */}
                {/* BUTTON SAVE REQUEST*/}
                <Button type="button" className="btn btn-outline-primary bg-white btn-sm steppers-btn-save d-none d-lg-block saveBtn" data-element="save-request" onClick={onSaveClick} disabled={isSaving}>
                  <span className="text-button-sm t-primary flex-fill">{intl.formatMessage(messages.save_request)}</span>
                </Button>
                {/* BUTTON ADVANCE*/}
                <Button type="button" className="btn btn-primary btn-sm stepper-btn-next d-flex justify-content-center align-items-center" style={{ height: '48px' }} data-bs-toggle="modal" data-bs-target="#modal-save-1" data-focus-mouse="false" data-element="advance" onClick={handleNextClick} disabled={isSaving}>
                  {isLoading ? (
                    <Spinner className="" active small />
                  ) : (
                    <>
                      <span className="text-button-sm text-white flex-fill">{intl.formatMessage(messages.advance)}</span>
                      <Icon icon="it-chevron-right" title="Avanti" />
                    </>
                  )}
                </Button>
              </StepperNav>
            ) : null}
          </section>
        </Row>
      </StepperContainer>
    </>
  );
};

export default ProcessInstanceForm;
