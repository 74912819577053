/**
 * Root reducer.
 * @module reducers/root
 */

import defaultReducers from '@plone/volto/reducers';
import { loginInfoReducer } from './logininfo';
import { onlineServiceReducer } from './onlineservice';
import { portalUserReducer } from './portaluser';

const reducers = {
  ...defaultReducers,
  // login info
  loginInfo: loginInfoReducer,
  // portal user
  portalUser: portalUserReducer,

  // online service
  onlineService: onlineServiceReducer,
};

export default reducers;
