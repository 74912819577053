import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  moreInfo: {
    id: 'moreInfo',
    defaultMessage: 'Altre informazioni',
  },
});

const PNAdministratorText = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.text) ? (
    <RichTextSection
      tag_id={'title-administrator-moreInfo'}
      field="text"
      title={intl.formatMessage(messages.moreInfo)}
      show_title={true}
      data={content?.text}
      className={'mb-5'}
    />
  ) : (
    ''
  );
};

PNAdministratorText.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorText;
