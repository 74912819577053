import { Container, Card, CardBody, CardTitle } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import PropTypes from 'prop-types';
import React from 'react';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PNAlertBehavior from '../PNMessages/PNAlertBehavior';

const messages = defineMessages({
  module_one: {
    id: 'module_one',
    defaultMessage: 'Primo modulo',
  },
  module_two: {
    id: 'module_two',
    defaultMessage: 'Secondo modulo',
  },
  modules: {
    id: 'modules',
    defaultMessage: 'Moduli',
  },
});

const PNMultipleModuleView = ({ content }) => {
  const intl = useIntl();

  return content?.file_one || content?.file_two ? (
    <Container className="my-4 modules-view">
      <div className="px-4 px-lg-0">
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <RichTextSection
          tag_id="modules_view"
          title={intl.formatMessage(messages.modules)}
        >
          {content.description && <p>{content.description}</p>}
          <Card
            className="card card-teaser shadow p-4 mt-3 rounded attachment"
            noWrapper={true}
            tag="div"
          >
            <Icon
              icon="it-clip"
              alt={intl.formatMessage(messages.module_one)}
              title={intl.formatMessage(messages.module_one)}
            />
            <CardBody tag="div">
              <CardTitle tag="h5">
                <a
                  href={
                    content?.file_one?.download +
                    '/' +
                    content?.file_one?.filename
                  }
                  download
                  data-element="service-file"
                >
                  {content?.file_one?.filename}
                </a>
              </CardTitle>
            </CardBody>
          </Card>
          <Card
            className="card card-teaser shadow p-4 mt-3 rounded attachment"
            noWrapper={true}
            tag="div"
          >
            <Icon
              icon="it-clip"
              alt={intl.formatMessage(messages.module_two)}
              title={intl.formatMessage(messages.module_two)}
            />
            <CardBody tag="div">
              <CardTitle tag="h5">
                <a
                  href={
                    content?.file_two?.download +
                    '/' +
                    content?.file_two?.filename
                  }
                  download
                  data-element="service-file"
                >
                  {content?.file_two?.filename}
                </a>
              </CardTitle>
            </CardBody>
          </Card>
        </RichTextSection>
      </div>
    </Container>
  ) : (
    ''
  );
};

PNMultipleModuleView.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNMultipleModuleView;
