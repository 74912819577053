/**
 * ServicesPage component.
 * @module components/Features/MyArea/ServicesPage
 *
 * Page which displays personal services page.
 *
 */
const ServicesPage = (props) => {
  return (
    <>
      <div>
        <p>Page which displays personal services page</p>
      </div>
    </>
  );
};

export default ServicesPage;
