/**
 * MessagesPage component.
 * @module components/Features/MyArea/MessagesPage
 *
 * Page which displays personal messages page.
 *
 */
import {
  useSideMenu,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import Messages from './components/Messages';
import { defineMessages, useIntl } from 'react-intl';
import { createRef } from 'react';
import { Col, Row } from 'design-react-kit';

const messages = defineMessages({
  messagesTitle: {
    id: 'messagesTitle',
    defaultMessage: 'Messaggi',
  },
});

const MessagesPage = (props) => {
  const intl = useIntl();
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(props, documentBody);
  return (
    <>
      <div>
        <Row className="border-top-0 row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            <SideMenu data={sideMenuElements} />
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container "
          >
            <RichTextSection
              tag_id={'title-last-messages'}
              field="text"
              title={intl.formatMessage(messages.messagesTitle)}
              show_title={true}
            >
              <Messages limit={10} tab={'MessagesPage'} />
            </RichTextSection>
          </section>
        </Row>
      </div>
    </>
  );
};

export default MessagesPage;
