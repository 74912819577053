/**
 * File attribute.
 * @module fields/file
 *
 */

import { defineMessages, useIntl } from 'react-intl';
import { setAutoValue, updateVisibility, validateValue } from './../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import { Input } from 'design-react-kit';
import { getFieldLabel } from './utils';
import { uploadFileRequest } from '../proxies/utils';

const messages = defineMessages({
  fileLoading: {
    id: 'fileLoading',
    defaultMessage: 'Stai caricando il file: ',
  },
  uploadFile: {
    id: 'uploadFile',
    defaultMessage: 'Carica il file',
  },
});

export const FileField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const fieldId = `field-string-${config.name}`;
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState(context?.[config.name]);
  const [errors, setErrors] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [infoText, setInfoText] = useState('');
  const [fileData, setFileData] = useState({} as { name: string });
  const [isUploading, setIsUploading] = useState(false);

  const updateFileData = ({ _id, name }: { _id: string; name: string }) => {
    setValue(_id);
    setFileData({
      name: name,
    });
    context[config.name] = _id;
    recordUpdater({
      ...context,
    });
  };

  /**
   * On value change
   */
  const onValueChange = (event) => {
    const uploaded_file = event.target.files[0];
    if (uploaded_file) {
      setIsUploading(true);

      uploadFileRequest('TEMP', uploaded_file, {}).then((data: { _id: string; name: string }) => {
        updateFileData(data);
        event.target.value = null;
        setIsUploading(false);
      });
    }
  };

  /**
   * On context change
   */
  useEffect(() => {
    if (context?.[config.name]) {
      updateFileData({ _id: context?.[config.name], name: context?.[`_${config.name}_name`] });
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  useEffect(() => {
    if (fileData?.name) {
      setInfoText(intl.formatMessage(messages.fileLoading) + fileData.name);
    }
  }, [fileData]);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <div className="form-group" hidden={!isVisible}>
      <label htmlFor={fieldId} className="active">
        {getFieldLabel(config)}
      </label>
      <Input
        type="file"
        noWrapper={true}
        id={fieldId}
        validationText={errors}
        disabled={isDisabled || isUploading}
        valid={errors ? false : value ? true : undefined}
        required={required}
        infoText={infoText}
        onChange={onValueChange}
        placeholder={intl.formatMessage(messages.fileLoading)} />
      {infoText && (
        <div>
          <small id={`${fieldId}FileNeme`} className="form-text text-muted">
            <em>{infoText}</em>
          </small>
        </div>
      )}
      <div hidden={!config?.help}>
        <small id={`${fieldId}Description`} className="form-text">
          {config?.help}
        </small>
      </div>
      <div className="invalid-feedback form-feedback just-validate-error-label form-text form-feedback just-validate-error-label" hidden={!errors}>
        {errors}
      </div>
    </div>
  );
};
