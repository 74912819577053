/**
 * NewsItemView view component.
 * @module components/theme/View/PNEventContainerView
 */
import { defineMessages, useIntl } from 'react-intl';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageHeader,
  SkipToMainContent,
  ContentImage,
  WideImage,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { PNAlertBehavior } from 'comune-pordenone-plone-theme/components/View';

import { Container, Row } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import { viewDate } from 'design-comuni-plone-theme/helpers';

const messages = defineMessages({
  readMore: {
    id: 'readMore',
    defaultMessage: 'Leggi di più',
  },
});
/**
 * NewsItemView view component class.
 * @function PNEventContainerView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNEventContainerView = ({ content, location }) => {
  const intl = useIntl();
  const [readingtime, setReadingtime] = useState(0);

  return content?.items ? (
    <Container className="my-4 events-view">
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          readingtime={readingtime}
          showreadingtime={true}
          showtassonomiaargomenti={true}
          showdates={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        <WideImage
          title={content?.title}
          image={content}
          caption={content?.image_caption}
          fullWidth={true}
        />
        <Row className="border-top row-column-border row-column-menu-left cardDiv-eventContainer">
          <div>
            <div className="container p-3 p-md-5">
              <h2 className="mb-4 ">Eventi in evidenza</h2>
            </div>
            <div className="row">
              {content?.items.map((item, index) => (
                <div className="col-lg-6 col-xl-4 my-2">
                  <div className="card-wrapper border border-light rounded shadow-sm">
                    <div className="card no-after rounded">
                      <div className="img-responsive-wrapper">
                        <div className="img-responsive img-responsive-panoramic {{imgClass}}">
                          <ContentImage
                            content={content}
                            position="afterHeader"
                          />
                          <figure className="img-wrapper">
                            {item.image_scales?.banner ? (
                              <img
                                src={
                                  item['@id'] +
                                  '/' +
                                  item.image_scales.banner[0].download
                                }
                                alt={item.description}
                              />
                            ) : (
                              <img
                                src={
                                  'https://www.comune.pordenone.it/it/banners/banner1.jpg/@@images/f925e445-944b-47e9-af76-afe61cd91834.jpeg'
                                }
                                alt={'event Image'}
                              />
                            )}
                          </figure>
                          <div className="card-calendar d-flex flex-column justify-content-center">
                            <span className="card-date">
                              {viewDate(intl.locale, item?.start).format('DD')}
                            </span>
                            <span className="card-day">
                              {viewDate(intl.locale, item?.start).format('MMMM')}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="card-body">
                        <div className="category-top">
                          <a className="category text-decoration-none" href="#">
                            {item.category}
                          </a>
                          {/* <span className="data">card.category-date</span> */}
                        </div>
                        <h3 className="card-title">
                          <a
                            href={flattenToAppURL(item['@id'])}
                            className="text-decoration-none"
                          >
                            {item.title}
                          </a>
                        </h3>
                        <p className="card-text text-tertiary pb-3">
                          {item.description}
                        </p>
                        <a
                          className="read-more t-primary text-uppercase"
                          href={flattenToAppURL(item['@id'])}
                          aria-label="Leggi di più sulla pagina di {{card.title}}"
                        >
                          <span className="text">
                            {intl.formatMessage(messages.readMore)}
                          </span>
                          <span className="visually-hidden">
                            su Lorem ipsum dolor sit amet, consectetur
                            adipiscing elit…
                          </span>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            className="bi bi-arrow-right-short"
                            viewBox="0 0 16 16"
                          >
                            <path
                              fillRule="evenodd"
                              d="M4 8a.5.5 0 0 1 .5-.5h5.793L8.146 5.354a.5.5 0 1 1 .708-.708l3 3a.5.5 0 0 1 0 .708l-3 3a.5.5 0 0 1-.708-.708L10.293 8.5H4.5A.5.5 0 0 1 4 8"
                            />
                          </svg>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Row>
      </div>
    </Container>
  ) : (
    ''
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNEventContainerView.propTypes = {
  content: PropTypes.shape({
    title: PropTypes.string.isRequired,
    description: PropTypes.string,
    effective: PropTypes.string,
    start: PropTypes.string,
    end: PropTypes.string,
    orari: PropTypes.shape({
      data: PropTypes.string,
    }),
    fee: PropTypes.shape({
      data: PropTypes.string,
    }),
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
    modified: PropTypes.string,
  }).isRequired,
};

export default PNEventContainerView;
