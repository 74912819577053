/**
 * PNPersonLink view component.
 * @module components/theme/View/PNPersonLink
 */

import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardBody, CardTitle, CardText } from 'design-react-kit';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  link: {
    id: 'link',
    defaultMessage: 'Link',
  },
});

// ... (other imports)

const PNPersonLink = ({ content }) => {
  const intl = useIntl();
  const link = content?.items;
  const renderItems = link?.filter((link) => link['@type'] === 'Link');

  return renderItems?.length > 0 ? (
    <RichTextSection
      tag_id="person_file"
      title={intl.formatMessage(messages.link)}
    >
      <Card
        className="card card-teaser shadow p-4 my-3 rounded personFile"
        noWrapper={true}
        tag="div"
      >
        <CardBody tag="div">
          {renderItems.map((item, index) => {
            return (
              <CardText tag="p" className="mb-4">
                <Icon
                  icon="it-clip"
                  alt={intl.formatMessage(messages.link)}
                  title={intl.formatMessage(messages.link)}
                />
                <CardTitle tag="h5" className="d-inline">
                  <a href={flattenToAppURL(item.url)}>{item.title}</a>
                </CardTitle>
                {item?.description && (
                  <p className="ml-4">{item.description}</p>
                )}
              </CardText>
            );
          })}
        </CardBody>
      </Card>
    </RichTextSection>
  ) : (
    ''
  );
};

PNPersonLink.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNPersonLink;
