import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichText,
  RichTextSection,
  UniversalLink,
  OfficeCard,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  accessService: {
    id: 'accessService',
    defaultMessage: 'Accedi al servizio',
  },
  accesso_generico: {
    id: 'accesso_generico',
    defaultMessage: 'Accesso generico',
  },
  accesso_online: {
    id: 'accesso_online',
    defaultMessage: 'Accesso online',
  },
  prenotaAppuntamento: {
    id: 'prenotaAppuntamento',
    defaultMessage: 'Prenota appuntamento',
  },
});

const PNProcedureAccessService = ({ content }) => {
  const intl = useIntl();

  return content?.cmdbuild_processname ||
    content?.accesso_online ||
    content?.prenotaAppuntamento ? (
    <RichTextSection
      tag_id="submit-request"
      title={intl.formatMessage(messages.accessService)}
      hasBg
      p="4"
      lighthouseId="service-generic-access"
      className="it-page-section mb-30 has-bg-grey p-3"
    >
      {/* ACCESSO ONLINE CMDBUILD PROCESS NAME */}
      {content?.cmdbuild_processname && (
        <div className="mb-4">
          <p className="draftjs-buttons" data-element="online-service">
            <a
              href={flattenToAppURL(content['@id'] + '/online-service')}
              className="btn btn-primary mobile-full"
            >
              {intl.formatMessage(messages.accesso_online)}
            </a>
          </p>
        </div>
      )}
      {/* ACCESSO ONLINE */}
      {content?.accesso_online && (
        <div className="mb-4">
          <p className="draftjs-buttons" data-element="service-online-access">
            <a
              href={flattenToAppURL(content.accesso_online)}
              className="btn btn-primary mobile-full"
            >
              {intl.formatMessage(messages.accesso_online)}
            </a>
          </p>
        </div>
      )}
      {/* PRENOTA APPUNTAMENTO */}
      {content?.prenotaAppuntamento && (
        <div className="mt-3">
          <p className="draftjs-buttons" data-element="service-booking-access">
            <a
              href={flattenToAppURL(content.prenotaAppuntamento)}
              className="btn btn-outline-primary t-primary bg-white mobile-full text-primary"
            >
              {intl.formatMessage(messages.prenotaAppuntamento)}
            </a>
          </p>
        </div>
      )}
    </RichTextSection>
  ) : (
    // )}
    ''
  );
};

PNProcedureAccessService.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureAccessService;
