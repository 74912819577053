/**
 * Online service reducer
 * @module src/reducers/onlineservice
 */

import { GET_ONLINE_SERVICE_INFO } from 'comune-pordenone-plone-theme/actions';

const initialState = {
  error: null,
  hasError: false,
  result: {},
  loading: false,
  authorized: null,
};

export const onlineServiceReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case `${GET_ONLINE_SERVICE_INFO}_PENDING`:
      return {
        ...state,
        loading: true,
      };

    case `${GET_ONLINE_SERVICE_INFO}_SUCCESS`:
      return {
        ...state,
        result: action.result,
        loading: false,
        authorized: !action.result.unauthorized,
      };

    case `${GET_ONLINE_SERVICE_INFO}_FAIL`:
      return {
        ...state,
        error: action.error,
        hasError: true,
        loading: false,
      };

    default:
      return state;
  }
};
