/**
 * Messages component.
 * @module components/Features/MyArea/Components/Messages
 *
 * Page which displays personal activities page.
 *
 */
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';
import { useClassModel } from '../../../../addons/volto-cmdbuild/src/modelsuses';
import { Pagination } from 'design-comuni-plone-theme/components/ItaliaTheme/';
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'design-react-kit';
import { formatDate } from '@plone/volto/helpers/Utils/Date';

const Messages = (config) => {
  const intl = useIntl();
  const { getCards } = useClassModel();
  const className = 'Message';
  const [currentPage, setCurrentPage] = useState(1);
  const [cards, setCards] = useState();
  const [totalCards, setTotalCards] = useState(cards?.length);
  const [openModalIndex, setOpenModalIndex] = useState(null);

  const messages = defineMessages({
    practice: {
      id: 'practice',
      defaultMessage: 'Pratica: ',
    },
    noMessages: {
      id: 'noMessages',

      defaultMessage: 'Al momento non ci sono messaggi',
    },
  });
  config = {
    ...config,
    sort: [
      {
        property: 'Date',
        direction: 'DESC',
      },
    ],
    limit: config?.limit || 10,
    page: currentPage,
    start: (currentPage - 1) * config?.limit,
  };

  useEffect(() => {
    getCards(className, config).then(({ data, meta }) => {
      setCards(
        data?.map((i) => {
          return i;
        }),
      );
      setTotalCards(meta?.total);
    });
    config = {
      ...config,
      start: currentPage,
    };
  }, [currentPage]);

  const handlePageChange = (e, { activePage }) => {
    setCurrentPage(activePage?.children);
  };
  const totalPages = Math.ceil(totalCards / config?.limit);

  return (
    <>
      {cards?.length > 0 ? (
        cards.map((card, index) => (
          <>
            <Card
              className="card-bg rounded mb-3"
              onClick={() => {
                setOpenModalIndex(index);
              }}
            >
              <CardBody className="pb-0">
                <CardText>
                  {formatDate({
                    date: card?.Date,
                    format: 'd-m-yyyy',
                    locale: 'it-IT',
                  })}
                </CardText>
                <CardTitle tag={'h5'} className="text-primary">
                  <a href="#" onClick={'return false;'}>
                    {card?.Title}
                  </a>
                </CardTitle>
                <CardText
                  className={'messages-card-text'}
                  dangerouslySetInnerHTML={{ __html: card?.Content }}
                ></CardText>
              </CardBody>
            </Card>
            <Modal
              isOpen={openModalIndex === index}
              toggle={() => setOpenModalIndex(null)}
              labelledBy={`widget-modal-${card['_id']}`}
              size="md"
              className={`modal-messages bg-opacity-25 `}
              centered={true}
              unmountOnClose={true}
              scrollable={false}
              id={index}
              modalClassName={'overflow-hidden'}
            >
              <ModalHeader
                toggle={() => setOpenModalIndex(null)}
                id={`widget-modal-${card['_id']}`}
              >
                <h4 className="text-primary">{card?.Title}</h4>
              </ModalHeader>
              <p className="px-4 mb-0 font-weight-light">
                {formatDate({
                  date: card?.Date,
                  format: 'dd-mm-yyyy',
                  locale: 'it-IT',
                })}
              </p>
              <ModalBody>
                <p>
                  <b>{intl.formatMessage(messages.practice)}</b>
                  {card?.CityProcessID}
                </p>
                <div
                  className={'mt-2'}
                  dangerouslySetInnerHTML={{ __html: card?.Content }}
                ></div>
              </ModalBody>
              <ModalFooter></ModalFooter>
            </Modal>
          </>
        ))
      ) : (
        <p>{intl.formatMessage(messages.noMessages)}</p>
      )}
      {config?.tab === 'MessagesPage' && totalPages > 1 ? (
        <Pagination
          defaultActivePage={1}
          activePage={1}
          boundaryRange={0}
          siblingRange={3}
          onPageChange={handlePageChange}
          totalPages={totalPages}
        />
      ) : (
        ''
      )}
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
Messages.propTypes = {};

export default Messages;
