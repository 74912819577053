import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  association: {
    id: 'Association',
    defaultMessage: `Questo evento è stato segnalato dall'associazione`,
  },
  category: {
    id: 'category',
    defaultMessage: `Categoria`,
  },
});

const PNAssociationEventInfo = ({ content }) => {
  const intl = useIntl();

  return (
    <>
      {/* Parent association */}
      {content?.parent?.title && (
        <RichTextSection
          tag_id="association"
          title={intl.formatMessage(messages.association)}
          className="mt-2"
        >
          <a
            data-pat-plone-modal="image: true"
            className="pat-plone-modal"
            href={content?.parent['@id']}
            title="Vedi immagine"
          >
            {content?.parent?.title}
          </a>
        </RichTextSection>
      )}
      {/* Category */}
      {content?.category && (
        <RichTextSection
          tag_id={'association-category'}
          field="text"
          title={intl.formatMessage(messages.category)}
          show_title={true}
        >
          <a href={flattenToAppURL('resolveuid/' + content?.category?.token)}>
            {content.category?.title}
          </a>
        </RichTextSection>
      )}
    </>
  );
};

PNAssociationEventInfo.propTypes = {
  content: PropTypes.shape({
    prezzo: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PNAssociationEventInfo;
