/**
 * Fields helper utility.
 * @module utilities/fieldshelper
 *
 */
import { ActivityModel, AttributeModel, CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { BooleanField, DateField, FileField, IntegerField, LookupArrayField, LookupField, ReadOnlyField, ReferenceField, StringField, TextField, TimeField } from '../fields';
import { IntlShape, defineMessages } from 'react-intl';

import { FieldInlineWidget } from './widgetshelper';
import { ProcessInstance } from '../models/processinstance';
import { getFunctionOutputsProxy } from '../proxies';
import { getLookupValuesProxy } from '../proxies/lookup';

const BINDS_REGEX = /^api\.bind(\s?)=(\s?)\[.*\](\s?);/;

const messages = defineMessages({
  requiredFiled: {
    id: 'requiredFiled',
    defaultMessage: 'Questo campo è obbligatorio',
  },
  enterValidValue: {
    id: 'enterValidValue',
    defaultMessage: 'Inserire un valore valido',
  },
});
export const updateVisibility = (showIfFn: Function, showIfApis: object, setIsVisible: Function, setIsDisabled: Function) => {
  if (!showIfFn) {
    setIsVisible(true);
  } else {
    const visibility = showIfFn(showIfApis);
    if (visibility === true || visibility === 'true' || visibility === 'enabled') {
      setIsVisible(true);
      setIsDisabled(false);
    } else if (visibility === 'disabled') {
      setIsVisible(true);
      setIsDisabled(true);
    } else {
      setIsVisible(false);
      setIsDisabled(true);
    }
  }
};

export const validateValue = (value: any, mandatory: boolean, setError: Function, vrFn: Function, vrApis: object, intl: IntlShape) => {
  const validStr = typeof value === 'string' ? !!value : true;
  const validBool = typeof value === 'boolean' ? value : true;
  const validArray = Array.isArray(value) ? !!value.length : true;

  if (mandatory && (value === null || value === undefined || !validStr || !validArray || !validBool)) {
    return setError(intl.formatMessage(messages.requiredFiled));
  }

  if (vrFn) {
    const validationResult = vrFn(value, vrApis);
    let validationErrors = '';
    if (validationResult === false) {
      validationErrors = intl.formatMessage(messages.enterValidValue);
    } else if (typeof validationResult === 'string') {
      validationErrors = validationResult;
    } else if (Array.isArray(validationResult)) {
      validationErrors = validationResult.join('<br />');
    }
    return setError(validationErrors);
  }
  return setError(null);
};

export const setAutoValue = (autoValueFn: Function, autoValueApis: object) => {
  if (autoValueFn) {
    autoValueFn(autoValueApis);
  }
};

export const CMDBuildAttribute = ({ attribute, context, setContext, activity, inlineWidgets }: { attribute: AttributeModel; context: CardModel | ProcessInstance | object | null | undefined; setContext: Function; activity: ActivityModel | null | undefined; inlineWidgets: WidgetModel[] }) => {
  // get show if bindings

  const showIf = attribute.showIf || '';
  const validationRule = attribute?.validationRules;
  const autoValue = attribute?.autoValue;
  let showIfBindings;
  let showIfApis;
  let showIfFn;
  let validationRuleBindings;
  let validationRuleApis;
  let validationRuleFn;
  let autoValueBindings;
  let autoValueApis;
  let autoValueFn;
  let isFormWritable = true;

  if (activity) {
    isFormWritable = activity.writable;
  }

  if (showIf) {
    showIfBindings = extractFieldFeatureBindings(showIf, context);
    showIfApis = getFieldFeatureApis(context, activity);
    showIfFn = getShowIfFn(showIf);
  }

  if (validationRule) {
    validationRuleBindings = extractFieldFeatureBindings(validationRule, context);
    validationRuleApis = getFieldFeatureApis(context, activity);
    validationRuleFn = getValidationRuleFn(validationRule);
  }

  if (autoValue) {
    autoValueBindings = extractFieldFeatureBindings(autoValue, context);
    autoValueApis = getFieldFeatureApis(context, activity, attribute.name, setContext);
    autoValueFn = getAutoValueFn(autoValue);
  }

  const beforeWidgets = inlineWidgets.filter((w) => w.RelatedTo === attribute.name || w.BeforeAttribute === attribute.name);
  const afterWidgets = inlineWidgets.filter((w) => w.AfterAttribute === attribute.name);

  let Field;
  if (attribute.writable !== true || !isFormWritable) {
    Field = ReadOnlyField;
  } else {
    switch (attribute.type) {
      case 'string':
        Field = StringField;
        break;
      case 'reference':
        Field = ReferenceField;
        break;
      case 'text':
        Field = TextField;
        break;
      case 'date':
        Field = DateField;
        break;
      case 'time':
        Field = TimeField;
        break;
      case 'integer':
        Field = IntegerField;
        break;
      case 'boolean':
        Field = BooleanField;
        break;
      case 'lookup':
        Field = LookupField;
        break;
      case 'lookupArray':
        Field = LookupArrayField;
        break;
      case 'file':
        Field = FileField;
        break;
      default:
        return <p>{attribute._description_translation}</p>;
    }
  }
  return (
    <>
      {beforeWidgets.map((w) => (
        <FieldInlineWidget widget={w} context={context} />
      ))}
      <Field config={attribute} context={context} recordUpdater={setContext} showIfFn={showIfFn} showIfBindings={showIfBindings} showIfApis={showIfApis} filterBindings={extractFilterBindings(attribute.ecqlFilter || {}, context || {})} validationRuleFn={validationRuleFn} validationRuleBindings={validationRuleBindings} validationRuleApis={validationRuleApis} autoValueFn={autoValueFn} autoValueBindings={autoValueBindings} autoValueApis={autoValueApis} />
      {afterWidgets.map((w) => (
        <FieldInlineWidget widget={w} context={context} />
      ))}
    </>
  );
};

export const extractFieldFeatureBindings = (rule: string, context: CardModel | ProcessInstanceModel | object | null | undefined): any[] => {
  let bindings: any[] = [];
  const binds = BINDS_REGEX.exec(rule);

  if (binds?.length) {
    let api = {
      bind: [],
    };
    try {
      // eslint-disable-next-line no-eval
      eval(binds[0]);
    } catch (e) {
      console.error('Error on api bindings');
    }
    bindings = api.bind.map((b) => {
      let key: string = b;
      if (Array.isArray(context?.[b])) {
        key = `_${b}_str`;
      }
      return JSON.stringify(context?.[key as keyof object]);
    });
  } else if (rule) {
    return [context];
  }
  return bindings;
};

export const getFieldFeatureApis = (context: CardModel | ProcessInstance | object | null | undefined, activity: ActivityModel | null | undefined, attributeName: string = '', setContext: Function = function () {}): object => {
  return {
    activity: {
      get: (propName: string) => {
        return activity?.[propName as keyof ActivityModel];
      },
    },
    record: {
      get: (propName: string) => {
        return context?.[propName as keyof CardModel];
      },
      set: (propName: string, value: any) => {
        setContext({
          ...context,
          [propName]: value,
        });
      },
    },
    getValue: (attrName: string): any => {
      return context?.[attrName as keyof object];
    },
    getLookupCode: (attrName: string): string | undefined => {
      return context?.[`_${attrName}_code` as keyof object];
    },
    getLookupDescription: (attrName: string): string | undefined => {
      return context?.[`_${attrName}_description_translation` as keyof object];
    },
    getReferenceDescription: (attrName: string): string | undefined => {
      return context?.[`_${attrName}_description` as keyof object];
    },
    testRegExp: function (regexp: RegExp, value: string) {
      value = value || '';
      return regexp.test(value);
    },
    setValue: function (value: any) {
      setContext({
        ...context,
        [attributeName]: value,
      });
    },
    getFunctionOutputs: (fnName: string, params: object, model: object) => {
      return new Promise((resolve, reject) => {
        getFunctionOutputsProxy(fnName, params).then((response) => {
          resolve(response?.data);
        });
      });
    },
    getRemoteLookupFromCode: (lookupType: string, lookupCode: string) => {
      return new Promise((resolve, reject) => {
        getLookupValuesProxy(lookupType, {
          filter: {
            attribute: { simple: { attribute: 'Code', operator: 'equal', value: [lookupCode] } },
          },
        }).then(({ data, meta }) => {
          if (data && data.length) {
            resolve({
              get: (prop) => {
                return data[0][prop];
              },
            });
          }
        });
      });
    },
  };
};

export const getShowIfFn = (rule: string): Function | undefined => {
  const script = rule.replace(BINDS_REGEX, '');
  let executeShowIf;
  try {
    const jsfn = `executeShowIf = (api) => {\n${script}\n}`;
    // eslint-disable-next-line no-eval
    eval(jsfn);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    executeShowIf = (api: object) => {
      return true;
    };
  }
  return executeShowIf;
};

export const getValidationRuleFn = (rule: string): Function | undefined => {
  const script = rule.replace(BINDS_REGEX, '');
  let executeValidationRule;
  try {
    const jsfn = `executeValidationRule = (value, api) => {\n${script}\n}`;
    // eslint-disable-next-line no-eval
    eval(jsfn);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    executeValidationRule = (value: any, api: object) => {
      return true;
    };
  }
  return executeValidationRule;
};

export const getAutoValueFn = (rule: string): Function | undefined => {
  let script = rule.replace(BINDS_REGEX, '');
  let executeAutoValue;
  try {
    const jsfn = `executeAutoValue = (api) => {\n${script}\n}`;
    // eslint-disable-next-line no-eval
    eval(jsfn);
  } catch (err) {
    // eslint-disable-next-line no-console
    console.error(err);
    executeAutoValue = (api: object) => {
      return true;
    };
  }
  return executeAutoValue;
};

const extractFilterBindings = (filter: { bindings?: { client?: string[] } }, context: object): string[] => {
  let filter_bindings: string[] = [];
  if (filter?.bindings?.client?.length) {
    filter_bindings = filter.bindings.client.map((b: string) => context?.[getBindingCode(b) as keyof object]);
  }
  return filter_bindings;
};

export const evaluateEcqlFilter = (filter_def: { bindings?: { client?: string[] }; id: string }, context: object): object | undefined => {
  let filter: { context?: object; id?: any } | undefined = undefined;
  if (filter_def) {
    const client_vars = {};
    filter_def.bindings?.client?.forEach((b: string) => {
      client_vars[b as keyof object] = context?.[getBindingCode(b) as keyof object];
    });
    filter = {
      id: filter_def.id,
      context: {
        client: client_vars,
      },
    };
  }
  return filter;
};

const getBindingCode = (binding: string): string => {
  if (binding.indexOf('.') !== -1) {
    const bindingSplit = binding.split('.');
    if (bindingSplit[1] == 'Code') {
      return '_' + bindingSplit[0] + '_' + bindingSplit[1].toLowerCase();
    }
  }
  return binding;
};
