/**
 * DeskPage component.
 * @module components/Features/MyArea/DeskPage
 *
 * Page which displays personal desk page.
 *
 */
import { useSideMenu } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';
import { createRef } from 'react';
import { Col, Row } from 'design-react-kit';

import Activities from './components/Activities';
import Messages from './components/Messages';

const messages = defineMessages({
  lastMessages: {
    id: 'lastMessages',
    defaultMessage: 'Ultimi messaggi',
  },
  lastActivities: {
    id: 'lastActivities',
    defaultMessage: 'Ultime attività',
  },
});
const DeskPage = (props) => {
  let documentBody = createRef();
  const intl = useIntl();
  const { sideMenuElements, SideMenu } = useSideMenu(props, documentBody);
  return (
    <>
      <div>
        <Row className="border-top-0 row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            <SideMenu data={sideMenuElements} />
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container "
          >
            <RichTextSection
              tag_id={'title-last-messages'}
              field="text"
              title={intl.formatMessage(messages.lastMessages)}
              show_title={true}
            >
              <div>
                <Messages limit={3} tab={'DeskPage'} />
              </div>
            </RichTextSection>
            <RichTextSection
              tag_id={'title-last-activities'}
              field="text"
              title={intl.formatMessage(messages.lastActivities)}
              show_title={true}
            >
              <div>
                <Activities limit={3} tab={'DeskPage'} />
              </div>
            </RichTextSection>
          </section>
        </Row>
      </div>
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
DeskPage.propTypes = {};

export default DeskPage;
