/**
 * View Accordion block.
 * @module components/ItaliaTheme/Blocks/Accordion/View
 */

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Card,
  CardBody,
} from 'design-react-kit';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { defineMessages, useIntl } from 'react-intl';

const messages = defineMessages({
  time: {
    id: 'time: ',
    defaultMessage: 'Orari',
  },
});

/**
 * View Accordion block class.
 * @class View
 * @extends Component
 */
const PNOfficeDates = ({ content, block }) => {
  const intl = useIntl();
  const [itemOpen, setItemOpen] = useState(-1);
  const toggle = (index) => {
    setItemOpen(index === itemOpen ? -1 : index);
  };
  const id = new Date().getTime();
  return richTextHasContent(content?.openTimeDetails) ? (
    <RichTextSection
      tag_id={'title-office-time'}
      title={intl.formatMessage(messages.time)}
    >
      <Container className="px-md-4">
        <Card
          className="card-bg rounded my-3"
          noWrapper={false}
          space
          tag="div"
        >
          <CardBody tag="div">
            {/* NOTE OPEN TIME */}
            <RichTextSection
              data={content.openTimeDetails}
              tag_id={'title-openTime'}
              field="text"
            />
          </CardBody>
        </Card>
      </Container>
    </RichTextSection>
  ) : (
    ''
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNOfficeDates.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
};

export default PNOfficeDates;
