/**
 * Form helper utility.
 * @module utilities/processstatusstepper
 *
 */

import { AttributeModel, CardModel, ProcessInstanceModel } from '../models';
import { StepperHeader, StepperHeaderElement } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useEffect, useState } from 'react';

import { evaluateEcqlFilter } from '../utilities/fieldshelper';
import { getLookupValuesProxy } from '../proxies/lookup';

const messages = defineMessages({
  infoPrivacy: {
    id: 'infoPrivacy',
    defaultMessage: 'Informativa privacy',
  },
});

const STATE_ATTR = 'CityContactState';

export const ProcessStateStepper = ({ attributes, context }: { attributes: AttributeModel[]; context: CardModel | ProcessInstanceModel | object }) => {
  const intl = useIntl();
  const [processStatesList, setProcessStatesList] = useState([]);

  useEffect(() => {
    if (attributes) {
      let attr = attributes.find((AttrName) => AttrName.name === STATE_ATTR);

      let attrLookupType = attr?.lookupType;

      if (attrLookupType) {
        getLookupValuesProxy(attrLookupType, {
          filter: {
            ecql: evaluateEcqlFilter(attr.ecqlFilter, context),
          },
        }).then(({ data, meta }) => {
          setProcessStatesList(data);
        });
      }
    }
  }, [attributes]);

  const currentStateCode: string | null = context ? context[`_${STATE_ATTR}_code` as keyof object] : null;

  return (
    <StepperHeader>
      <StepperHeaderElement prependIcon={currentStateCode ? 'it-check' : ''} variant={currentStateCode ? 'confirmed' : 'active'}>
        {intl.formatMessage(messages.infoPrivacy)}
      </StepperHeaderElement>
      {processStatesList &&
        processStatesList.map((state: { code: string; _description_translation: string }) => (
          <StepperHeaderElement key={state.code} prependIcon={currentStateCode && currentStateCode > state.code ? 'it-check' : ''} variant={currentStateCode ? (currentStateCode === state.code ? 'active' : currentStateCode > state.code ? 'confirmed' : '') : ''}>
            {state._description_translation}
          </StepperHeaderElement>
        ))}
    </StepperHeader>
  );
};
