const getPordenoneSettings = (config) => {
  return {
    // set Slate as default block editor
    defaultBlockType: 'slate',

    // multilanguage support
    isMultilingual: true,
    supportedLanguages: ['it'],
    defaultLanguage: 'it',

    // site properties
    siteProperties: {
      enableFeedbackForm: true,
      siteTitle: 'Comune di Pordenone',
      parentSiteTitle: 'Regione Autonoma Friuli Venezia Giulia',
      parentSiteURL: 'https://www.regione.fvg.it/',
      arLoginUrl: '/login',
      arLogoutUrl: '/logout',

      headerslimTertiaryMenu: {
        it: [
          //{ title: 'Contatti', url: '/it/contatti' },
          //{ title: 'Novità', url: '/it/novita' },
        ],
        en: [
          //{ title: 'Contacts', url: '/en/contacts' },
          //{ title: 'News', url: '/en/news' },
        ],
      },
    },
    'volto-editablefooter': {
      options: {
        socials: true,
      },
    },
  };
};

export default getPordenoneSettings;
