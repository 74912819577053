import React from 'react';
import { useEffect, useState } from 'react';
import cx from 'classnames';
import { defineMessages, useIntl } from 'react-intl';
import { evaluateEcqlFilter, validateValue, updateVisibility, setAutoValue } from '../utilities/fieldshelper';
import { objecttypes } from '../constants';
import { useClassModel } from '../modelsuses';
import { useProcessModel } from '../modelsuses';
import { getFieldLabel } from './utils';
import { Input } from 'design-react-kit';

const messages = defineMessages({
  choose_option: {
    id: 'choose_option',
    defaultMessage: 'Choose an option',
  },
});

export const ReferenceField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, filterBindings, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const { getCards } = useClassModel();
  const { getProcessInstances } = useProcessModel();

  const fieldId = `field-reference-${config.name}`;
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState(context?.[config.name]);
  const [errors, setErrors] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    if (config.targetType === objecttypes.klass) {
      getCards(config.targetClass, {
        attrs: ['Id', 'Description', 'Code'],
        filter: { ecql: evaluateEcqlFilter(config.ecqlFilter, context) },
      }).then(({ data, meta }) => {
        setOptions(data);
      });
    } else if (config.targetType === objecttypes.process && context) {
      getProcessInstances(context?._type, {
        attrs: ['Id', 'Description', 'Code'],
        filter: { ecql: evaluateEcqlFilter(config.ecqlFilter, context) },
      }).then(({ data }) => {
        setOptions(data);
      });
    }
  }, filterBindings);

  /**
   * On value change
   */
  useEffect(() => {
    const selectedRecord = options.find((r: { _id: number }) => r._id == value);
    // update value
    context = {
      ...context,
      [config.name]: value,
      [`_${config.name}_code`]: selectedRecord?.Code,
      [`_${config.name}_description`]: selectedRecord?.Description,
    };
    context[config.name] = value;
    recordUpdater(context);

    // validate value
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, [value]);

  /**
   * On context change
   */
  useEffect(() => {
    if (value != context?.[config.name]) {
      setValue(context?.[config.name]);
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  const handleChange = (event: string) => {
    const selectedOption = event.target.value;
    if (selectedOption) {
      setValue(parseInt(selectedOption));
    } else {
      setValue(null);
    }
  };

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <div hidden={!isVisible} className={'form-group select-wrapper'}>
      <label htmlFor={fieldId}>{getFieldLabel(config)}</label>
      <select
        id={fieldId}
        label={getFieldLabel(config)}
        className={cx('select', {
          'is-invalid': errors,
          'just-validate-success-field': !errors,
        })}
        disabled={isDisabled}
        required={required}
        value={value?.toString() || ''}
        onChange={handleChange}
        infoText={config?.help}
      >
        <option value="" key="reference-option-empty">
          {intl.formatMessage(messages.choose_option)}
        </option>
        {options?.map((el) => (
          <option value={el._id} key={`reference-option-${el._id}`}>
            {el.Description}
          </option>
        ))}
      </select>
      <Input id={fieldId} className={cx('p-0', {})} validationText={errors} invalid={!!errors} disabled={isDisabled} valid={errors ? false : value ? true : undefined} required={required} value={value?.toString() || ''} infoText={config?.help} tabIndex={-1} autoComplete="off" style={{ opacity: 0, height: 0 }} onchange={(selectedValue: string) => handleChange} />
    </div>
  );
};
