/**
 * ReadOnly attribute.
 * @module fields/readOnly
 *
 */
import { setAutoValue, updateVisibility } from '../utilities/fieldshelper';

import { Input } from 'design-react-kit';
import React from 'react';
import cx from 'classnames';
import { formatDate } from '@plone/volto/helpers/Utils/Date';
import { getFieldLabel } from './utils';
import { useEffect } from 'react';
import { useState } from 'react';

export const ReadOnlyField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const fieldId = `field-string-${config.name}`;

  const [value, setValue] = useState();
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    if (value != context?.[config.name]) {
      if (config.type === 'boolean') {
        if (context?.[config.name] === false) {
          setValue('No');
        } else if (context?.[config.name] === true) {
          setValue('Sì');
        }
      } else if (config.type === 'date') {
        const formattedDate = formatDate({
          date: context?.[config.name],
          locale: 'it-IT',
          long: false,
          includeTime: false,
        });
        setValue(formattedDate);
      } else if (config.type === 'time') {
        if (config?.showSeconds) {
          setValue(context?.[config.name]);
        } else {
          setValue(context?.[config.name].split(':')[0] + ':' + context?.[config.name].split(':')[1]);
        }
      } else if (config.type === 'reference') {
        setValue(context?.[`_${config.name}_description`]);
      } else if (config.type === 'lookup') {
        setValue(context?.[`_${config.name}_description_translation`]);
      } else if (config.type === 'lookupArray') {
        let attrValue = '';
        context?.[`_${config.name}_details`]?.forEach(function(el) {
          attrValue += (el._description_translation + ', ');
        });
        attrValue = attrValue.substring(0, attrValue.length - 2);
        setValue(attrValue);
      } else if (config.type === 'decimal' || config.type === 'double') {
        let attrValue = context?.[`${config.name}`];
        if (attrValue) {
          if (config.scale) {
            attrValue = attrValue.toFixed(config.scale);
          }
          if (config?.unitOfMeasure) {
            const umLocation = config?.unitOfMeasureLocation || 'AFTER';
            if (umLocation === 'AFTER') {
              attrValue = `${attrValue} ${config?.unitOfMeasure}`;
            } else if (config?.unitOfMeasureLocation === 'BEFORE') {
              attrValue = `${config?.unitOfMeasure} ${umLocation}`;
            }
          }
        }
        setValue(attrValue);
      } else {
        setValue(context?.[`${config.name}`]);
      }
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <>
      <Input
        label={getFieldLabel(config)}
        className={cx('', {
          'd-none': !isVisible,
        })}
        id={fieldId}
        value={value}
        readOnly
        infoText={config?.help}
      />
    </>
  );
};
