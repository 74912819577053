import {
  MyArea,
  OnlineService,
  PortalLogin,
} from 'comune-pordenone-plone-theme/components/Features';

const getPordenoneAddonRoutes = (config) => {
  const routes = [
    {
      path: ['/portal-login', '/**/portal-login'],
      component: PortalLogin,
    },
    {
      path: ['/online-service', '/**/online-service'],
      component: OnlineService,
    },
    {
      path: ['/my-area', '/**/my-area'],
      component: MyArea,
    },
  ];
  return routes;
};

export default getPordenoneAddonRoutes;
