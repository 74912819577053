import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  office_competences: {
    id: 'office_competences',
    defaultMessage: 'Competenze',
  },
});

const PNOfficeCompetencesView = ({ content }) => {
  const intl = useIntl();

  return content?.competences || content?.officeResponsibleText ? (
    <>
      <RichTextSection
        data={content.competences}
        tag_id={'title-office-competences'}
        field="text"
        title={intl.formatMessage(messages.office_competences)}
        show_title={true}
      />
      <div> {content.officeResponsibleText} </div>
    </>
  ) : (
    ''
  );
};

PNOfficeCompetencesView.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNOfficeCompetencesView;
