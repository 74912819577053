import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichTextSection,
  RichText,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  otherInfo: {
    id: 'otherInfo',
    defaultMessage: 'Ulteriori informazioni',
  },
  procedureCollegate: {
    id: 'procedureCollegate',
    defaultMessage: 'Procedure collegate',
  },
  copertura_geografica: {
    id: 'copertura_geografica',
    defaultMessage: 'Copertura geografica',
  },
  Settore_merceologico: {
    id: 'Settore_merceologico',
    defaultMessage: 'Settore merceologico',
  },
  ipa: {
    id: 'ipa',
    defaultMessage: "Codice dell'Ente erogatore (ipa)",
  },
  CasiParticolari: {
    id: 'CasiParticolari',
    defaultMessage: 'Casi particolari',
  },
  Vincoli: {
    id: 'Vincoli',
    defaultMessage: 'Vincoli',
  },
});
const PNProcedureMoreInfo = ({ content }) => {
  const intl = useIntl();
  return richTextHasContent(content?.other_info) ||
    richTextHasContent(content?.procedureCollegate) ||
    richTextHasContent(content?.copertura_geografica) ||
    richTextHasContent(content?.casiParticolari) ||
    richTextHasContent(content?.Vincoli) ||
    content?.ipa ||
    content?.Settore_merceologico ? (
    <>
      <RichTextSection
        data={content?.other_info}
        tag_id={'service-other_info'}
        field="text"
        title={intl.formatMessage(messages.otherInfo)}
        show_title={true}
        data-element="page-index"
      >
        {richTextHasContent(content?.procedureCollegate) && (
          <RichText
            data={content?.procedureCollegate}
            tag_id={'service-procedureCollegate'}
            field="text"
            title={intl.formatMessage(messages.procedureCollegate)}
            show_title={true}
          />
        )}
        {richTextHasContent(content?.copertura_geografica) && (
          <RichText
            data={content?.copertura_geografica}
            tag_id={'service-copertura_geografica'}
            field="text"
            title={intl.formatMessage(messages.copertura_geografica)}
            show_title={true}
          />
        )}
        {richTextHasContent(content?.casiParticolari) && (
          <RichText
            data={content?.casiParticolari}
            tag_id={'service-casiParticolari'}
            field="text"
            title={intl.formatMessage(messages.CasiParticolari)}
            show_title={true}
          />
        )}
        {richTextHasContent(content?.Vincoli) && (
          <RichText
            data={content?.Vincoli}
            tag_id={'service-Vincoli'}
            field="text"
            title={intl.formatMessage(messages.Vincoli)}
            show_title={true}
          />
        )}
        {content?.ipa && (
          <div>
            <h5>{intl.formatMessage(messages.ipa)}</h5>
            <span>{content?.ipa}</span>
          </div>
        )}
        {content?.Settore_merceologico && (
          <div>
            <h5>{intl.formatMessage(messages.Settore_merceologico)}</h5>
            <span>{content?.Settore_merceologico}</span>
          </div>
        )}
      </RichTextSection>
    </>
  ) : (
    ''
  );
};

PNProcedureMoreInfo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureMoreInfo;
