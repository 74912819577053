/**
 * Login information
 * @module src/actions/getOnlineServiceInfo
 */
export const GET_ONLINE_SERVICE_INFO = 'GET_ONLINE_SERVICE_INFO';

/**
 * Get login info.
 * @function getLoginInfo
 * @returns {Object} Get login info action.
 */
export function getOnlineServiceInfo(path) {
  return {
    type: GET_ONLINE_SERVICE_INFO,
    request: {
      op: 'get',
      path: `${path}/@online-service`,
    },
  };
}
