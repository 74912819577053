import { Icon as BaseIcon } from '@plone/volto/components';
import { Button } from 'design-react-kit';
import PropTypes from 'prop-types';
import cieSVG from 'comune-pordenone-plone-theme/icons/cie-ico.svg';
import { defineMessages } from 'react-intl';
import { expandToBackendURL } from '@plone/volto/helpers';
import { flattenHTMLToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  loginCie: {
    id: 'login_cie',
    defaultMessage: 'CIE',
  },
  loginCieButton: {
    id: 'login_with_cie',
    defaultMessage: 'Login with CIE',
  },
});

export const CIELogin = ({ intl, description, url, info }) => {
  const onItemCick = (idp) => {
    const { search } = window.location;
    const query = new URLSearchParams(search);
    const came_from = query.get('came_from');

    if (came_from) {
      url += `?came_from=${came_from}`;
    }

    window.location = expandToBackendURL(url);
  };

  return (
    <div className="login-method">
      <h2>{intl.formatMessage(messages.loginCie)}</h2>
      <p className="description">{description}</p>
      <div className="authorized-spid-login mb-4">
        <Button
          className="btn-icon"
          color="italia"
          data-element="personal-area-login"
          size="big"
          onClick={onItemCick}
        >
          <BaseIcon name={cieSVG} style={{ width: 29, height: 29 }} />
          <span className="flex-fill">
            {intl.formatMessage(messages.loginCieButton)}
          </span>
        </Button>
        {info && (
          <div
            dangerouslySetInnerHTML={{ __html: flattenHTMLToAppURL(info) }}
          />
        )}
      </div>
    </div>
  );
};

CIELogin.propTypes = {
  intl: PropTypes.object,
  description: PropTypes.string,
  url: PropTypes.string,
  info: PropTypes.string,
};
