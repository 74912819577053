import React, { createRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ContentImage,
  SkipToMainContent,
  PageHeader,
  useSideMenu,
  RelatedItems,
  ContentTypeViewSections,
  Metadata,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import PNAdministratorContacts from './PNAdministratorContacts';
import PNAdministratorMandates from './PNAdministratorMandates';
import PNAdministratorBiography from './PNAdministratorBiography';
import PNAdministratorNotes from './PNAdministratorNotes';
import PNAdministratorInfo from './PNAdministratorInfo';
import PNAdministratorText from './PNAdministratorText';
import PNAdministratorDocuments from './PNAdministratorDocuments';
import PNAdministratorsViewSecretariat from '../PNAdministratorsView/PNAdministratorsViewSecretariat';
import {
  PNImageGallery,
  PNAlertBehavior,
} from 'comune-pordenone-plone-theme/components/View';

import { Container, Col, Row } from 'design-react-kit';

export const AdministratorSectionsOrder = [
  {
    /* HEADER IMAGE */

    component: ContentImage,
    props: { position: 'documentBody' },
  },
  { /* INFO AMMINISTRATORE */ component: PNAdministratorInfo },
  { /* CONTATTI */ component: PNAdministratorContacts },
  { /* MANDATI */ component: PNAdministratorMandates },
  { /* BIOGRAFIA */ component: PNAdministratorBiography },
  { /* NOTE */ component: PNAdministratorNotes },
  { /* TESTO */ component: PNAdministratorText },
  { /* FILE */ component: PNImageGallery },
  { /* DOCUMENTI */ component: PNAdministratorDocuments },
  { /* SEGRETARI */ component: PNAdministratorsViewSecretariat },
  { /* METADATA */ component: Metadata },
];

const PNAdministrator = ({ content }) => {
  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);
  content.title = content.firstName + ' ' + content.lastName;

  return (
    <Container className="px-4 container-administrator">
      <div className="px-lg-0">
        <SkipToMainContent />
        <PageHeader
          content={content}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
          className="px-md-4"
        />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={AdministratorSectionsOrder}
            />
          </section>
        </Row>
        <RelatedItems list={content?.relatedItems} />
      </div>
    </Container>
  );
};

PNAdministrator.propTypes = {
  content: PropTypes.shape({
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    alertText: PropTypes.string,
    alertExpiration: PropTypes.string,
    UID: PropTypes.string,
    relatedItems: PropTypes.arrayOf(PropTypes.object),
    text: PropTypes.object,
  }).isRequired,
};

export default PNAdministrator;
