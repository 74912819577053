import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  what_you_get: {
    id: 'what_you_get',
    defaultMessage: 'Cosa si ottiene',
  },
});

const PNProcedureWhatYouGet = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.cosa_si_ottiene) ? (
    <RichTextSection
      data={content.cosa_si_ottiene}
      tag_id={'service-whatYouGet'}
      field="text"
      title={intl.formatMessage(messages.what_you_get)}
      show_title={true}
      lighthouseId={'service-achieved'}
    />
  ) : (
    ''
  );
};

PNProcedureWhatYouGet.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureWhatYouGet;
