import { Col, Container, Row } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { BodyClass } from '@plone/volto/helpers';
import { CIELogin } from './CIELogin';
import { Helmet } from '@plone/volto/helpers';
import { RemoveBodyClass } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { SPIDLogin } from './SPIDLogin';
import { compose } from 'redux';
import { getLoginInfo } from 'comune-pordenone-plone-theme/actions';
import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';

const messages = defineMessages({
  login: {
    id: 'login_sso',
    defaultMessage: 'Log in',
  },
  loginDescription: {
    id: 'login_sso_description',
    defaultMessage:
      'To access the site and its services, use one of the following methods.',
  },
});

const PortalLogin = (props) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const loginInfo = useSelector((state) => state.loginInfo);

  useEffect(() => {
    if (!loginInfo.result || Object.keys(loginInfo.result).length === 0) {
      dispatch(getLoginInfo());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div id="page-login">
      <Helmet title={intl.formatMessage(messages.login)} />
      <BodyClass className="public-ui" />
      <RemoveBodyClass className="cms-ui" />
      <Container className="view-wrapper py-5">
        <Row className="view-container">
          <Col xs={12} lg={{ size: 10, offset: 1 }}>
            <h1 sans-serfif="true">{intl.formatMessage(messages.login)}</h1>
            <p className="description">
              {intl.formatMessage(messages.loginDescription)}
            </p>
          </Col>
        </Row>
        <hr className="d-none d-lg-block mt-0 mb-4" />
        <Row>
          <Col xs={12} lg={{ size: 8, offset: 2 }}>
            {/* SPID Access */}
            {loginInfo?.result?.spid && (
              <SPIDLogin
                description={loginInfo.result.spid.description}
                info={loginInfo.result.spid.info}
                intl={intl}
                url={loginInfo.result.spid.url}
                idps={loginInfo.result.spid.providers}
              />
            )}
            {/* CIE Access */}
            {loginInfo?.result?.cie && (
              <CIELogin
                description={loginInfo.result.cie.description}
                info={loginInfo.result.cie.info}
                intl={intl}
                url={loginInfo.result.cie.url}
              />
            )}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default compose(withRouter)(PortalLogin);
