/**
 * PNProcedureView view component.
 * @module components/theme/View/PNProcedureView
 */

import React, { createRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { Col, Container, Row } from 'design-react-kit';

import {
  RelatedItems,
  useSideMenu,
  ContentTypeViewSections,
  WideImage,
  SkipToMainContent,
  NewsItemPlaceholderAfterContent,
  ServizioArgomenti,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import PNProcedurePageHeader from './PNProcedurePageHeader';
import PNProcedureIntendedFor from './PNProcedureIntendedFor';
import PNProcedureHowToDo from './PNProcedureHowToDo';
import PNProcedureDescription from './PNProcedureDescription';
import PNProcedureNeeded from './PNProcedureNeeded';
import PNProcedureWhatYouGet from './PNProcedureWhatYouGet';
import PNProcedureTimesDeadlines from './PNProcedureTimesDeadlines';
import PNProcedureAccessService from './PNProcedureAccessService';
import PNProcedureTerms from './PNProcedureTerms';
import PNProcedureStateReason from './PNProcedureStateReason';
import PNProcedureCategory from './PNProcedureCategory';
import PNProcedureCosts from './PNProcedureCosts';
import PNProcedureMoreInfo from './PNProcedureMoreInfo';
import PNProcedureContacts from './PNProcedureContacts';
import PNProcedureArguments from './PNProcedureArguments';
import {
  PNAttachments,
  PNAlertBehavior,
} from 'comune-pordenone-plone-theme/components/View';
import { Metadata } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

import PropTypes from 'prop-types';

import { Breadcrumbs as ItaliaBreadcrumbs } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { useSelector } from 'react-redux';

export const ProcedureViewSectionsOrder = [
  { /* DESCRIPTION */ component: PNProcedureDescription },
  { /* INTENDED FOR */ component: PNProcedureIntendedFor },
  { /* HOW TO DO */ component: PNProcedureHowToDo },
  { /* NEEDED */ component: PNProcedureNeeded },
  { /* WHAT U GET */ component: PNProcedureWhatYouGet },
  { /* TIMES DEAD LINES */ component: PNProcedureTimesDeadlines },
  { /* ACCESS SERVICE */ component: PNProcedureAccessService },
  { /* TERMS */ component: PNProcedureTerms },
  { /* STATE REASON */ component: PNProcedureStateReason },
  { /* CATEGORY */ component: PNProcedureCategory },
  { /* COSTS */ component: PNProcedureCosts },
  { /* CONTACTS */ component: PNProcedureContacts },
  { /* ARGUMENTS */ component: PNProcedureArguments },
  // { /* ARGOMENTI */ component: ServizioArgomenti },
  { /* FILE */ component: PNAttachments },
  { /* MORE INFO */ component: PNProcedureMoreInfo },
];

/**
 * ModuloView view component class.
 * @function PNProcedureView
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */

const PNProcedureView = ({ content }) => {
  const intl = useIntl();

  let documentBody = createRef();
  const { sideMenuElements, SideMenu } = useSideMenu(content, documentBody);

  let related_items = [];
  if (content?.notizie_correlate?.length > 0) {
    related_items = [...related_items, ...content.notizie_correlate];
  }
  if (content?.relatedItems?.length > 0) {
    related_items = [...related_items, ...content.relatedItems];
  }

  return (
    <Container className="px-4 procedure-view">
      <script data-element="metatag" type="application/ld+json">
        {JSON.stringify({
          name: content?.title,
          serviceType: content['@type'],
          serviceOperator: { name: 'Comune di Pordenone' },
          areaServed: { name: content?.title },
          audience: { audienceType: 'Comune di Pordenone' },
          availableChannel: {
            serviceUrl: content?.accesso_online,
            serviceLocation: {
              name: 'Comune di Pordenone',
              address: {
                streetAddress: 'Corso Vittorio Emanuele II, 64',
                postalCode: '33170',
                addressLocality: 'Pordenone',
              },
            },
          },
        })}
      </script>
      <div className="px-4 px-lg-0">
        <SkipToMainContent />
        <PNProcedurePageHeader
          content={content}
          showreadingtime={true}
          showdates={true}
          showtassonomiaargomenti={true}
        />
        <PNAlertBehavior
          text={content?.alertText}
          expirationDate={content?.alertExpiration}
        />
        {/* HEADER IMAGE */}
        <WideImage
          title={content?.title}
          item={content}
          caption={content?.image_caption}
          fullWidth={true}
        />
        <Row className="border-top row-column-border row-column-menu-left">
          <Col tag="aside" lg={4}>
            {__CLIENT__ && (
              <SideMenu data={sideMenuElements} content_uid={content?.UID} />
            )}
          </Col>
          <section
            ref={documentBody}
            id="main-content-section"
            className="col-lg-8 it-page-sections-container"
          >
            {/* SEZIONI */}
            <ContentTypeViewSections
              content={content}
              defaultSections={ProcedureViewSectionsOrder}
            />
            <PNAttachments content={content?.items} />
            <Metadata content={content}></Metadata>
          </section>
        </Row>
        <NewsItemPlaceholderAfterContent content={content} />
        <RelatedItems list={related_items} />
      </div>
    </Container>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNProcedureView.propTypes = {
  content: PropTypes.shape({
    officeResponsible: PropTypes.object,
    procedureResponsible: PropTypes.object,
    replacementPower: PropTypes.object,
  }).isRequired,
};

export default PNProcedureView;
