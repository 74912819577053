import { updateVisibility, validateValue, setAutoValue } from './../utilities/fieldshelper';
/**
 * String attribute.
 * @module fields/string
 *
 */
import { useEffect, useState } from 'react';

import { Input } from 'design-react-kit';
import React from 'react';
import cx from 'classnames';
import { getFieldLabel } from './utils';
import { defineMessages, useIntl } from 'react-intl';

// eslint-disable-next-line prettier/prettier
export const StringField = ({
  config,
  context,
  recordUpdater,
  showIfFn,
  showIfBindings,
  showIfApis,
  validationRuleFn,
  validationRuleBindings,
  validationRuleApis,
  autoValueFn,
  autoValueBindings,
  autoValueApis
}) => {
  const intl = useIntl();
  const fieldId = `field-string-${config.name}`;
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState(context?.[config.name]);
  const [errors, setErrors] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  /**
   * On value change
   */
  useEffect(() => {
    // update value
    context[config.name] = value;
    recordUpdater({
      ...context,
    });

    // validate value
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, [value]);

  /**
   * On context change
   */
  useEffect(() => {
    if (value != context?.[config.name]) {
      setValue(context?.[config.name]);
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <Input
      type="text"
      label={getFieldLabel(config)}
      className={cx('', {
        'd-none': !isVisible,
      })}
      id={fieldId}
      value={value}
      validationText={errors}
      disabled={isDisabled}
      valid={errors ? false : value ? true : undefined}
      required={required}
      maxLength={config.maxLength}
      onChange={(e) => {
        setValue(e.target.value);
      }}
      infoText={config?.help}
    />
  );
};
