import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  how_toDo: {
    id: 'how_toDo',
    defaultMessage: 'Come fare',
  },
});

const PNProcedureHowToDo = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.how_toDo) ? (
    <RichTextSection
      data={content.how_toDo}
      tag_id="service-hotToDo"
      title={intl.formatMessage(messages.how_toDo)}
      show_title={true}
      lighthouseId={'service-how-to'}
    />
  ) : (
    ''
  );
};

PNProcedureHowToDo.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureHowToDo;
