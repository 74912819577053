/**
 * PNAnnouncemenReferent view component.
 * @module components/theme/View/PNAnnouncemenReferent
 */
import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  RichTextSection,
  ContactLink,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';

const messages = defineMessages({
  referentContacts: {
    id: 'referentContacts',
    defaultMessage: 'Cotatti referente',
  },
});

/**
 * PNAnnouncemenReferent view component class.
 * @function PNAnnouncemenReferent
 * @params {object} content Content object.
 * @returns {string} Markup of the component.
 */
const PNAnnouncemenReferent = ({ content }) => {
  const intl = useIntl();
  const base_cig_url =
    'https://dati.anticorruzione.it/superset/dashboard/dettaglio_cig/?cig=';
  return (
    <>
      {content?.label_referent_name ||
      content?.referent_phone ||
      content?.referent_email ? (
        <RichTextSection
          tag_id="referent-contacts"
          title={intl.formatMessage(messages.referentContacts)}
          show_title={false}
        >
          <div className="mb-5">
            <Card
              className="card card-teaser rounded shadow mt-3"
              noWrapper={true}
              tag="div"
            >
              <CardBody tag="div" className={'card-body pr-3 display-6'}>
                <CardTitle tag="h4">
                  {intl.formatMessage(messages.referentContacts)}
                </CardTitle>
                {content?.label_referent_name && (
                  <p className="card-text mt-3">
                    <Icon icon="it-user" padding={true} />
                    <span>{content.label_referent_name}</span>
                  </p>
                )}
                {content?.referent_phone && (
                  <p className="card-text mt-3">
                    <Icon icon="it-telephone" padding={true} />
                    <ContactLink tel={content.referent_phone} label={true} />
                  </p>
                )}
                {content?.referent_email && (
                  <p className="card-text mt-3">
                    <Icon icon="it-mail" padding={true} />
                    <ContactLink email={content.referent_email} label={true} />
                  </p>
                )}
              </CardBody>
            </Card>
          </div>
        </RichTextSection>
      ) : (
        ''
      )}
    </>
  );
};
/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
PNAnnouncemenReferent.propTypes = {
  content: PropTypes.shape({
    _ufficio_responsabile: PropTypes.array,
  }).isRequired,
};

export default PNAnnouncemenReferent;
