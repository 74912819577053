import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';
const messages = defineMessages({
  commissionMembers: {
    id: 'commissionMembers',
    defaultMessage: 'Membri della commissione',
  },
  textInfo: {
    id: 'textInfo',
    defaultMessage: 'Informazioni',
  },
  president: {
    id: 'president',
    defaultMessage: 'Presidente',
  },
  vice: {
    id: 'vice',
    defaultMessage: 'Vice Presidente',
  },
});

const PNAdministratorsViewBoardCommittee = ({ content }) => {
  const intl = useIntl();

  return (
    content?.Administrators?.length > 0 && (
      <RichTextSection
        tag_id="commission-members-list"
        title={intl.formatMessage(messages.commissionMembers)}
        show_title={true}
      >
        <ul className="commission-list p-0">
          {content.Administrators.map((item, index) => (
            <li
              className={`p-3 rounded ${index % 2 === 0 ? 'bg-primary' : ''}`}
              // key={index}
            >
              <a
                href={flattenToAppURL(item.url)}
                title={`Leggi altro su ${item.name}`}
                className={`commission-list-link-name ${
                  index % 2 === 0 ? 'text-white' : ''
                }`}
              >
                <b className="text-uppercase">{item.name}</b>
              </a>
              {item?.email && (
                <>
                  {' - '}
                  <span>
                    <a
                      href={`mailto:${item.email}`}
                      title="Invia e-mail"
                      className={`administratorEmail ${
                        index % 2 === 0 ? 'text-white' : ''
                      }`}
                    >
                      {item.email}
                    </a>
                  </span>
                </>
              )}
              <span>
                {item?.president === true
                  ? ` (${intl.formatMessage(messages.president)}) `
                  : ''}
                {item?.vice === true
                  ? ` (${intl.formatMessage(messages.vice)}) `
                  : ''}
              </span>
              {item.role && <span> ({item.role})</span>}
            </li>
          ))}
        </ul>
      </RichTextSection>
    )
  );
};

PNAdministratorsViewBoardCommittee.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewBoardCommittee;
