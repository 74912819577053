import { defineMessages, useIntl } from 'react-intl';
import React, { useState } from 'react';
import { Card, CardBody } from 'design-react-kit';
import PropTypes from 'prop-types';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  documents: {
    id: 'documents',
    defaultMessage: 'Documenti',
  },
  appointmentDate: {
    id: 'appointmentDate',
    defaultMessage: 'Data di nomina',
  },
  appointment_file: {
    id: 'appointment_file',
    defaultMessage: 'Atto di nomina',
  },
  appointment_file_label: {
    id: 'appointment_file_label',
    defaultMessage: 'Note',
  },
  curriculumVitae: {
    id: 'curriculumVitae',
    defaultMessage: 'Curriculum Vitae',
  },
  label_otherPositions: {
    id: 'label_otherPositions',
    defaultMessage: `Cariche in altri enti, incarichi pubblici e titolarità d'impresa`,
  },
  balanceSheet: {
    id: 'balanceSheet',
    defaultMessage: `Situazione patrimoniale`,
  },
  changesBalanceSheet: {
    id: 'changesBalanceSheet',
    defaultMessage: `Variazioni sulla situazione patrimoniale`,
  },
  electioneeringExpenses: {
    id: 'electioneeringExpenses',
    defaultMessage: `Spese elettorali`,
  },
  taxReturn: {
    id: 'taxReturn',
    defaultMessage: `Dichiarazione dei redditi`,
  },
  label_familyIncome: {
    id: 'label_familyIncome',
    defaultMessage: `Redditi del coniuge e dei familiari fino al II grado di parentela`,
  },
});

const PNAdministratorDocuments = ({ content }) => {
  const intl = useIntl();

  return content?.curriculumVitae ||
    content?.balanceSheet1 ||
    content?.balanceSheet2 ||
    content?.balanceSheet3 ||
    content?.balanceSheet4 ||
    content?.balanceSheet5 ||
    content?.balanceSheet6 ||
    content?.taxReturn ||
    content?.taxReturn2 ||
    content?.taxReturn3 ||
    content?.taxReturn4 ||
    content?.taxReturn5 ||
    content?.taxReturn6 ||
    content?.taxReturn_text ||
    content?.electioneeringExpenses ||
    content?.changesBalanceSheet1 ||
    content?.changesBalanceSheet2 ||
    content?.changesBalanceSheet3 ||
    content?.changesBalanceSheet4 ||
    content?.changesBalanceSheet5 ||
    content?.changesBalanceSheet6 ||
    content?.changesBalanceSheet_text ||
    content?.otherPositions1 ||
    content?.otherPositions2 ||
    content?.otherPositions3 ||
    content?.otherPositions4 ||
    content?.otherPositions5 ||
    content?.otherPositions6 ||
    content?.familyIncome ||
    content?.familyIncome2 ||
    content?.familyIncome3 ||
    content?.familyIncome4 ||
    content?.familyIncome5 ||
    content?.familyIncome6 ||
    content?.familyIncome_text ? (
    <>
      {/* Curriculum */}
      {content?.curriculumVitae && (
        <RichTextSection
          tag_id={'title-administrator-curriculum'}
          field="text"
          title={intl.formatMessage(messages.curriculumVitae)}
          show_title={true}
        >
          <Card className="card-bg rounded">
            <CardBody className="pb-0">
              <p className="card-text">
                <Icon
                  icon="it-clip"
                  title={intl.formatMessage(messages.curriculumVitae)}
                />
                <a href={flattenToAppURL(content.curriculumVitae.download)}>
                  {content?.curriculumVitae_label
                    ? content.curriculumVitae_label
                    : intl.formatMessage(messages.curriculumVitae)}
                </a>
              </p>
            </CardBody>
          </Card>
        </RichTextSection>
      )}
      {/* Situazione patrimoniale - balanceSheet */}
      {content?.balanceSheet1 ||
      content?.balanceSheet2 ||
      content?.balanceSheet3 ||
      content?.balanceSheet4 ||
      content?.balanceSheet5 ||
      content?.balanceSheet6 ? (
        <RichTextSection
          tag_id={'administrator-balanceSheet'}
          title={intl.formatMessage(messages.balanceSheet)}
          show_title={true}
        >
          {content?.balanceSheet1 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet1.download)}>
                    {content?.balanceSheet1_label
                      ? content?.balanceSheet1_label
                      : content.balanceSheet1.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.balanceSheet2 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet2.download)}>
                    {content?.balanceSheet2_label
                      ? content?.balanceSheet2_label
                      : content.balanceSheet2.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.balanceSheet3 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet3.download)}>
                    {content?.balanceSheet3_label
                      ? content?.balanceSheet3_label
                      : content.balanceSheet3.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.balanceSheet4 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet4.download)}>
                    {content?.balanceSheet4_label
                      ? content?.balanceSheet4_label
                      : content.balanceSheet4.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.balanceSheet5 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet5.download)}>
                    {content?.balanceSheet5_label
                      ? content?.balanceSheet5_label
                      : content.balanceSheet5.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.balanceSheet6 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.balanceSheet)}
                  />
                  <a href={flattenToAppURL(content.balanceSheet6.download)}>
                    {content?.balanceSheet6_label
                      ? content?.balanceSheet6_label
                      : content.balanceSheet6.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
      {/* Dichiarazione dei redditi - taxReturn */}
      {content?.taxReturn ||
      content?.taxReturn2 ||
      content?.taxReturn3 ||
      content?.taxReturn4 ||
      content?.taxReturn5 ||
      content?.taxReturn6 ||
      content?.taxReturn_text ? (
        <RichTextSection
          tag_id={'administrator-taxReturn'}
          title={intl.formatMessage(messages.taxReturn)}
          show_title={true}
        >
          {content?.taxReturn && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn?.download}
                    download={content?.taxReturn?.download}
                  >
                    {content?.taxReturn_label
                      ? content?.taxReturn_label
                      : content?.taxReturn?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn2 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn2?.download}
                    download={content?.taxReturn2?.download}
                  >
                    {content?.taxReturn2_label
                      ? content?.taxReturn2_label
                      : content?.taxReturn2?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn3 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn3?.download}
                    download={content?.taxReturn3?.download}
                  >
                    {content?.taxReturn3_label
                      ? content?.taxReturn3_label
                      : content?.taxReturn3?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn4 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn4?.download}
                    download={content?.taxReturn4?.download}
                  >
                    {content?.taxReturn4_label
                      ? content?.taxReturn4_label
                      : content?.taxReturn4?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn5 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn5?.download}
                    download={content?.taxReturn5?.download}
                  >
                    {content?.taxReturn5_label
                      ? content?.taxReturn5_label
                      : content?.taxReturn5?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn6 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-clip" padding={true} />
                  <a
                    href={content?.taxReturn6?.download}
                    download={content?.taxReturn6?.download}
                  >
                    {content?.taxReturn6_label
                      ? content?.taxReturn6_label
                      : content?.taxReturn6?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.taxReturn_text && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <p>{content?.taxReturn_text}</p>
                </div>
              </CardBody>
            </Card>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
      {/* Spese elettorali - electioneeringExpenses */}
      {content?.electioneeringExpenses && (
        <RichTextSection
          tag_id={'administrator-electioneeringExpenses'}
          title={intl.formatMessage(messages.electioneeringExpenses)}
          show_title={true}
        >
          <Card className="card-bg rounded mb-3">
            <CardBody className="pb-0">
              <div>
                <Icon icon="it-clip" padding={true} />
                <a
                  href={content?.electioneeringExpenses?.download}
                  download={content?.electioneeringExpenses?.download}
                >
                  {content?.electioneeringExpenses_label
                    ? content?.electioneeringExpenses_label
                    : content?.electioneeringExpenses?.filename}
                </a>
              </div>
            </CardBody>
          </Card>
        </RichTextSection>
      )}
      {/* Cariche in altri enti, incarichi pubblici e titolarità d'impresa - OtherPositions */}
      {content?.otherPositions1 ||
      content?.otherPositions2 ||
      content?.otherPositions3 ||
      content?.otherPositions4 ||
      content?.otherPositions5 ||
      content?.otherPositions6 ? (
        <RichTextSection
          tag_id={'administrator-otherPositions'}
          title={intl.formatMessage(messages.label_otherPositions)}
          show_title={true}
        >
          {content?.otherPositions1 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                {content?.otherPositions1 && (
                  <div>
                    <Icon icon="it-clip" padding={true} />
                    <a
                      href={content?.otherPositions1?.download}
                      download={content?.otherPositions1?.download}
                    >
                      {content?.otherPositions1_label
                        ? content?.otherPositions1_label
                        : content?.otherPositions1?.filename}
                    </a>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {content?.otherPositions2 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                {content?.otherPositions2 && (
                  <div>
                    <Icon icon="it-clip" padding={true} />
                    <a
                      href={content?.otherPositions2?.download}
                      download={content?.otherPositions2?.download}
                    >
                      {content?.otherPositions2_label
                        ? content?.otherPositions2_label
                        : content?.otherPositions2?.filename}
                    </a>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {content?.otherPositions3 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                {content?.otherPositions3 && (
                  <div>
                    <Icon icon="it-clip" padding={true} />
                    <a
                      href={content?.otherPositions3?.download}
                      download={content?.otherPositions3?.download}
                    >
                      {content?.otherPositions3?.filename}
                      {content?.otherPositions3_label
                        ? content?.otherPositions3_label
                        : content?.otherPositions3?.filename}
                    </a>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {content?.otherPositions4 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                {content?.otherPositions4 && (
                  <div>
                    <Icon icon="it-clip" padding={true} />
                    <a
                      href={content?.otherPositions4?.download}
                      download={content?.otherPositions4?.download}
                    >
                      {content?.otherPositions4_label
                        ? content?.otherPositions4_label
                        : content?.otherPositions4?.filename}
                    </a>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
          {content?.otherPositions5 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                {content?.otherPositions5 && (
                  <div>
                    <Icon icon="it-clip" padding={true} />
                    <a
                      href={content?.otherPositions5?.download}
                      download={content?.otherPositions5?.download}
                    >
                      {content?.otherPositions5_label
                        ? content?.otherPositions5_label
                        : content?.otherPositions5?.filename}
                    </a>
                  </div>
                )}
              </CardBody>
            </Card>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
      {/* Variazioni sulla situazione patrimoniale - changesBalanceSheet */}
      {content?.changesBalanceSheet1 ||
      content?.changesBalanceSheet2 ||
      content?.changesBalanceSheet3 ||
      content?.changesBalanceSheet4 ||
      content?.changesBalanceSheet5 ||
      content?.changesBalanceSheet6 ||
      content?.changesBalanceSheet_text ? (
        <RichTextSection
          tag_id={'administrator-changesBalanceSheet'}
          title={intl.formatMessage(messages.changesBalanceSheet)}
          show_title={true}
        >
          {content?.changesBalanceSheet1 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet1.download,
                    )}
                  >
                    {content?.changesBalanceSheet1_label
                      ? content?.changesBalanceSheet1_label
                      : content.changesBalanceSheet1.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet2 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet2.download,
                    )}
                  >
                    {content?.changesBalanceSheet2_label
                      ? content?.changesBalanceSheet2_label
                      : content.changesBalanceSheet2.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet3 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet3.download,
                    )}
                  >
                    {content?.changesBalanceSheet3_label
                      ? content?.changesBalanceSheet3_label
                      : content.changesBalanceSheet3.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet4 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet4.download,
                    )}
                  >
                    {content?.changesBalanceSheet4_label
                      ? content?.changesBalanceSheet4_label
                      : content.changesBalanceSheet4.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet5 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet5.download,
                    )}
                  >
                    {content?.changesBalanceSheet5_label
                      ? content?.changesBalanceSheet5_label
                      : content.changesBalanceSheet5.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet6 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <p className="card-text">
                  <Icon
                    icon="it-clip"
                    title={intl.formatMessage(messages.changesBalanceSheet)}
                  />
                  <a
                    href={flattenToAppURL(
                      content.changesBalanceSheet6.download,
                    )}
                  >
                    {content?.changesBalanceSheet6_label
                      ? content?.changesBalanceSheet6_label
                      : content.changesBalanceSheet6.filename}
                  </a>
                </p>
              </CardBody>
            </Card>
          )}
          {content?.changesBalanceSheet_text && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <p>{content?.changesBalanceSheet_text}</p>
                </div>
              </CardBody>
            </Card>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
      {/* Redditi del coniuge e dei familiari fino al II grado di parentela - Family Income */}
      {content?.familyIncome ||
      content?.familyIncome2 ||
      content?.familyIncome3 ||
      content?.familyIncome4 ||
      content?.familyIncome5 ||
      content?.familyIncome6 ||
      content?.familyIncome_text ? (
        <RichTextSection
          tag_id={'administrator-familyIncome'}
          title={intl.formatMessage(messages.label_familyIncome)}
          show_title={true}
        >
          {content?.familyIncome && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome?.download}
                    download={content?.familyIncome?.download}
                  >
                    {content?.familyIncome_label
                      ? content?.familyIncome_label
                      : content?.familyIncome?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome2 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome2?.download}
                    download={content?.familyIncome2?.download}
                  >
                    {content?.familyIncome2_label
                      ? content?.familyIncome2_label
                      : content?.familyIncome2?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome3 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome3?.download}
                    download={content?.familyIncome3?.download}
                  >
                    {content?.familyIncome3_label
                      ? content?.familyIncome3_label
                      : content?.familyIncome3?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome4 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome4?.download}
                    download={content?.familyIncome4?.download}
                  >
                    {content?.familyIncome4_label
                      ? content?.familyIncome4_label
                      : content?.familyIncome4?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome5 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome5?.download}
                    download={content?.familyIncome5?.download}
                  >
                    {content?.familyIncome5_label
                      ? content?.familyIncome5_label
                      : content?.familyIncome5?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome6 && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <Icon icon="it-chevron-right" padding={true} />
                  <a
                    href={content?.familyIncome6?.download}
                    download={content?.familyIncome6?.download}
                  >
                    {content?.familyIncome6_label
                      ? content?.familyIncome6_label
                      : content?.familyIncome6?.filename}
                  </a>
                </div>
              </CardBody>
            </Card>
          )}
          {content?.familyIncome_text && (
            <Card className="card-bg rounded mb-3">
              <CardBody className="pb-0">
                <div>
                  <p>{content?.familyIncome_text}</p>
                </div>
              </CardBody>
            </Card>
          )}
        </RichTextSection>
      ) : (
        ''
      )}
    </>
  ) : (
    ''
  );
};

PNAdministratorDocuments.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorDocuments;
