import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  ContactLink,
  RichTextSection,
  richTextHasContent,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { Row, Col } from 'design-react-kit';
import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  textInfo: {
    id: 'textInfo',
    defaultMessage: 'Informazioni',
  },
  mandates: {
    id: 'mandates',
    defaultMessage: 'Deleghe',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  councillors: {
    id: 'councillors',
    defaultMessage: 'Consiglieri',
  },
});

const PNAdministratorsViewCityBoard = ({ content }) => {
  const intl = useIntl();
  return content?.Administrators.map((item, index) => (
    <RichTextSection
      tag_id={item.lastName}
      title={item.firstName + ' ' + item.lastName}
      show_title={false}
      // key={index}
    >
      <Card
        className="card-bg rounded my-3 administrator-main-card"
        noWrapper={false}
        space
        tag="div"
      >
        <CardBody tag="div">
          <Row>
            {/* IMAGE  */}
            {item?.image && (
              <Col className={'administrator-image-col'}>
                <img src={item?.image?.download} alt={item?.image?.filename} />
              </Col>
            )}
            <Col className={'administrator-roles'}>
              {/* FIRSTNAME LAST NAME  */}
              {item?.firstName && item?.lastName ? (
                <CardTitle tag="h3">
                  <a href={flattenToAppURL(item['@id'])}>
                    {item.firstName + ' ' + item.lastName}
                  </a>
                </CardTitle>
              ) : (
                ''
              )}
              {/* ROLES  */}
              {item?.administratorRoles?.length > 0
                ? item.administratorRoles.map((role, index) => (
                    <p>
                      <b>{role.title}</b>
                    </p>
                  ))
                : ''}
            </Col>
          </Row>
          <Row>
            <Col className={'administrator-info mt-4'} md="12">
              {/* MANDATEES */}
              {richTextHasContent(item?.mandates) && (
                <RichTextSection
                  data={item?.mandates}
                  title={intl.formatMessage(messages.mandates)}
                />
              )}
              {item?.phone || item?.email ? (
                <div>
                  {/* PHONE */}
                  <h4>{intl.formatMessage(messages.contacts)}</h4>
                  {item?.phone && (
                    <p className="card-text">
                      <Icon
                        icon="it-telephone"
                        padding={true}
                        className="icon-administrator"
                      />
                      <ContactLink tel={item.phone} label={true} />
                    </p>
                  )}
                  {/* EMAIL  */}
                  {item?.email && (
                    <p className="card-text">
                      <Icon
                        icon="it-mail"
                        padding={true}
                        className="icon-administrator"
                      />
                      <ContactLink email={item.email} label={true} />
                    </p>
                  )}
                </div>
              ) : (
                ''
              )}
            </Col>
          </Row>
        </CardBody>
      </Card>
    </RichTextSection>
  ));
};

PNAdministratorsViewCityBoard.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorsViewCityBoard;
