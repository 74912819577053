/**
 * Form widgets WfInfoWidget module.
 * @module formwidgets/wfinfowidget
 *
 */
import { CardModel, ProcessInstanceModel, WidgetModel } from '../models';
import { extractFieldFeatureBindings, getFieldFeatureApis, getShowIfFn, updateVisibility } from './../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import { useClassModel } from '../modelsuses';

export const WfInfoWidget = ({ config, target }: { config: WidgetModel; target: CardModel | ProcessInstanceModel | object }) => {
  const type = target._type;
  const code = config.Code;
  const { getCards } = useClassModel();
  const [text, setText] = useState();
  const [isVisible, setIsVisible] = useState(true);
  const [isDisabled, setIsDisabled] = useState(false);

  const showIf = config.ShowIf || '';
  let showIfBindings;
  let showIfApis: any;
  let showIfFn: Function | undefined;

  if (showIf) {
    showIfBindings = extractFieldFeatureBindings(showIf, target);
    showIfApis = getFieldFeatureApis(target, {});
    showIfFn = getShowIfFn(showIf);
  }

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  useEffect(() => {
    getCards('WfText', {
      filter: {
        attribute: {
          and: [
            {
              simple: {
                attribute: 'Code',
                operator: 'equal',
                value: [code],
              },
            },
            {
              simple: {
                attribute: 'Workflow',
                operator: 'equal',
                value: [type],
              },
            },
          ],
        },
      },
    }).then(({ data }: { data: object[] }) => {
      if (data && data.length) {
        setText(data[0].Text);
      }
    });
  }, []);

  return <div dangerouslySetInnerHTML={{ __html: text }} hidden={!isVisible} style={{ marginBottom: '2rem' }} />;
};
