import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  mandates: {
    id: 'mandates',
    defaultMessage: 'Deleghe',
  },
});

const PNAdministratorMandates = ({ content }) => {
  const intl = useIntl();

  return (
    richTextHasContent(content?.mandates) && (
      <RichTextSection
        tag_id={'title-administrator-mandates'}
        field="text"
        title={intl.formatMessage(messages.mandates)}
        show_title={true}
        data={content?.mandates}
        className={'mb-5'}
      />
    )
  );
};

PNAdministratorMandates.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorMandates;
