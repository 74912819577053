/**
 * Form helper utility.
 * @module utilities/formhelper
 *
 */
import { ActivityModel, AttributeGroupModel, AttributeModel, CardModel, ClassModel, ProcessInstanceModel, ProcessModel, WidgetModel } from '../models';
import { Card, CardBody, CardText, CardTitle, Col, Row } from 'design-react-kit';

import { CMDBuildAttribute } from './fieldshelper';
import { MessageField } from '../fields';

export const CMDBuildForm = ({ groups, attributes, layout, widgets, context, updateContext, activity, model }: { groups: AttributeGroupModel[]; attributes: AttributeModel[]; layout: { [groupname: string]: { rows: object[] } }; widgets: WidgetModel[]; context: CardModel | ProcessInstanceModel | object; updateContext: Function; activity: ActivityModel | undefined; model: ClassModel | ProcessModel }) => {
  const getGroupRows = (groupname: string): object[] => {
    if (layout) {
      return layout[groupname]?.rows || [];
    }
    return [];
  };

  const layoutkeys = Object.keys(layout);
  const showngroups = groups.filter((g) => layoutkeys.includes(g.name));
  const attributeswidgets = widgets.filter((w) => !!w.RelatedTo || !!w.BeforeAttribute || !!w.AfterAttribute);

  return (
    <div>
      <MessageField attribute={model?.messageAttr} context={context} />
      {showngroups?.map((group, index) => (
        <Card className="card-bg card-big no-after mb-5 h-100" id={`fieldset-${group.name}`}>
          <CardBody
            className="card shadow-sm p-5 has-bkg-grey"
            style={{
              backgroundColor: '#ebeef0',
            }}
          >
            <CardTitle tag="h2">{group._description_translation}</CardTitle>
            <CardText className="bg-white p-5 pb-2 mt-3">
              {getGroupRows(group.name).map((row) => (
                <Row>{row.columns.length ? row.columns.map((column) => <FormColumn column={column} attributes={attributes} context={context} updateContext={updateContext} activity={activity} rowcols={row.columns.length} widgets={attributeswidgets} />) : ''}</Row>
              ))}
            </CardText>
          </CardBody>
        </Card>
      ))}
    </div>
  );
};

const FormColumn = ({ column, attributes, context, updateContext, activity, rowcols, widgets = [] }: { column: { width: number; fields: { attribute: string }[] }; attributes: AttributeModel[]; context: CardModel | ProcessInstanceModel | object; updateContext: Function; activity: ActivityModel; rowcols: number; widgets: WidgetModel[] }) => {
  const size = 12 * column.width || 12 / rowcols;

  const findAttribute = (name: string) => {
    return attributes.find((attr: AttributeModel) => attr.name === name);
  };

  const findWidgets = (name: string) => {
    return widgets.filter((w) => w.RelatedTo === name || w.BeforeAttribute === name || w.AfterAttribute === name);
  };

  return (
    <Col xs="12" xl={size}>
      {column.fields.map((field: { attribute: string }) => {
        const attr = findAttribute(field.attribute);
        if (attr) {
          return <CMDBuildAttribute attribute={attr} context={context} setContext={updateContext} activity={activity} inlineWidgets={findWidgets(attr.name)} />;
        }
        return null;
      })}
    </Col>
  );
};
