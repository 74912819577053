import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  description: {
    id: 'description',
    defaultMessage: 'Descrizione',
  },
});

const PNAssociationEventText = ({ content }) => {
  const intl = useIntl();

  return (
    content?.text && (
      <RichTextSection
        data={content?.text}
        tag_id="description"
        title={intl.formatMessage(messages.description)}
      />
    )
  );
};

PNAssociationEventText.propTypes = {
  content: PropTypes.shape({
    prezzo: PropTypes.shape({
      data: PropTypes.string,
    }),
  }).isRequired,
};

export default PNAssociationEventText;
