import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { Metadata } from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  progressive_number: {
    id: 'progressive_number',
    defaultMessage: 'Numero progressivo: ',
  },
});

const PNNewsMoreInformation = ({ content }) => {
  const intl = useIntl();
  const showSectionTitle = useMemo(
    () => content?.numero_progressivo,
    [content],
  );

  return (
    <Metadata content={content} showSectionTitle={showSectionTitle}>
      {content?.numero_progressivo && (
        <div className="mt-4">
          <h6>
            {intl.formatMessage(messages.progressive_number)}
            <small>{content?.numero_progressivo}</small>
          </h6>
        </div>
      )}
    </Metadata>
  );
};

PNNewsMoreInformation.propTypes = {
  content: PropTypes.shape({
    ulteriori_informazioni: PropTypes.shape({
      data: PropTypes.string,
    }),
    items: PropTypes.array,
  }).isRequired,
};

export default PNNewsMoreInformation;
