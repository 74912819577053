import React from 'react';
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import {
  richTextHasContent,
  RichText,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  long_description: {
    id: 'long_description',
    defaultMessage: 'Descrizione estesa',
  },
  sort_description: {
    id: 'sort_description',
    defaultMessage: 'Descrizione breve',
  },
});

const PNProcedureDescription = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.long_description) ||
    content?.short_description ? (
    <>
      {richTextHasContent(content?.long_description) && (
        <RichText
          data={content.long_description}
          className={'bg-primary'}
          tag_id={'service-descrizione-estesa'}
          field="text"
          show_title={false}
        />
      )}
      <p>{content?.short_description}</p>
    </>
  ) : (
    ''
  );
};

PNProcedureDescription.propTypes = {
  content: PropTypes.shape({
    text: PropTypes.object,
  }).isRequired,
};

export default PNProcedureDescription;
