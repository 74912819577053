/**
 * Volto CMDBuild processes proxy utilities.
 * @module proxies/processes
 *
 * Proxy for process instances management.
 *
 */
import { ActivityModel, AttributeModel, ProcessInstanceModel } from '../models';

import { RequestConfig } from './utils';
import { RequestResponse } from './utils';
import { expandToBackendURL } from '@plone/volto/helpers';
import { makeGetRequest } from './utils';
import { makePostRequest } from './utils';
import { makePutRequest } from './utils';

export const getProcessesProxy = async (config?: RequestConfig): Promise<RequestResponse> => {
  return makeGetRequest(`processes`, config);
};

export const getPorcessAttributesProxy = async (processName: string): Promise<AttributeModel[] | null> => {
  const response = await fetch(expandToBackendURL(`@c_services/processes/${processName}/attributes`), {
    headers: {
      Accept: 'application/json',
    },
  });
  const j_response = await response.json();
  return j_response.data;
};

export const getProcessInstanceProxy = async (processName: string, instanceId: number): Promise<ProcessInstanceModel | null> => {
  const response = await fetch(
    expandToBackendURL(`@c_services/processes/${processName}/instances/${instanceId}`), {
      headers: {
        Accept: 'application/json',
      },
    }
  );
  const j_response = await response.json();
  return j_response.data;
};

export const getProcessInstancesProxy = async (processName: string, config?: RequestConfig): Promise<ProcessInstanceModel | null> => {
  return makeGetRequest(`classes/${processName}/cards`, config);
};
export const getProcessInstanceAttachmentsProxy = async (processName: string, instanceId: number, config?: RequestConfig): Promise<RequestResponse> => {
  return makeGetRequest(`processes/${processName}/instances/${instanceId}/attachments`, config);
};

export const getProcessActivityProxy = async (processName: string, instanceId?: number, activityId?: string): Promise<ActivityModel> => {
  let url;
  if (instanceId && activityId) {
    url = `@c_services/processes/${processName}/instances/${instanceId}/activities/${activityId}`;
  } else {
    url = `@c_services/processes/${processName}/start_activities`;
  }
  const response = await fetch(expandToBackendURL(url), {
    headers: {
      Accept: 'application/json',
    },
  });
  const j_response = await response.json();
  return j_response.data;
};

export const postProcessInstanceProxy = async (processName: string, activityId: string, data: object, advance: boolean): Promise<ProcessInstanceModel | null> => {
  const url = `processes/${processName}/instances`;
  const instance_data = {
    ...data,
    _activity: activityId,
    _advance: advance,
  };
  return makePostRequest(url, instance_data);
};

export const putProcessInstanceProxy = async (processName: string, instanceId: number, activityId: string, data: object, advance: boolean): Promise<ProcessInstanceModel | null> => {
  const url = `processes/${processName}/instances/${instanceId}`;
  const instance_data = {
    ...data,
    _activity: activityId,
    _advance: advance,
  };
  return makePutRequest(url, instance_data);
};
