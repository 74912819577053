/**
 * Login information
 * @module src/actions/isAuthenticated
 */
export const GET_PORTAL_USER = 'GET_PORTAL_USER';
export const POST_LOGOUT = 'POST_LOGOUT';

/**
 * Get portal user.
 * @function getPortalUser
 * @returns {Object} Get portal user action.
 */
export function getPortalUser() {
  return {
    type: GET_PORTAL_USER,
    request: {
      op: 'get',
      path: '/@portal-user',
    },
  };
}

/**
 * Do logout.
 * @function getLoginInfo
 * @returns {Object} Get login info action.
 */
export function doLogout() {
  return {
    type: POST_LOGOUT,
    request: {
      op: 'post',
      path: '/@portal-logout',
    },
  };
}
