/*
 * PNCuredBy component class
 */

import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { Chip, ChipLabel } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';
import {
  OfficeCard,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  cured_by: {
    id: 'cured_by',
    defaultMessage: 'A cura di',
  },
  page_cured_by: {
    id: 'page_cured_by',
    defaultMessage: 'Questa pagina è gestita da',
  },
  cured_by_people: {
    id: 'cured_by_people',
    defaultMessage: 'Persone',
  },
});

const PNCuredBy = ({ content }) => {
  const intl = useIntl();
  return content?.offices || content?.person?.length > 0 ? (
    <RichTextSection
      tag_id="a-cura-di"
      title={intl.formatMessage(messages.cured_by)}
      className="it-page-section anchor-offset mb-5"
    >
      <div className="row">
        {content?.offices && (
          <div className="col-12 col-sm-8">
            <h5>
              <small>{intl.formatMessage(messages.page_cured_by)}</small>
            </h5>
            <OfficeCard office={content?._offices} />
          </div>
        )}
        {content?.person?.length > 0 ? (
          <div className={`col-12 ${content?.offices && 'col-sm-4'}`}>
            <h5>
              <small>{intl.formatMessage(messages.cured_by_people)}</small>
            </h5>
            {content?.person.map((item, i) => (
              <a
                href={flattenToAppURL('resolveuid/' + item?.token)}
                key={item?.token}
                className="text-decoration-none me-2"
              >
                <Chip
                  color="primary"
                  disabled={false}
                  large={false}
                  simple
                  tag="div"
                >
                  <ChipLabel tag="span">{item?.title}</ChipLabel>
                </Chip>
              </a>
            ))}
          </div>
        ) : null}
      </div>
    </RichTextSection>
  ) : (
    ''
  );
};
export default PNCuredBy;

PNCuredBy.propTypes = {
  office: PropTypes.shape({
    '@id': PropTypes.string,
    '@type': PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    review_state: PropTypes.string,
  }),
  people: PropTypes.arrayOf(
    PropTypes.shape({
      '@id': PropTypes.string,
      '@type': PropTypes.string,
      title: PropTypes.string,
      description: PropTypes.string,
      review_state: PropTypes.string,
    }),
  ),
};
