/**
 * PNProcedureContacts view component.
 * @module components/theme/View/PNProcedureContacts
 */

import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';

import { Icon, Logo } from 'design-comuni-plone-theme/components/ItaliaTheme';
import {
  richTextHasContent,
  RichTextSection,
  ContactLink,
  OfficeCard,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { Card, CardBody, CardTitle } from 'design-react-kit';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  supported_by: {
    id: 'supported_by',
    defaultMessage: 'Con il supporto di',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  web_site: {
    id: 'web_site',
    defaultMessage: 'Sito web: ',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'facebook: ',
  },
});

const PNProcedureContacts = ({ content }) => {
  const intl = useIntl();
  const getOffice = () => {
    return (
      content?.organizationalUnit && (
        <div className="col-xl-8 col-md-10 col-12">
          <h5 className="mt-4 supported-by">
            {intl.formatMessage(messages.supported_by)}
          </h5>
          <OfficeCard
            key={content?._organizationalUnit['@id']}
            office={content?._organizationalUnit}
            extended={true}
            icon={'it-pa'}
            data-element="service-area"
          />
        </div>
      )
    );
  };

  return richTextHasContent(content?.contact_name) ||
    content?.ufficio_responsabile ||
    content?.contact_phone ||
    content?.contact_email ||
    content?.facebookUrl ||
    content?.fax ? (
    <RichTextSection
      tag_id="contatti"
      title={intl.formatMessage(messages.contacts)}
      lighthouseId={'contacts'}
      show_title={false}
    >
      <div className="col-12 col-md-8 mb-30">
        <div className="mb-5">
          <Card
            className="ccard card-teaser rounded shadow mt-3"
            data-element="service-area"
          >
            <CardBody className="card-body pe-3">
              <CardTitle tag="h4">
                {intl.formatMessage(messages.contacts)}
              </CardTitle>
              <div className="card-text service-contacts">
                {content?.ufficio_responsabile && (
                  <p className="text-primary pl-2" data-element="service-area">
                    <a
                      href={flattenToAppURL(
                        'resolveuid/' + content.ufficio_responsabile?.token,
                      )}
                    >
                      {content.ufficio_responsabile?.title}
                    </a>
                  </p>
                )}
                {content?.telephone && (
                  <p className="card-text u-main-black">
                    <Icon
                      icon="it-telephone"
                      padding={true}
                      className="icons-ServiceContacts"
                    />
                    <ContactLink tel={content.telephone} label={false} />
                  </p>
                )}
                {content?.email && (
                  <p className="card-text u-main-black">
                    <Icon
                      icon="it-mail"
                      padding={true}
                      className="icons-ServiceContacts"
                    />
                    <ContactLink email={content.email} label={false} />
                  </p>
                )}
                {content?.pec && (
                  <p className="card-text u-main-black">
                    <Icon
                      icon="it-mail"
                      padding={true}
                      className="icons-ServiceContacts"
                    />
                    <ContactLink email={content.pec} label={false} />
                  </p>
                )}
                {content?.fax && (
                  <p className="card-text u-main-black">
                    <Icon
                      icon="it-print"
                      padding={true}
                      className="icons-ServiceContacts"
                    />
                    <ContactLink fax={content.fax} label={false} />
                  </p>
                )}
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      {/* ---ufficioResponsabile */}
      {getOffice()}
    </RichTextSection>
  ) : (
    <></>
  );
};

PNProcedureContacts.propTypes = {
  content: PropTypes.object.isRequired,
};

export default PNProcedureContacts;
