import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  biography: {
    id: 'biography',
    defaultMessage: 'Biografia',
  },
});

const PNAdministratorBiography = ({ content }) => {
  const intl = useIntl();

  return (
    richTextHasContent(content?.biography) && (
      <RichTextSection
        tag_id={'title-administrator-biography'}
        field="text"
        title={intl.formatMessage(messages.biography)}
        show_title={true}
        data={content?.biography}
        className={'mb-5'}
      />
    )
  );
};

PNAdministratorBiography.propTypes = {
  content: PropTypes.shape({
    biography: PropTypes.object,
  }).isRequired,
};

export default PNAdministratorBiography;
