/**
 * Gates proxy.
 * @module proxies/gates
 *
 * Proxy for gates management.
 *
 */
import { makeGetRequest } from './utils';

export const getGateResponse = async (gateName: string, method: string, parameters: object): Promise<object | null> => {
  const url = `etl/gate/private/${gateName}`;

  return makeGetRequest(url, {
    params: parameters,
  });
};
