import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
} from 'design-react-kit';
import { expandToBackendURL, flattenHTMLToAppURL } from '@plone/volto/helpers';

import { Icon as BaseIcon } from '@plone/volto/components';
import PropTypes from 'prop-types';
import { defineMessages } from 'react-intl';
import spidSvg from 'comune-pordenone-plone-theme/icons/spid-ico.svg';
import { useState } from 'react';

const messages = defineMessages({
  loginSpid: {
    id: 'login_spid',
    defaultMessage: 'SPID',
  },
  loginSpidButton: {
    id: 'login_with_spid',
    defaultMessage: 'Login with SPID',
  },
});

export const SPIDLogin = ({ intl, description, url, info, idps }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onItemCick = (idp) => {
    const { search } = window.location;
    const query = new URLSearchParams(search);
    const came_from = query.get('came_from');

    let full_url = `${url}?idp=${idp}`;

    if (came_from) {
      full_url += `&came_from=${came_from}`;
    }

    window.location = expandToBackendURL(full_url);
  };

  return (
    <div className="login-method">
      <h2>{intl.formatMessage(messages.loginSpid)}</h2>
      <p className="description">{description}</p>
      <div className="authorized-spid-login mb-4">
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
          <DropdownToggle
            className="btn-icon"
            color="italia"
            title={intl.formatMessage(messages.loginSpidButton)}
          >
            <span>
              {/* <Icon color="primary" icon="it-user" padding={false} size="" /> */}
              <BaseIcon name={spidSvg} style={{ width: 29, height: 29 }} />
            </span>
            <span className="flex-fill">
              {intl.formatMessage(messages.loginSpidButton)}
            </span>
          </DropdownToggle>
          <DropdownMenu>
            {idps?.map((idp) => (
              <DropdownItem onClick={() => onItemCick(idp.id)}>
                <img
                  src={expandToBackendURL(
                    '/++plone++comune.pordenone.authentication.images/' +
                      idp.logo_uri,
                  )}
                  alt={idp.organization_name}
                  title={idp.organization_name}
                />
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
        {info && (
          <div
            dangerouslySetInnerHTML={{ __html: flattenHTMLToAppURL(info) }}
          />
        )}
      </div>
    </div>
  );
};

SPIDLogin.propTypes = {
  intl: PropTypes.object,
  description: PropTypes.string,
  url: PropTypes.string,
  info: PropTypes.string,
};
