/**
 * Login information
 * @module src/actions/getLoginInfo
 */
export const GET_LOGIN_INFO = 'GET_LOGIN_INFO';

/**
 * Get login info.
 * @function getLoginInfo
 * @returns {Object} Get login info action.
 */
export function getLoginInfo() {
  return {
    type: GET_LOGIN_INFO,
    request: {
      op: 'get',
      path: '/@login-info',
    },
  };
}
