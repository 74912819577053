/**
 * Boolean attribute.
 * @module fields/boolean
 *
 */
import { FormGroup, FormText, Input, Label } from 'design-react-kit';
import { defineMessages, useIntl } from 'react-intl';
import { updateVisibility, validateValue, setAutoValue } from './../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import React from 'react';
import { getFieldLabel } from './utils';

export const BooleanField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const fieldId = `field-boolean-${config.name}`;
  const required = config.mandatory ? true : undefined;
  const defaultCheck = context[config.name] ? true : undefined;

  const [valid, setValid] = useState();
  const [errors, setErrors] = useState('');
  const [checked, setChecked] = useState(!!context?.[config.name]);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const messages = defineMessages({
    requiredFiled: {
      id: 'requiredFiled',
      defaultMessage: 'Questo campo è obbligatorio',
    },
  });
  /**
   * On value change
   */
  useEffect(() => {
    // update value
    context[config.name] = checked;
    recordUpdater({
      ...context,
    });

    // validate value
    validateValue(checked, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, [checked]);

  /**
   * On context change
   */
  useEffect(() => {
    if (checked != context?.[config.name]) {
      setChecked(context?.[config.name]);
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(checked, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <FormGroup check hidden={!isVisible}>
      <Input
        type="checkbox"
        id={fieldId}
        valid={errors ? false : checked ? true : undefined}
        required={required}
        defaultChecked={defaultCheck}
        checked={checked}
        onClick={() => {
          setChecked(!checked);
        }}
        disabled={isDisabled}
        infoText={config?.help}
      />
      <Label check for={fieldId}>
        {getFieldLabel(config)}
      </Label>
      <FormText tag="div" color="red" className="invalid-feedback form-feedback just-validate-error-label mt-0 mb-3">
        {errors}
      </FormText>
    </FormGroup>
  );
};
