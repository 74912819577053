import { defineMessages, useIntl } from 'react-intl';
import React from 'react';
import PropTypes from 'prop-types';
import {
  richTextHasContent,
  RichTextSection,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';

const messages = defineMessages({
  notes: {
    id: 'notes',
    defaultMessage: 'Note',
  },
});

const PNAdministratorNotes = ({ content }) => {
  const intl = useIntl();

  return richTextHasContent(content?.notes) ? (
    <RichTextSection
      tag_id={'title-administrator-notes'}
      field="text"
      title={intl.formatMessage(messages.notes)}
      show_title={true}
      data={content?.notes}
      className={'mb-5'}
    />
  ) : (
    ''
  );
};

PNAdministratorNotes.propTypes = {
  content: PropTypes.arrayOf(PropTypes.object),
};

export default PNAdministratorNotes;
