import 'react-image-gallery/styles/css/image-gallery.css';

import { defineMessages, useIntl } from 'react-intl';

import { Button } from 'semantic-ui-react';
import { Icon } from '@plone/volto/components';
import PropTypes from 'prop-types';
import React from 'react';
import { RichTextSection } from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import config from '@plone/volto/registry';
import { flattenToAppURL } from '@plone/volto/helpers';
import galleryBackDownSVG from '@plone/volto/icons/back-down.svg';
import galleryFullScreenSVG from '@plone/volto/icons/fullscreen.svg';
import galleryLeftSVG from '@plone/volto/icons/left-key.svg';
import galleryPauseSVG from '@plone/volto/icons/pause.svg';
import galleryPlaySVG from '@plone/volto/icons/play.svg';
import galleryRightSVG from '@plone/volto/icons/right-key.svg';
import loadable from '@loadable/component';

const ImageGallery = loadable(() => import('react-image-gallery'));

const messages = defineMessages({
  images: {
    id: 'images',
    defaultMessage: 'Immagini',
  },
  images_gallery: {
    id: 'images_gallery',
    defaultMessage: 'Galleria di immagini',
  },
});

const renderLeftNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-left-nav primary basic"
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name={galleryLeftSVG} size="25px" fill="black" />
    </Button>
  );
};
const renderRightNav = (onClick, disabled) => {
  return (
    <Button
      className="image-gallery-icon image-gallery-right-nav primary basic"
      disabled={disabled}
      onClick={onClick}
    >
      <Icon name={galleryRightSVG} size="25px" />
    </Button>
  );
};

const renderPlayPauseButton = (onClick, isPlaying) => (
  <Button
    type="button"
    className="image-gallery-icon image-gallery-play-button basic primary"
    onClick={onClick}
    aria-label="Play or Pause Slideshow"
  >
    {isPlaying ? (
      <Icon name={galleryPauseSVG} size="25px" />
    ) : (
      <Icon name={galleryPlaySVG} size="25px" />
    )}
  </Button>
);

const renderFullscreenButton = (onClick, isFullscreen) => {
  return (
    <Button
      type="button"
      className="image-gallery-icon image-gallery-fullscreen-button primary basic"
      onClick={onClick}
      aria-label="Open Fullscreen"
    >
      {isFullscreen ? (
        <Icon name={galleryBackDownSVG} size="25px" />
      ) : (
        <Icon name={galleryFullScreenSVG} size="25px" />
      )}
    </Button>
  );
};

const PNImageGalleryTemplate = ({ content }) => {
  const intl = useIntl();
  const images = content?.items;
  const renderItems = images?.filter(
    (images) =>
      images['@type'] === 'PNEventImage' ||
      images['@type'] === 'PNNewsImage' ||
      images['@type'] === 'Image',
  );
  const imagesInfo = renderItems?.map((item) => {
    let image_id = item['@id'];
    let image_download = item?.image_scales?.image[0]?.download;
    let full_url = image_id + '/' + image_download;
    return {
      original: flattenToAppURL(
        item[config.settings.listingPreviewImageField]?.scales.large.download ||
          full_url ||
          '',
      ),
      thumbnail: flattenToAppURL(
        item[config.settings.listingPreviewImageField]?.scales.thumb.download ||
          full_url ||
          '',
      ),
      description: item.description ?? item.rights ?? null,
    };
  });

  return (
    renderItems?.length > 0 && (
      <RichTextSection
        tag_id="image_file"
        title={intl.formatMessage(messages.images_gallery)}
      >
        <ImageGallery
          items={imagesInfo}
          renderLeftNav={renderLeftNav}
          renderRightNav={renderRightNav}
          renderPlayPauseButton={renderPlayPauseButton}
          renderFullscreenButton={renderFullscreenButton}
          lazyLoad={false}
        />
      </RichTextSection>
    )
  );
};

PNImageGalleryTemplate.propTypes = {
  items: PropTypes.arrayOf(PropTypes.any).isRequired,
};

export default PNImageGalleryTemplate;
