import React from 'react';
import PropTypes from 'prop-types';

import { defineMessages, useIntl } from 'react-intl';
import { Card, CardBody, CardTitle } from 'design-react-kit';

import { Icon } from 'design-comuni-plone-theme/components/ItaliaTheme';
import {
  richTextHasContent,
  RichTextSection,
  ContactLink,
  OfficeCard,
} from 'design-comuni-plone-theme/components/ItaliaTheme/View';
import { flattenToAppURL } from '@plone/volto/helpers';

const messages = defineMessages({
  supported_by: {
    id: 'supported_by',
    defaultMessage: 'Con il supporto di',
  },
  contacts: {
    id: 'contacts',
    defaultMessage: 'Contatti',
  },
  web_site: {
    id: 'web_site',
    defaultMessage: 'Sito web: ',
  },
  facebook: {
    id: 'facebook',
    defaultMessage: 'facebook: ',
  },
});

const EventoDocumenti = ({ content }) => {
  const intl = useIntl();
  const getSupportatoDa = () => {
    return (
      content?.supportato_da?.length > 0 && (
        <>
          <h5 className="mt-4 supported-by">
            {intl.formatMessage(messages.supported_by)}
          </h5>
          {content?.supportato_da?.map((item) => (
            <OfficeCard
              key={item['@id']}
              office={item}
              extended={true}
              icon={'it-pa'}
            />
          ))}
        </>
      )
    );
  };

  return richTextHasContent(content?.contact_name) ||
    content?.contact_url ||
    content?.contact_phone ||
    content?.contact_email ||
    content?.facebookUrl ||
    content?.fax ? (
    <RichTextSection
      tag_id="contatti"
      title={intl.formatMessage(messages.contacts)}
      show_title={false}
    >
      <div className="mb-5">
        <Card
          className="card card-teaser rounded shadow mt-3"
          noWrapper={true}
          tag="div"
        >
          <CardBody tag="div" className={'card-body pr-3 display-6'}>
            <CardTitle tag="h4">
              {intl.formatMessage(messages.contacts)}
            </CardTitle>
            {content?.contact_name && (
              <p className="card-text mt-3">
                <Icon icon="it-user" padding={true} />
                <span>{content.contact_name}</span>
              </p>
            )}
            {content?.contact_phone && (
              <p className="card-text mt-3">
                <Icon icon="it-telephone" padding={true} />
                <ContactLink tel={content.contact_phone} label={false} />
              </p>
            )}
            {content?.reperibilita?.replace(/(<([^>]+)>)/g, '')?.length > 0 && (
              <p className="card-text mt-3">
                {content?.reperibilita?.replace(/(<([^>]+)>)/g, '')}
              </p>
            )}
            {content?.contact_email && (
              <p className="card-text mt-3">
                <Icon icon="it-mail" padding={true} />
                <ContactLink email={content.contact_email} label={false} />
              </p>
            )}
            {content?.contact_url && (
              <p className="card-text mt-3">
                <Icon icon="it-link" padding={true} />
                {intl.formatMessage(messages.web_site)}
                <span>
                  <a
                    href={flattenToAppURL(
                      content.contact_url.match(/^(http:\/\/|https:\/\/)/gm)
                        ? content.contact_url
                        : `http://${content.contact_url}`,
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {content.contact_url}
                  </a>
                </span>
              </p>
            )}
            {content?.facebookUrl && (
              <p className="card-text mt-3">
                <Icon icon="it-facebook" padding={true} />
                {intl.formatMessage(messages.facebook)}
                <span>
                  <a
                    href={flattenToAppURL(
                      content.facebookUrl.match(/^(http:\/\/|https:\/\/)/gm)
                        ? content.facebookUrl
                        : `http://${content.facebookUrl}`,
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {content.facebookUrl}
                  </a>
                </span>
              </p>
            )}
          </CardBody>
        </Card>
      </div>

      {/* ---supportato da */}
      {getSupportatoDa()}
    </RichTextSection>
  ) : (
    <></>
  );
};

EventoDocumenti.propTypes = {
  content: PropTypes.object.isRequired,
};

export default EventoDocumenti;
