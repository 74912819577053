/**
 * Lookup attribute.
 * @module fields/lookup
 *
 */
import { FormGroup, FormText, Input, Label } from 'design-react-kit';
import { evaluateEcqlFilter, setAutoValue, updateVisibility, validateValue } from '../utilities/fieldshelper';
import { useEffect, useState } from 'react';

import React from 'react';
import cx from 'classnames';
import { getFieldLabel } from './utils';
import { getLookupValuesProxy } from '../proxies/lookup';
import { useIntl } from 'react-intl';

export const LookupField = ({ config, context, recordUpdater, showIfFn, showIfBindings, showIfApis, filterBindings, validationRuleFn, validationRuleBindings, validationRuleApis, autoValueFn, autoValueBindings, autoValueApis }) => {
  const intl = useIntl();
  const required = config.mandatory ? true : undefined;

  const [value, setValue] = useState(context?.[config.name]);
  const [errors, setErrors] = useState('');
  const [options, setOptions] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  useEffect(() => {
    getLookupValuesProxy(config.lookupType, {
      filter: {
        ecql: evaluateEcqlFilter(config.ecqlFilter, context),
      },
    }).then(({ data, meta }) => {
      setOptions(data);
    });
  }, filterBindings);

  /**
   * On value change
   */
  useEffect(() => {
    const selectedRecord = options.find((r: { _id: number }) => r._id == value);
    // update value
    context = {
      ...context,
      [config.name]: value,
      [`_${config.name}_code`]: selectedRecord?.code,
      [`_${config.name}_description`]: selectedRecord?.description,
      [`_${config.name}_description_translation`]: selectedRecord?.description_translation,
    };
    context[config.name] = value;
    recordUpdater(context);

    // validate value
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, [value]);

  /**
   * On context change
   */
  useEffect(() => {
    if (value != context?.[config.name]) {
      setValue(context?.[config.name]);
    }
  }, [context?.[config.name]]);

  /**
   * Update visibility rules
   */
  useEffect(() => {
    updateVisibility(showIfFn, showIfApis, setIsVisible, setIsDisabled);
  }, showIfBindings);

  /**
   * Validation rules
   */
  useEffect(() => {
    validateValue(value, config.mandatory, setErrors, validationRuleFn, validationRuleApis, intl);
  }, validationRuleBindings);

  /**
   * AutoValue rules
   */
  useEffect(() => {
    setAutoValue(autoValueFn, autoValueApis);
  }, autoValueBindings);

  return (
    <div hidden={!isVisible} className="form-group">
      <Label className="active">{getFieldLabel(config)}</Label>
      {options.map((opt: any) => (
        <FormGroup check key={opt._id}>
          <Input
            type="radio"
            checked={opt._id == value}
            value={opt._id}
            id={`field-lookup-value-${config.name}-${opt._id}`}
            name={config.name}
            disabled={isDisabled}
            valid={errors ? false : value ? true : undefined}
            required={required}
            onClick={(e) => setValue(e.target.value)}
          />
          <Label check htmlFor={`field-lookup-value-${config.name}-${opt._id}`} style={{overflow: 'visible', whiteSpace: 'normal'}}>
            {opt._description_translation}
          </Label>
        </FormGroup>
      ))}
      <FormText className="form-text" hidden={!config?.help}>
        {config?.help}
      </FormText>
      <FormText tag="div" color="red" className="invalid-feedback form-feedback just-validate-error-label mt-0 mb-3">
        {errors}
      </FormText>
    </div>
  );
};
