/**
 * utils.
 * @module fields/utils
 *
 */
export const getFieldLabel = ({ _description_translation, description, mandatory = false }): string => {
  let label = _description_translation || description;
  if (mandatory) {
    label = `${label} *`;
  }
  return label;
};
