/* eslint-disable react-hooks/exhaustive-deps */
/**
 * HeaderLogin component.
 * @module components/ItaliaTheme/Header/HeaderSlim/HeaderLogin
 *
 * login button/dropdown for spid for top header
 *
 * nel caso di spid_login la url di login è impostata alla pagina intermedia
 * dove si può scegliere se loggarsi con spid o con utente plone/operatore
 *
 */

import {
  Col,
  DropdownMenu,
  DropdownToggle,
  LinkList,
  LinkListItem,
  Row,
  UncontrolledDropdown,
} from 'design-react-kit';
import {
  Icon,
  LoginButton,
} from 'design-comuni-plone-theme/components/ItaliaTheme';
import { defineMessages, useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';

import { BodyClass } from '@plone/volto/helpers';
import { doLogout } from 'comune-pordenone-plone-theme/actions';
import { getBaseUrl } from '@plone/volto/helpers';
import { getPortalUser } from 'comune-pordenone-plone-theme/actions';
import { getUser } from '@plone/volto/actions';
import jwtDecode from 'jwt-decode';
import { purgeMessages } from '@plone/volto/actions';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const messages = defineMessages({
  loginAreaPersonale: {
    id: "Accedi all'area personale",
    defaultMessage: "Accedi all'area personale a",
  },
  MyArea: {
    id: 'MyArea',
    defaultMessage: 'Area personale',
  },
  Logout: {
    id: 'Logout',
    defaultMessage: 'Esci',
  },
});

const HeaderLogin = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const location = useLocation();

  const userSession = useSelector((state) => state.portalUser);

  useEffect(() => {
    if (userSession.isAuthenticated === null) {
      dispatch(getPortalUser());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * The following code is needed for standard authentication
   */
  const ploneUserId = useSelector((state) =>
    state.userSession.token ? jwtDecode(state.userSession.token).sub : null,
  );

  const userLogged = useSelector((state) => state.users.user);
  const userLoggedSt = useSelector((state) => state.users);

  useEffect(() => {
    if (
      !userLoggedSt?.get?.loading &&
      userSession?.isAuthenticated === false &&
      ploneUserId
    ) {
      dispatch(getUser(ploneUserId));
    }
  }, [ploneUserId, userSession.isAuthenticated]);

  const execLogout = () => {
    dispatch(doLogout());
    dispatch(purgeMessages());
  };
  const redirectMyArea = () => {
    const rootPath = window.location.origin;
    const redirectUrl = `${rootPath}/my-area`;
    window.location.href = redirectUrl;
  };

  const rolesBodyClasses = userLogged.roles?.length
    ? userLogged.roles.map((role) => `role-${role.toLowerCase()}`)
    : ['no-user-roles'];

  return (
    <>
      <BodyClass className={rolesBodyClasses.join(' ')} />
      {!ploneUserId &&
        (!userSession.isAuthenticated ? (
          <LoginButton
            baseLoginUrl={`${getBaseUrl(location.pathname)}/portal-login`}
          >
            <span className="rounded-icon">
              <Icon color="primary" icon="it-user" padding={false} size="" />
            </span>
            <span className="d-none d-lg-block">
              {intl.formatMessage(messages.loginAreaPersonale)}
            </span>
          </LoginButton>
        ) : (
          <>
            <UncontrolledDropdown nav tag="div">
              <DropdownToggle
                aria-haspopup
                caret
                color="secondary"
                nav
                className="btn-icon"
              >
                <span className="rounded-icon">
                  <Icon color="primary" icon="it-user" size="" />
                </span>
                <span className="d-none d-lg-block">
                  {`${userSession.user?.first_name} ${userSession.user?.last_name}`}
                </span>
                <Icon color="" icon="it-expand" padding={false} size="" />
              </DropdownToggle>

              <DropdownMenu flip tag="div">
                <Row tag="div">
                  <Col
                    size="12"
                    tag="div"
                    widths={['xs', 'sm', 'md', 'lg', 'xl']}
                  >
                    <LinkList tag="div">
                      <LinkListItem
                        title={intl.formatMessage(messages.MyArea)}
                        tag="a"
                        onClick={() => {
                          redirectMyArea();
                        }}
                        className="logout"
                      >
                        <Icon color="" icon="user-alt" size="sm" left />
                        <span>{intl.formatMessage(messages.MyArea)}</span>
                      </LinkListItem>
                    </LinkList>
                  </Col>
                </Row>
                <hr />
                <Row tag="div">
                  <Col
                    size="12"
                    tag="div"
                    widths={['xs', 'sm', 'md', 'lg', 'xl']}
                  >
                    <LinkList tag="div">
                      <LinkListItem
                        title={intl.formatMessage(messages.Logout)}
                        tag="a"
                        onClick={() => {
                          execLogout();
                        }}
                        className="logout"
                      >
                        <Icon color="" icon="sign-out-alt" size="sm" left />
                        <span>{intl.formatMessage(messages.Logout)}</span>
                      </LinkListItem>
                    </LinkList>
                  </Col>
                </Row>
              </DropdownMenu>
            </UncontrolledDropdown>
          </>
        ))}
    </>
  );
};

export default HeaderLogin;
