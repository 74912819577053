/**
 * MyAreaTab component.
 * @module components/Features/MyArea/MyAreaTab
 *
 * Page which displays personal area page tab.
 *
 */
import PropTypes from 'prop-types';
import { defineMessages, useIntl } from 'react-intl';
import { Icon, Nav, NavItem, NavLink } from 'design-react-kit';

const messages = defineMessages({
  desk: {
    id: 'desk',
    defaultMessage: 'Scrivania',
  },
  messages: {
    id: 'messages',
    defaultMessage: 'Messaggi',
  },
  activities: {
    id: 'activities',
    defaultMessage: 'Attività',
  },
  services: {
    id: 'services',
    defaultMessage: 'Servizi',
  },
});

const MyAreaTab = ({ activeTab, setActiveTab }) => {
  const intl = useIntl();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <Nav
      tabs
      className="col-12 p-0 mb-3 cmp-nav-tab mb-4 mb-lg-5 mt-lg-4 w-100"
    >
      <NavItem className="w-25">
        <NavLink
          href="#"
          active={activeTab === 'desk-tab'}
          onClick={() => {
            if (activeTab !== 'desk-tab') {
              handleTabClick('desk-tab');
            }
          }}
        >
          <span>
            <Icon icon="it-pa" title="Desk" size="sm" />
            <i
              className="it-ico-lg it-file d-block text-center"
              aria-label={intl.formatMessage(messages.desk)}
            />
          </span>
          <span>{intl.formatMessage(messages.desk)}</span>
        </NavLink>
      </NavItem>
      <NavItem className="w-25">
        <NavLink
          href="#"
          active={activeTab === 'messages-tab'}
          onClick={() => {
            if (activeTab !== 'messages-tab') {
              handleTabClick('messages-tab');
            }
          }}
        >
          <span>
            <Icon icon="it-mail" title="Messages" size="sm" />
            <i
              className="it-ico-lg it-file d-block text-center"
              aria-label={intl.formatMessage(messages.messages)}
            />
          </span>
          <span>{intl.formatMessage(messages.messages)}</span>
        </NavLink>
      </NavItem>
      <NavItem className="w-25">
        <NavLink
          href="#"
          active={activeTab === 'activities-tab'}
          onClick={() => {
            if (activeTab !== 'activities-tab') {
              handleTabClick('activities-tab');
            }
          }}
        >
          <span>
            <Icon icon="it-files" title="Activities" size="sm" />
            <i
              className="it-ico-lg it-file d-block text-center"
              aria-label={intl.formatMessage(messages.activities)}
            />
          </span>
          <span>{intl.formatMessage(messages.activities)}</span>
        </NavLink>
      </NavItem>
      <NavItem className="w-25">
        <NavLink
          href="#"
          active={activeTab === 'services-tab'}
          onClick={() => {
            if (activeTab !== 'services-tab') {
              handleTabClick('services-tab');
            }
          }}
        >
          <span>
            <Icon icon="it-settings" title="Services" size="sm" />
            <i
              className="it-ico-lg it-file d-block text-center"
              aria-label={intl.formatMessage(messages.services)}
            />
          </span>
          <span>{intl.formatMessage(messages.services)}</span>
        </NavLink>
      </NavItem>
    </Nav>
  );
};

/**
 * Property types.
 * @property {Object} propTypes Property types.
 * @static
 */
MyAreaTab.propTypes = {
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

export default MyAreaTab;
